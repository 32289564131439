.catalogsearch-result-index {
    .page-title-wrapper {
        .block  {
            margin-bottom: 0;

            .title {
                @include font-size(16px);
                text-transform: uppercase;
            }

            .item {
                display: inline-block;
                margin-right: $half-spacing;

                &:before {
                    content: "\2022";
                }

                a {
                    color: $accent;
                    font-weight: bold;
                    @include hover {
                        color: $accent;
                    }
                }
            }
        }
    }
}

.search.results {
    margin-bottom: $base-spacing;

    .block {
margin-bottom: $base-spacing;
    }
}

.results {

}

.results__header {
    padding-top: 0;

}

.results__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @include media-query(min, $lap-start) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}

.results__item {
    display: block;
    text-align: center;
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        width: calc(50% - #{$half-spacing});
    }

    a {
        display: block;
        text-decoration: none;
        background-color: $silver-lt;
        padding: 20px;

        @include hover {
            background-color: $black;
            color: $white;
            text-decoration: none;
        }
    }
}


.results__title {
    margin-bottom: 0;
    padding-top: 0;
    display: block;

}

.results__link {
    @include font-size(16px);

    &:after {
        content: " +";
    }
}


.toolbar-products {
    margin-bottom: $base-spacing;
}