.filter.block {
    margin-bottom: 40px
}

.filter-title {
    display: none
}

.filter-content .item {
    margin: 10px 0
}

.filter-actions {
    margin-bottom: 30px
}

.filter.active .filter-options,.filter-options {
    background: transparent;
    clear: both;
    display: block;
    overflow: initial;
    position: static
}

.filter-subtitle {
    display: block;
    position: static
}
.ln_overlay {
    background-color: #FFFFFF;
    height: 300%;
    left: 0;
    text-align: center;
    opacity: 0.5;
    filter: alpha(opacity=50);
    position: absolute;
    top: 0px;
    width: 500%;
    z-index: 555;
    display: none
}

.ln_overlay img {
    margin-top: 300px;
    left: 45%;
    display: block;
    position: fixed
}

.ln_slider_container {
    width: calc(80%);
    margin: 0 15px 0 5px
}

.filter-options-content a {
    display: inline-block;
}

.ln_overlay {
    background-color: #FFFFFF;
    height: 100%;
    left: 0;
    opacity: 0.5;
    filter: alpha(opacity=50);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 555;
    display: none
}

.ln_overlay img {
    top: 40%;
    left: 45%;
    display: block;
    position: fixed
}

.ln_slider_container {
    width: calc(80%);
    margin: 0 15px 0 5px
}

.layered-filter-block-container .ln_tooltip {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px
}