
.products-list {

}

.product-items {
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;


  @include media-query(max, $lap-start) {
    text-align: center;
  }
}

.item-knowledge-link {
    list-style-type: none;
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    .item-link {
        width: calc(25% - 15px);
        margin-right: 15px;
        text-align: center;
    }
    strong {
        text-decoration: underline;
    }
}

.product-item {
  box-shadow: 0 0 0 1px $mercury;
  border: 3px solid transparent;
  border-radius: 2px;
  margin-bottom: $base-spacing;
  cursor: pointer;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;

  @include hover {
    border: 3px solid $accent;
    box-shadow: 0 0 0 1px $accent;
    margin-top: -$half-spacing;
    padding-top: $half-spacing;

    .photo.image {
      transform: translate(0, -5px);
    }
    .product-item-info:after {
      background-color: darken($accent, 10%);
    }
  }

  @include media-query-bracket(0, $lap-start) {
    width: calc(50% - (#{$base-spacing} / 2));
    margin-left: $base-spacing;

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }

  @include media-query-bracket($lap-start, $desk-start) {
    width: calc(33% - (#{$base-spacing * 2} / 3));
    margin-left: $base-spacing;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  @include media-query(min, $desk-start) {
    width: calc(25% - (#{$base-spacing * 3} / 4));
    margin-left: $base-spacing;

    &:nth-child(4n + 1) {
      margin-left: 0;
    }
  }
}

.product-item-info {
  position: relative;
  padding: 10px 10px 10px;
  transition: all .3s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: $accent;
    background-image: url("../img/arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40%;
    width: 25px;
    height: 30px;
    border-top-left-radius: 5px;
    right: -4px;
    bottom: -4px;

    @include media-query(min, $wide-start) {
      width: 35px;
      height: 45px;
    }
  }

  .price-box {


    @include media-query(min, $wide-start) {

    }
  }

}

.product-item-photo {
  display: block;

  &:after {
    content: '';
    display: block;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }


}

.product-image-container {

}

.product-image-wrapper {
}

.product-image-photo {
  margin: 0 auto;
}

.photo.image {
  min-width: 140px;
  min-height: 140px;
  transition: all .3s ease-in-out;
}

.product-image-photo {

}

.product-item-details {
  margin-top: auto;
  margin-bottom: $base-spacing;
}

.product-item-name {
  @include font-size($small-font-size);
  display: block;
  margin-bottom: $base-spacing;
  font-weight: normal;
  line-height: 1.3em;
  @include media-query(min, $wide-start) {
    @include font-size($base-font-size);
    margin-bottom: $double-spacing;
  }
}

.product-item-link {
  text-decoration: none;
  font-weight: bold;
  color: $accent;

  @include hover {
    color: $accent-dk;
    text-decoration: underline;
  }
}

.product-item-inner {

}

.product-item-actions {

  .actions-primary {
    display: none;
    @include font-size($small-font-size);
  }

  .action.tocart {

  }

  .actions-secondary {
    display: none;
  }

  .action.towishlist {

  }

  .action.tocompare {

  }
}

.product-item-description {

  display: none;

  .action.more {

  }
}
