@font-face {
  font-family: 'Helvetica Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/helvetica-condensed.woff2') format('woff2'),
  url('#{$font-path}/helvetica-condensed.woff') format('woff'),
  url('#{$font-path}/helvetica-condensed.eot') format('eot');
}

@font-face {
  font-family: 'Helvetica Condensed';
  font-style: normal;
  font-weight: bold;
  src: url('#{$font-path}/helvetica-bold-condensed.woff2') format('woff2'),
  url('#{$font-path}/helvetica-bold-condensed.woff') format('woff'),
  url('#{$font-path}/helvetica-bold-condensed.eot') format('eot');
}

@font-face {
  font-family: 'luma-icons';
  font-style: normal;
  font-weight: bold;
  src: url('#{$font-path}/Luma-Icons.woff2') format('woff2'),
  url('#{$font-path}/Luma-Icons.woff') format('woff'),
  url('#{$font-path}/Luma-Icons.eot') format('eot');
}

