.page-layout-page-builder {
    .columns {
        max-width: none;
    }
}

.pagebuilder-column-group {
    @include media-query(max, $lap-start) {
        flex-wrap: wrap;
    }
}

.pagebuilder-column {
    @include media-query(max, $lap-start) {
        background-attachment: scroll !important;
        flex-basis: 100%;
    }
}

[data-content-type='row'][data-appearance='contained'] {
    box-sizing: border-box;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: $page-width - ($base-spacing * 2);
}

[data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    box-sizing: border-box;

    @include media-query(max, $lap-start) {
        background-attachment: scroll !important;
    }
}

[data-content-type='row'][data-appearance='full-bleed'] {
    box-sizing: border-box;

    @include media-query(max, $lap-start) {
        background-attachment: scroll !important;
    }
}

[data-content-type='row'][data-appearance='full-width'] {
    box-sizing: border-box;

    @include media-query(max, $lap-start) {
        background-attachment: scroll !important;
    }
}

[data-content-type='row'][data-appearance='full-width']
    > .row-full-width-inner {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: $page-width;
    width: 100%;
}

div[data-content-type='text'] {
    word-wrap: break-word;
}

.cms-content-important {
    background-color: #f5f5f5;
    color: #333;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    margin: 0 0 35px -20px;
    padding: 20px;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}

.pagebuilder-mobile-only {
    @include media-query(min, $lap-start) {
        display: none !important;
    }
}

.pagebuilder-mobile-hidden {
    @include media-query(max, $lap-start) {
        display: none !important;
    }
}
