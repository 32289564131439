/*========================================
Forms
========================================*/
form {
    margin-bottom: $half-spacing;
}

label,
.label,
dt {
    display: block;
    font-weight: 500;
    @include font-size($base-font-size);
    margin-bottom: $micro-spacing;
    cursor: pointer;
}

select,
textarea,
input {
    appearance: none;
    display: block;
    padding: $half-spacing/2 $half-spacing * 1.5;
    width: 100%;
    line-height: 1;
    background-color: $white;
    border: 1px solid $primary;
    font-weight: 100;
    color: $base-color;
    height: $button-height;
    border-radius: 0;

    &:focus {
        outline: none;
        border-color: $primary;
    }

    &.mage-error {
        border-color: $failure;
        margin-top: 0;
    }

    &[disabled] {
        opacity: 0.7;
        cursor: not-allowed;
    }

    &::placeholder {
        color: $base-color;
    }
}

.mage-error[generated],
.field-error,
.mage-error {
    margin-top: $half-spacing;
    color: $failure;
    @include font-size(16px);
}

input[type="checkbox"] {
    display: block;
    position: relative;
    height: ($button-height / 2);
    width: ($button-height / 2);
    margin-right: $half-spacing;
    margin-bottom: 0;
    cursor: pointer;
    flex-shrink: 0;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom:0;
        left: 0;
        background-size: 15px;
        opacity: 0;
    }

    @include hover {
        border-color: $primary;
    }
}

input[type="checkbox"]:checked {
    &:after {
        opacity: 1;
        background: url("#{$image-path}check-tick.svg") no-repeat center, $accent;
        background-size: 80%;
    }
}

input[type="radio"] {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    height: $base-spacing;
    width: $base-spacing;
    margin-right: $half-spacing;
    margin-bottom: 0;
    cursor: pointer;
    border-radius: 100%;
    flex-shrink: 0;

    &:after {
        content: "";
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        background-color: $accent;
        opacity: 0;
        border-radius: 100%;
    }

    @include hover {
        border-color: $accent;
    }
}

input[type="radio"]:checked {
    &:after {
        opacity: 1;
    }
}


select {
    background: url("#{$image-path}arrow-down.svg") no-repeat right $half-spacing center $white;
    background-size: 30px;
    padding-right: $double-spacing + $half-spacing;
    line-height: 1.2;
}

select::-ms-expand {
    display: none;
}

fieldset {
    border: none;
    margin-bottom: $base-spacing;

    &:last-of-type {
        margin-bottom: 0;
    }
}

legend {
    display: block;
    width: 100%;
    margin-bottom: $base-spacing;
    @include font-size($h3-font-size);
    font-weight: 500;
}

.field {
    max-width: 35em;
    margin-bottom: $base-spacing;

    &._error,
    &.field--error {
        select,
        input {
            border-color: $failure;
        }
    }
}

.choice,
.field--type-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: $half-spacing;

    input {
    }

    label,
    .label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        @include font-size($base-font-size);
        font-weight: 100;
        text-transform: none;
        letter-spacing: 0;

        img {
            max-width: $double-spacing;
            margin-right: $half-spacing;
        }

        span, a {
            margin-left: $half-spacing;
        }
    }

    + .choice {
        margin-bottom: $base-spacing;
    }
}

.input-text {
    &::placeholder {
        color: $silver;
    }
}

.input-text--centered {
    text-align: center;
}

.control {
    position: relative;

    .nested {
        padding-top: $half-spacing;
    }
}

.is-email-available {
    position: relative;
    &:after {
        @include overlay-spinner;
        content: " ";
    }
}

.is-email-available--loading {
    > * {
        opacity: 0.3;
    }
    &:after {
        opacity: 1;
        visibility: visible;
    }
}

.is-email-available__password {
}

.is-email-available__password--hidden {
    display: none;
}

.control--combine {
    display: flex;
    flex-wrap: wrap;

    input {
        border-radius: $half-radius 0 0 $half-radius;
        border-right: none;
        order: 1;
        flex-grow: 1;
        flex-shrink: 1;
        width: auto;
    }

    button,
    .button {
        flex-grow: 0;
        flex-shrink: 0;
        border-radius:  0 $half-radius $half-radius 0;
        order: 2;
        padding: $half-spacing
    }

    div.mage-error {
        order: 3;
        width: 100%;
    }
}



//password
.password {
    .control {
        display: flex;
        flex-direction: column;

        input {
            order: 1;
        }

        #password-strength-meter-container {
            order: 2;
        }

        .mage-error[generated] {
            order: 3;
        }
    }
}

.password-strength-meter {
    background-color: $mercury-dk;
    line-height: 32px;
    height: 32px;
    padding: 0 9px;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
}

.password-none {
}

.password-weak .password-strength-meter:before {
    background-color: #f893af;
    width: 25%;
}

.password-medium .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%;
}

.password-strong .password-strength-meter:before {
    background-color: lighten($green, 15%);
    width: 75%;
}

.password-very-strong .password-strength-meter:before {
    background-color: $green;
    width: 100%;
}

//DOB
.customer-dob {
    button {
        position: absolute;
        top: 0;
        right: 0;
        width: $button-height;
        height: $button-height;
        border: none;
        //background: url("../img/calendar.svg") no-repeat center;
        background-size: $base-and-half-spacing;

        span {
            display: none;
        }
    }

    input {
        padding-right: $button-height;
    }
}

.ui-datepicker {
    background-color: $white;
    padding: $half-spacing;
}

.ui-datepicker-header {
    background-color: $white;
}

.ui-datepicker-prev {
    margin-right: $half-spacing;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    display: inline-block;
    margin-bottom: $half-spacing;
}

.ui-datepicker-title {
    display: flex;
}

.ui-datepicker-month {
    width: calc(50% - #{$half-spacing});
    margin-right: $half-spacing;
    margin-bottom: $half-spacing;
}

.ui-datepicker-year {
    width: calc(50% - #{$half-spacing});
    margin-bottom: $half-spacing;
}

.ui-datepicker-calendar {
    margin-bottom: 0;
    border-left: 1px solid $silver-lt;
    border-right: 1px solid $silver-lt;
}

//Login Form
.form-login {
    .label:after {
        display: none;
    }
    .button {

        width: 100%;
        @include media-query (min, $lap-start){
            margin-bottom: 0;
            width: auto;
        }
    }

    .actions-toolbar {
        @include media-query (min, $lap-start){
            display: flex;
            max-width: 35em;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    .remind {
        display: block;
        width: 100%;
        text-align: center;
        text-decoration: none;

        @include media-query (min, $lap-start){
            width: auto;
            text-align: left;
        }

        @include hover {
            text-decoration: underline;
        }
    }
}


.fieldset {
    border: 0;
    margin: 0 0 40px;
    padding: 0;
    letter-spacing: -.31em;
}
.fieldset > * {
    letter-spacing: normal;
}
.fieldset > .legend {
    margin: 0 0 20px;
    padding: 0 0 10px;
    width: 100%;
    box-sizing: border-box;
    float: left;
    font-weight: 300;
    line-height: 1.2;
    font-size: 1.8rem;
}
.fieldset > .legend + br {
    clear: both;
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.fieldset:last-child {
    margin-bottom: 20px;
}
.fieldset > .field,
.fieldset > .fields > .field {
    margin: 0 0 20px;
}
.fieldset > .field > .label,
.fieldset > .fields > .field > .label {
    margin: 0 0 8px;
    display: inline-block;
}
.fieldset > .field:last-child,
.fieldset > .fields > .field:last-child {
    margin-bottom: 0;
}
.fieldset > .field > .label,
.fieldset > .fields > .field > .label {
    font-weight: 600;
}
.fieldset > .field > .label + br,
.fieldset > .fields > .field > .label + br {
    display: none;
}
.fieldset > .field .choice input,
.fieldset > .fields > .field .choice input {
    vertical-align: top;
}
.fieldset > .field .fields.group:before,
.fieldset > .fields > .field .fields.group:before,
.fieldset > .field .fields.group:after,
.fieldset > .fields > .field .fields.group:after {
    content: '';
    display: table;
}
.fieldset > .field .fields.group:after,
.fieldset > .fields > .field .fields.group:after {
    clear: both;
}
.fieldset > .field .fields.group .field,
.fieldset > .fields > .field .fields.group .field {
    box-sizing: border-box;
    float: left;
}
.fieldset > .field .fields.group.group-2 .field,
.fieldset > .fields > .field .fields.group.group-2 .field {
    width: 50% !important;
}
.fieldset > .field .fields.group.group-3 .field,
.fieldset > .fields > .field .fields.group.group-3 .field {
    width: 33.3% !important;
}
.fieldset > .field .fields.group.group-4 .field,
.fieldset > .fields > .field .fields.group.group-4 .field {
    width: 25% !important;
}
.fieldset > .field .fields.group.group-5 .field,
.fieldset > .fields > .field .fields.group.group-5 .field {
    width: 20% !important;
}
.fieldset > .field .addon,
.fieldset > .fields > .field .addon {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 0;
    width: 100%;
}
.fieldset > .field .addon textarea,
.fieldset > .fields > .field .addon textarea,
.fieldset > .field .addon select,
.fieldset > .fields > .field .addon select,
.fieldset > .field .addon input,
.fieldset > .fields > .field .addon input {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    display: inline-block;
    margin: 0;
    width: auto;
}
.fieldset > .field .addon .addbefore,
.fieldset > .fields > .field .addon .addbefore,
.fieldset > .field .addon .addafter,
.fieldset > .fields > .field .addon .addafter {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}
.fieldset > .field .addon .addbefore:disabled,
.fieldset > .fields > .field .addon .addbefore:disabled,
.fieldset > .field .addon .addafter:disabled,
.fieldset > .fields > .field .addon .addafter:disabled {
    opacity: 0.5;
}
.fieldset > .field .addon .addbefore::-moz-placeholder,
.fieldset > .fields > .field .addon .addbefore::-moz-placeholder,
.fieldset > .field .addon .addafter::-moz-placeholder,
.fieldset > .fields > .field .addon .addafter::-moz-placeholder {
    color: #575757;
}
.fieldset > .field .addon .addbefore::-webkit-input-placeholder,
.fieldset > .fields > .field .addon .addbefore::-webkit-input-placeholder,
.fieldset > .field .addon .addafter::-webkit-input-placeholder,
.fieldset > .fields > .field .addon .addafter::-webkit-input-placeholder {
    color: #575757;
}
.fieldset > .field .addon .addbefore:-ms-input-placeholder,
.fieldset > .fields > .field .addon .addbefore:-ms-input-placeholder,
.fieldset > .field .addon .addafter:-ms-input-placeholder,
.fieldset > .fields > .field .addon .addafter:-ms-input-placeholder {
    color: #575757;
}
.fieldset > .field .addon .addbefore,
.fieldset > .fields > .field .addon .addbefore {
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
}
.fieldset > .field .additional,
.fieldset > .fields > .field .additional {
    margin-top: 10px;
}
.fieldset > .field.required > .label:after,
.fieldset > .fields > .field.required > .label:after,
.fieldset > .field._required > .label:after,
.fieldset > .fields > .field._required > .label:after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem;
    margin: 0 0 0 5px;
}
.fieldset > .field .note,
.fieldset > .fields > .field .note {
    font-size: 1.2rem;
    margin: 3px 0 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
}
.fieldset > .field .note:before,
.fieldset > .fields > .field .note:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 12px;
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.fieldset > .field.choice .label,
.fieldset > .fields > .field.choice .label {
    display: inline;
    font-weight: normal;
    margin: 0;
}
.column:not(.main) .fieldset > .field .label,
.column:not(.main) .fieldset > .fields > .field .label {
    font-weight: normal;
}
.fieldset > .field .field.choice,
.fieldset > .fields > .field .field.choice {
    margin-bottom: 10px;
}
.fieldset > .field .field.choice:last-child,
.fieldset > .fields > .field .field.choice:last-child {
    margin-bottom: 0;
}
.legend + .fieldset,
.legend + div {
    clear: both;
}
.legend > span {
    margin-right: 5px;
}
fieldset.field {
    border: 0;
    padding: 0;
}
.field.date .time-picker {
    display: inline-block;
    margin-top: 10px;
    white-space: nowrap;
}
.field .message.warning {
    margin-top: 10px;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-size: 30px 30px;
    text-indent: .01em;
}
select::-ms-expand {
    display: none;
}
select.admin__control-multiselect {
    height: auto;
}
.field-error,
div.mage-error[generated] {
    margin-top: 30px;
}
.field-error {
    color: #e02b27;
    font-size: 1.2rem;
}
.product-options-wrapper .date.required div[for*='options'].mage-error {
    display: none !important;
}
.field .tooltip {
    position: relative;
}
.field .tooltip .tooltip-content {
    background: #ffffff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333333;
    line-height: 1.4;
    border: 1px solid #bbbbbb;
    margin-left: 5px;
    left: 100%;
    top: 0;
}
.field .tooltip .tooltip-content:after,
.field .tooltip .tooltip-content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
}
.field .tooltip .tooltip-content:after {
    border-width: 5px;
    border-color: transparent;
}
.field .tooltip .tooltip-content:before {
    border-width: 6px;
    border-color: transparent;
}
.field .tooltip .tooltip-content:after,
.field .tooltip .tooltip-content:before {
    right: 100%;
}
.field .tooltip .tooltip-content:after {
    border-right-color: #ffffff;
    margin-top: -5px;
    top: 15px;
}
.field .tooltip .tooltip-content:before {
    border-right-color: #bbbbbb;
    margin-top: -6px;
    top: 15px;
}
.field .tooltip .tooltip-toggle {
    cursor: help;
}
.field .tooltip .tooltip-toggle:hover + .tooltip-content,
.field .tooltip .tooltip-toggle:focus + .tooltip-content,
.field .tooltip:hover .tooltip-content {
    display: block;
}
.field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal;
}
input:focus ~ .tooltip .tooltip-content,
select:focus ~ .tooltip .tooltip-content {
    display: block;
}
._has-datepicker ~ .ui-datepicker-trigger {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: block;
    text-decoration: none;
    display: inline-block;
    margin-top: -4px;
    vertical-align: middle;
}
._has-datepicker ~ .ui-datepicker-trigger:focus,
._has-datepicker ~ .ui-datepicker-trigger:active {
    background: none;
    border: none;
}
._has-datepicker ~ .ui-datepicker-trigger:hover {
    background: none;
    border: none;
}
._has-datepicker ~ .ui-datepicker-trigger.disabled,
._has-datepicker ~ .ui-datepicker-trigger[disabled],
fieldset[disabled] ._has-datepicker ~ .ui-datepicker-trigger {
    pointer-events: none;
    opacity: 0.5;
}
._has-datepicker ~ .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
._has-datepicker ~ .ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 23px;
    line-height: 23px;
    color: #7d7d7d;
    content: '\e612';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
._has-datepicker ~ .ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0;
}
.sidebar .fieldset {
    margin: 0;
}
.sidebar .fieldset > .field:not(.choice):not(:last-child),
.sidebar .fieldset .fields > .field:not(:last-child) {
    margin: 0 0 20px;
}
.sidebar .fieldset > .field:not(.choice) .label,
.sidebar .fieldset .fields > .field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%;
}
.sidebar .fieldset > .field:not(.choice) .control,
.sidebar .fieldset .fields > .field .control {
    width: 100%;
}
