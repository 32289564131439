@font-face {
    font-family: 'pagebuilder-font';
    font-style: normal;
    font-weight: normal;
    src: url('../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.eot');
    src: url('../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.eot?#iefix')
            format('embedded-opentype'),
        url('../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.woff')
            format('woff'),
        url('../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.ttf')
            format('truetype');
}

.pagebuilder-icon,
.slick-prev:before,
.slick-next:before,
.slick-prev:after,
.slick-next:after {
    font-family: 'pagebuilder-font';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
}

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
    display: inline-block;
    font-family: 'pagebuilder-font';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
}

.pagebuilder-icon-down:before {
    content: '\f101';
}

.pagebuilder-icon-next:before {
    content: '\f102';
}

.pagebuilder-icon-prev:before {
    content: '\f103';
}

.pagebuilder-icon-up:before {
    content: '\f104';
}
