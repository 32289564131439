[data-content-type='banner'] > [data-element='link'],
[data-content-type='banner'] > [data-element='empty_link'] {
    color: inherit;
    text-decoration: inherit;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

.pagebuilder-banner-wrapper {
    background-clip: padding-box;
    border-radius: inherit;
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .pagebuilder-overlay {
        box-sizing: border-box;
        padding: $base-spacing;
        transition: background-color 500ms ease;

        &.pagebuilder-poster-overlay {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        &:not(.pagebuilder-poster-overlay) {
            max-width: none;

            @include media-query(min, $lap-start) {
                max-width: 50%;
            }
        }
    }

    .pagebuilder-collage-content {
    }

    [data-element='content'] {
        min-height: 50px;
        -webkit-font-smoothing: antialiased;
    }

    .pagebuilder-banner-button {
        margin: 20px 0 0 0;
        max-width: 100%;
        text-align: inherit;
        transition: opacity 500ms ease;
        word-break: break-word;
    }

    .pagebuilder-poster-content {
        width: auto;
    }

    @include media-query(max, $lap-start) {
        background-attachment: scroll !important;
    }
}

[data-appearance='collage-centered']
    .pagebuilder-banner-wrapper
    .pagebuilder-overlay {
    margin-left: auto;
    margin-right: auto;
}

[data-appearance='collage-left']
    .pagebuilder-banner-wrapper
    .pagebuilder-overlay {
    margin-right: auto;
}

[data-appearance='collage-right']
    .pagebuilder-banner-wrapper
    .pagebuilder-overlay {
    margin-left: auto;
}
