.warranty-register-index {
  .ui-datepicker {
    padding: $base-spacing;
    border: 1px solid $silver-lt;
  }
  .ui-datepicker-trigger {
    position: absolute;
    top: 0;
    right: 10px;
  }

  .ui-datepicker-calendar {
    border: none;
  }

  .warranty--field-group {
    display: flex;
  }

  .warranty--field {
    flex: 50%;
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  .ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    padding: 4px 0;
    margin: 0 0 10px 25px;
    list-style: none;
    background-color: #ffffff;
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
  }

  .ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #000000;
    white-space: nowrap;
    text-decoration: none;
  }

  .ui-menu-item:hover {
    text-decoration: none;
    background-color: #e2e2e2;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    background-image: none;
  }
}

.warranty-form {
  .field {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .field-error,div.mage-error[generated] {
    margin-top: 0;
    position: absolute;
    bottom: -3px;
    font-size: 12px;
  }
  input, select {
    margin-bottom: 20px;
  }
  .fieldset>.field:last-child {
    margin-bottom: 10px;
  }
}

@media only screen and  (max-width: 768px) {
  .warranty-register-index {
    .warranty--field {
      flex: 100%;
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;
    }
  }
}

.warranty__table {
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    margin-bottom: $double-spacing;
  }
}


.warranty__summary-details {
  @include font-size(14px);
  font-weight: bold;
}

.warranty__summary-list {
  @include font-size(14px);
}

@media print {
  .warranty-view-index {
    .site-actions, .block-search, nav, footer {
      display: none;
    }

    .site-header {
      background-color: $black !important;
    }

    .site-header__links {
      display: flex;

      a {
        color: $white !important;
        text-decoration: none !important;
      }
    }

    .site-links__item--dealer, .site-links__item--login {
      display: none;
    }

    .site-header__logo {
      display: inline-block;
      background-color: $black !important;
    }
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }

  td {
    padding-left: $base-spacing !important;
  }
  td:nth-of-type(odd) {
    background: $silver-lt !important;
  }

}
.warranty__list-wrapper {
  display:flex;
  justify-content: space-between;
  margin-bottom: $base-spacing;
  flex-wrap: wrap;
}
.warranty__list-item {
  width: 100%;
  margin-bottom: .5rem;
}