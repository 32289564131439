#co-transparent-form-braintree label span {
    font-weight: 600;
}

.payment-method-braintree .hosted-error {
    display: inline-block;
    padding-left: 20px;
    clear: both;
    color: red;
    font-size: 14px;
    height: 0;
    opacity: 0;
}

.payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused)~.hosted-error {
    height: auto;
    opacity: 1;
}

div#braintree_cc_number,
div#braintree_expirationDate,
div#braintree_cc_cid {
    display: inline-block;
    vertical-align: middle;
    background: #e5e5e5;
    border-radius: 14px;
    height: 44px;
    padding-left: 14px;
}

div#braintree_cc_number {
    width: 210px;
}

div#braintree_expirationDate {
    width: 115px;
}

div#braintree_cc_cid {
    width: 60px;
}

.field.cvv {
    .field-tooltip {
        display: inline-block;
        position: unset;
        padding-left: 10px;
        .field-tooltip-action {
            position: unset;
            &:before {
                padding-left: 0;
                margin-bottom: 1px;
                margin-right: 1px;
            }
        }
    }
}

.checkout-payment-method .credit-card-types {
    margin-left: 0;
}

.braintree-credit-card-selected {
    display: none;
}

.braintree-credit-card-selected.active {
    display: inline-block;
    vertical-align: middle;
    padding-left: 15px;
}

