.widget-block-after-title,
.widget-block-before-tabs,
.widget-block-after-tabs,
.widget-block-after-info,
.widget-block-after-all {
    clear: both;
    margin-bottom: $base-spacing;
    
    @include media-query (min, $lap-start) {
        margin-bottom: $base-spacing * 2;
    }
}

.widget-block-after-info {
    @include media-query (min, $lap-start) {
        display: flex;
    }
}

.widget-block-left,
.widget-block-center,
.widget-block-right {
    @include media-query (min, $lap-start) {
        width: calc(33.333% - (#{$base-spacing * 2} / 3));
        margin-right: $base-spacing;
        
        &:last-child {
            margin-right: 0;
        }
    }
}
