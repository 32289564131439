.page.messages {
  .messages {
    max-width: $page-width;
    margin: 0 auto $base-spacing;
    padding: 0 $half-spacing;

    @include media-query(min, $desk-start) {
      padding: 0 $base-and-half-spacing;
      margin-bottom: $double-spacing;
    }
  }
}

.messages {

}

.message {
  text-align: center;
  border-radius: $base-radius;
  padding: $half-spacing $base-spacing;
  background-color: $primary;
  color: $white;
  font-weight: 500;
  margin-bottom: $base-spacing;

  &.global {
    margin: 0;
  }

  .field & {
    margin-top: $half-spacing;
    margin-bottom: 0;
    text-align: left;
    @include font-size($micro-font-size);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
  a {
    color: $white;
    @include hover {
      color: $white;
    }
  }
}

.message-success {
  background-color: $green;
}
.message-error {
  background-color: $failure;
}

.message-notice {
  background-color: $alert;
}

.load.indicator {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.load.indicator:before {
  background: transparent url('../images/loader-2.gif') no-repeat 50% 50%;
  border-radius: 5px;
  height: 160px;
  width: 160px;
  bottom: 0;
  box-sizing: border-box;
  content: '';
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.load.indicator > span {
  display: none;
}
.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
}
.loading-mask .loader > img {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.loading-mask .loader > p {
  display: none;
}
body > .loading-mask {
  z-index: 9999;
}
._block-content-loading {
  position: relative;
}
[data-role='main-css-loader'] {
  display: none;
}

