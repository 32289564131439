.checkout-index-index {
	.columns,
	.container {
		max-width: $page-width;
	}

	.top-bar__stores {
		display: none;
	}

	.field {
		max-width: 100%;
	}

	.authentication-wrapper {
		@include media-query(min, $lap-start) {
		}

		.authentication-dropdown {
			text-align: left;
		}
	}
}

.checkout-container {
	position: relative;
	@include media-query(min, $lap-start) {
		@include clearfix;
	}
}

.opc-progress-bar {
	@include media-query(min, $lap-start) {
		float: left;
		width: 50%;
	}
}

.opc-estimated-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $half-spacing $base-spacing;
	margin-bottom: $double-spacing;
	border: 1px solid $mercury-dk;
	background: $mercury;
	border-radius: $base-radius;

	@include media-query(min, $lap-start) {
		display: none;
	}

	.estimated-label {
		font-weight: 500;
		margin-right: $micro-spacing;
	}

	.action.showcart {
		background-image: url("#{$image-path}/cart-white.svg");
		background-repeat: no-repeat;
		background-position: left $half-spacing center;
		padding-left: ($double-spacing + $half-spacing);
	}
}

.opc-wrapper {
	@include media-query(min, $lap-start) {
		float: left;
		width: 62%;
		padding-right: $double-spacing;
	}
	@include media-query(min, $desk-start) {
		padding-right: ($base-and-half-spacing * 2);
	}
}

.opc-sidebar {
	.modal-inner-wrap {
		.modal-header {
			@include media-query(min, $lap-start) {
				display: none;
			}
		}
	}

	.opc-sidebar__image {
		margin-top: $base-spacing;
	}

	.opc-block-summary {
		.title {
			display: block;
			margin-bottom: $base-spacing;
		}
	}

	.items-in-cart {
		position: relative;

		.title {
			position: relative;
			display: block;
			margin-bottom: $base-spacing;
			@include font-size(18px);
			font-weight: bold;
		}

		&.active {
			.title {
			}
		}
	}

	.minicart-items {
		list-style-type: none;
		margin-left: 0;

		.product-item {
			width: 100%;
			border: none;
			border-top: 1px solid $silver-lt;
			padding: $base-spacing 0;
			margin-left: 0;

			@include hover {
				transform: none;
				box-shadow: none;
				cursor: auto;
			}

			.label {
				cursor: auto;
			}

			.product {
			}

			.product-image-container {
				float: left;
			}

			.product-item-details {
				padding-left: 88px;
			}

			.product-item-inner {
				display: table;
				margin: 0 0 10px;
				width: 100%;
			}

			.product-item-name-block {
			}

			.product-item-name {
				display: block;
				hyphens: auto;
				margin: 5px 0;
				word-wrap: break-word;
				@include font-size(15px);
				margin-bottom: $half-spacing;
			}

			.details-qty {
				display: table-cell;
				text-align: left;

				.label {
					display: table-cell;
					text-align: left;
					padding-right: $half-spacing;
					@include font-size(15px);

					&:after {
						content: ":";
					}
				}

				.value {
					display: table-cell;
					text-align: left;
					@include font-size(15px);
				}
			}

			.subtotal {
				.price-including-tax {
					@include font-size(15px);
					color: $accent;
					font-weight: bold;
				}

				.price {
					@include font-size(15px);
				}
			}
		}
	}
}

.opc {
	list-style: none;
	margin: 0;
}

.step-title {
	display: block;
	margin-bottom: $base-spacing;
	@include font-size(35px);
	font-weight: bold;
	text-align: left;
}

.step-content {
	.methods-shipping {
		.actions-toolbar {
			text-align: right;
		}
	}
}

.table-checkout-shipping-method {
	margin-bottom: $base-spacing;

	thead {
		display: none;
	}

	tbody {
		text-align: left;

		.col-method {
		}
	}

	tr {
		display: table-row;
		padding: $base-spacing 0;
	}

	td {
		display: table-cell;
		width: auto;

		&:first-child {
			width: 20px;
			padding: 0;
			vertical-align: middle;
		}
	}

	input {
		margin: 0;
	}
}

.checkout-onepage-success .print {
	display: none;
}

.abs-discount-code .form-discount,
.checkout-payment-method .payment-option-content .form-discount {
	display: table;
	width: 100%;
}

.abs-discount-code .form-discount .actions-toolbar,
.checkout-payment-method .payment-option-content .form-discount .actions-toolbar {
	display: table-cell;
	vertical-align: bottom;
	width: 1%;

	@include media-query(max, $lap-start) {
		display: block;
		margin-top: $half-spacing;
	}
}

.abs-discount-code .form-discount .actions-toolbar .primary,
.checkout-payment-method .payment-option-content .form-discount .actions-toolbar .primary {
	float: left;
}

.abs-discount-code .form-discount .actions-toolbar .primary .action,
.checkout-payment-method .payment-option-content .form-discount .actions-toolbar .primary .action {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	margin: 0 0 0 -2px;
	white-space: nowrap;
	width: auto;
}

.abs-discount-code .form-discount > .field > .label,
.checkout-payment-method .payment-option-content .form-discount > .field > .label {
	display: none;
}

.opc-estimated-wrapper {
	background: #f4f4f4;
	border-bottom: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
	margin: 20px -15px;
	padding: 20px 15px;
}

.opc-estimated-wrapper .estimated-block {
	font-size: 18px;
	float: left;
	font-weight: 700;
}

.opc-estimated-wrapper .estimated-block .estimated-label {
	display: block;
	margin: 0 0 5px;
}

.opc-estimated-wrapper .minicart-wrapper button.action.showcart {
	background-image: none;
	background: none;
	-moz-box-sizing: content-box;
	border: 0;
	box-shadow: none;
	line-height: inherit;
	margin: 0;
	padding: 0;
	text-decoration: none;
	text-shadow: none;
	font-weight: 400;
}

.opc-estimated-wrapper .minicart-wrapper button.action.showcart:focus,
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:active {
	background: none;
	border: none;
}

.opc-estimated-wrapper .minicart-wrapper button.action.showcart:hover {
	background: none;
	border: none;
}

.opc-estimated-wrapper .minicart-wrapper button.action.showcart.disabled,
.opc-estimated-wrapper .minicart-wrapper button.action.showcart[disabled],
fieldset[disabled] .opc-estimated-wrapper .minicart-wrapper button.action.showcart {
	pointer-events: none;
	opacity: 0.5;
}

.opc-estimated-wrapper .minicart-wrapper button.action.showcart:before {
	color: #333333;
}

.opc-progress-bar {
	display: none;
}

.field.choice .field-tooltip {
	display: inline-block;
	margin-left: 10px;
	position: relative;
	top: -3px;
}

.field.choice .field-tooltip-action {
	line-height: 21px;
}

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
	border-color: #ed8380;
}

.checkout-index-index .modal-popup .fieldset .field .label {
	font-weight: 400;
}

.checkout-index-index .modal-popup .modal-footer .action-hide-popup {
	margin-top: 8px;
}

.field-tooltip {
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 1px;
}

.field-tooltip._active {
	z-index: 100;
}

.field-tooltip._active .field-tooltip-content {
	display: block;
}

.field-tooltip._active .field-tooltip-action:before {
	color: #333333;
}

.field-tooltip .label {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.field-tooltip .field-tooltip-action {
	display: inline-block;
	text-decoration: none;
}

.field-tooltip .field-tooltip-action > span {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.field-tooltip .field-tooltip-action:before {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 21px;
	line-height: inherit;
	color: #bbbbbb;
	content: "\e623";
	font-family: "luma-icons";
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}

.field-tooltip .field-tooltip-action:hover:before {
	color: #333333;
}

.field-tooltip .field-tooltip-action:before {
	padding-left: 1px;
}

._keyfocus .field-tooltip .field-tooltip-action:focus {
	z-index: 100;
}

._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
	display: block;
}

._keyfocus .field-tooltip .field-tooltip-action:focus:before {
	color: #333333;
}

.field-tooltip .field-tooltip-content {
	background: $black;
	border: 1px solid $black;
	border-radius: 1px;
	font-size: 14px;
	padding: 12px;
	width: 270px;
	display: none;
	left: 38px;
	position: absolute;
	text-transform: none;
	top: -9px;
	word-wrap: break-word;
	z-index: 2;
}

.field-tooltip .field-tooltip-content:before,
.field-tooltip .field-tooltip-content:after {
	border: 10px solid transparent;
	height: 0;
	width: 0;
	border-right-color: $black;
	left: -21px;
	top: 12px;
	content: "";
	display: block;
	position: absolute;
	z-index: 3;
}

.field-tooltip .field-tooltip-content:before {
	border-right-color: $black;
}

.field-tooltip .field-tooltip-content:after {
	border-right-color: $black;
	width: 1px;
	z-index: 4;
}

@media only screen and (max-width: 768px) {
	.field-tooltip .field-tooltip-content {
		left: auto;
		right: -10px;
		top: 40px;
	}
	.field-tooltip .field-tooltip-content::before,
	.field-tooltip .field-tooltip-content::after {
		border: 10px solid transparent;
		height: 0;
		left: auto;
		margin-top: -21px;
		right: 10px;
		top: 0;
		width: 0;
	}
	.field-tooltip .field-tooltip-content::before {
		border-bottom-color: $black;
	}
	.field-tooltip .field-tooltip-content::after {
		border-bottom-color: $black;
		top: 1px;
	}
}

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
	margin-top: 28px;
	margin-bottom: 28px;
}

.opc-wrapper .form-login .fieldset .field .label,
.opc-wrapper .form-shipping-address .fieldset .field .label {
	font-weight: 400;
}

.opc-wrapper .form-login .fieldset .note,
.opc-wrapper .form-shipping-address .fieldset .note {
	font-size: 14px;
	margin-top: 10px;
}

.opc-wrapper .shipping-address-items {
	font-size: 0;
}

.opc-wrapper .shipping-address-item {
	border: 2px solid transparent;
	font-size: 14px;
	line-height: 30px;
	margin: 0 0 20px;
	padding: 20px 35px 20px 20px;
	transition: 0.3s border-color;
	width: 50%;
	display: inline-block;
	position: relative;
	vertical-align: top;
	word-wrap: break-word;
}

.opc-wrapper .shipping-address-item.selected-item {
	border-color: #ff5501;
}

.opc-wrapper .shipping-address-item.selected-item:after {
	background: #ff5501;
	color: #ffffff;
	height: 27px;
	width: 29px;
	font-size: 19px;
	line-height: 21px;
	padding-top: 2px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
}

.opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
	visibility: hidden;
}

.opc-wrapper .action-show-popup {
	margin: 0 0 20px;
}

.opc-wrapper .action-show-popup > span:before {
	content: "+";
	padding-right: 5px;
}

.opc-wrapper .action-select-shipping-item {
	float: right;
	margin: 20px 0 0;
}

.opc-wrapper .edit-address-link {
	display: block;
	float: left;
	margin: 26px 5px 0 0;
}

.checkout-shipping-method .step-title {
	margin-bottom: 0;
}

.checkout-shipping-method .no-quotes-block {
	margin: 20px 0;
}

.methods-shipping .actions-toolbar .action.primary {
	margin: 20px 0 0;
}

.table-checkout-shipping-method thead th {
	display: none;
}

.table-checkout-shipping-method tbody td {
	border-top: 1px solid #cccccc;
	padding-bottom: 20px;
	padding-top: 20px;
}

.table-checkout-shipping-method tbody td:first-child {
	padding-left: 0;
	padding-right: 0;
	width: 20px;
}

.table-checkout-shipping-method tbody tr:first-child td {
	border-top: none;
}

.table-checkout-shipping-method tbody .col-price {
	font-weight: 600;
}

.table-checkout-shipping-method tbody .row-error td {
	border-top: none;
	padding-bottom: 10px;
	padding-top: 0;
}

.checkout-shipping-method {
	position: relative;
}

.shipping-policy-block.field-tooltip {
	top: 12px;
}

.shipping-policy-block.field-tooltip .field-tooltip-action {
	color: #006bb4;
	cursor: pointer;
}

.shipping-policy-block.field-tooltip .field-tooltip-action:before {
	display: none;
}

.shipping-policy-block.field-tooltip .field-tooltip-content {
	width: 420px;
	top: 30px;
}

.opc-block-shipping-information {
	padding: 0 30px;
}

.opc-block-shipping-information .shipping-information-title {
	border-bottom: 1px solid #cccccc;
	margin: 0 0 20px;
	position: relative;
}

.opc-block-shipping-information .shipping-information-title .action-edit {
	top: 10px;
	display: inline-block;
	text-decoration: none;
	margin: 0;
	position: absolute;
	right: 0;
}

.opc-block-shipping-information .shipping-information-title .action-edit > span {
}

.opc-block-shipping-information .shipping-information-title .action-edit:before {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 18px;
	line-height: 20px;
	color: #757575;
	content: "\e601";
	font-family: "luma-icons";
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}

.opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
	color: #333333;
}

.opc-block-shipping-information .shipping-information-title .action-edit:active:before {
	color: #757575;
}

.opc-block-shipping-information .shipping-information-content {
	line-height: 27px;
}

.opc-block-shipping-information .shipping-information-content .actions-toolbar {
	margin-left: 0;
}

.opc-block-shipping-information .ship-to,
.opc-block-shipping-information .ship-via {
	margin: 0 0 20px;
}

.opc-block-summary {
	background: #f5f5f5;
	margin: 0 0 20px;
	padding: 22px 30px;

	@include media-query(min, $lap-start) {
		margin: 0;
	}
}

.opc-block-summary > .title {
	display: block;
}

.opc-block-summary .mark .value {
	color: #666666;
	display: block;
}

.opc-block-summary .discount.coupon {
	display: none;
}

.opc-block-summary .grand.incl + .grand.excl .mark,
.opc-block-summary .grand.incl + .grand.excl .amount {
	border-top: 0;
	font-size: 1.4rem;
	padding-top: 0;
}

.opc-block-summary .grand.incl + .grand.excl .mark strong,
.opc-block-summary .grand.incl + .grand.excl .amount strong {
	font-weight: 400;
}

.opc-block-summary .not-calculated {
	font-style: italic;
}

.opc-block-summary .items-in-cart > .title {
	border-bottom: 1px solid #cccccc;
	padding: 10px 40px 10px 0;
	cursor: pointer;
	display: block;
	text-decoration: none;
	margin-bottom: 0;
	position: relative;
}

.opc-block-summary .items-in-cart > .title > span {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.opc-block-summary .items-in-cart > .title:after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 12px;
	line-height: 12px;
	color: inherit;
	content: "\e622";
	font-family: "luma-icons";
	margin: 3px 0 0;
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}

.opc-block-summary .items-in-cart > .title:after {
	position: absolute;
	right: 0;
	top: 10px;
}

.opc-block-summary .items-in-cart > .title strong {
	@include font-size(16px);
	font-weight: 400;
	margin: 0;
}

.opc-block-summary .items-in-cart.active > .title:after {
}

.opc-block-summary .items-in-cart .product {
	position: relative;
}

.opc-block-summary .minicart-items-wrapper {
	margin: 0 -15px 0 0;
	max-height: 370px;
	padding: 15px 15px 0 0;
	border: 0;
	overflow-x: scroll;
}

.column.main .opc-block-summary .product-item {
	margin: 0;
	padding-left: 0;
}

.opc-block-summary .product-item .product-item-inner {
	display: table;
	margin: 0 0 10px;
	width: 100%;
}

.opc-block-summary .product-item .product-item-name-block {
	display: block;
	padding-right: 5px;
	text-align: left;
}

.opc-block-summary .product-item .subtotal {
	display: block;
	text-align: left;
}

.opc-block-summary .product-item .price {
	font-size: 1.6rem;
	font-weight: 400;
}

.opc-block-summary .product-item .price-including-tax + .price-excluding-tax .price {
	font-size: 1rem;
}

.opc-block-summary .product-item .message {
	margin-top: 10px;
}

.opc-block-summary .actions-toolbar {
	position: relative;
	z-index: 1;
}

.opc-block-summary .actions-toolbar .secondary {
	border-top: 1px solid #cccccc;
	display: block;
	margin: -1px 0 0;
	padding: 15px 0 0;
	text-align: left;
}

.opc-block-summary .actions-toolbar .secondary .action {
	margin: 0;
}

@media only screen and (max-width: 768px) {
	.opc-block-summary .product-item .product-item-inner {
		display: block;
	}
	.opc-block-summary .product-item .product-item-name-block {
		display: block;
		text-align: left;
	}
	.opc-block-summary .product-item .subtotal {
		display: block;
		text-align: left;
	}
}

.authentication-dropdown {
	box-sizing: border-box;
}

.authentication-dropdown .modal-inner-wrap {
	padding: 25px;
}

.authentication-wrapper {
	float: right;
	position: relative;
	z-index: 1;
}

._has-auth-shown .authentication-wrapper {
	z-index: 900;
}

.block-authentication .block-title {
	font-size: 1.8rem;
	border-bottom: 0;
	margin-bottom: 25px;
}

.block-authentication .block-title strong {
	font-weight: 300;
}

.block-authentication .field .label {
	font-weight: 400;
}

.block-authentication .actions-toolbar {
	margin-bottom: 5px;
}

.block-authentication .actions-toolbar > .secondary {
	padding-top: 25px;
	text-align: left;
}

.block-authentication .block[class] {
	margin: 0;
}

.block-authentication .block[class] ul {
	list-style: none;
	padding-left: 10px;
}

.block-authentication .block[class] .field .control,
.block-authentication .block[class] .field .label {
	float: none;
	width: auto;
}

.block-authentication .block[class] + .block {
	border-top: 1px solid #c1c1c1;
	margin-top: 40px;
	padding-top: 40px;
	position: relative;
}

.block-authentication .block[class] + .block:before {
	height: 36px;
	line-height: 34px;
	margin: -19px 0 0 -18px;
	min-width: 36px;
	background: #ffffff;
	border: 1px solid #c1c1c1;
	border-radius: 50%;
	box-sizing: border-box;
	color: #c1c1c1;
	content: attr(data-label);
	display: inline-block;
	left: 50%;
	letter-spacing: normal;
	padding: 0 0.2rem;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: 0;
}

.checkout-payment-method .step-title {
	border-bottom: 0;
	margin-bottom: 0;
}

.checkout-payment-method .payment-method:first-child .payment-method-title {
	border-top: 0;
}

.checkout-payment-method .payment-method._active .payment-method-content {
	display: block;
}

.checkout-payment-method .payment-method + .payment-method .payment-method-title {
	border-top: 1px solid #cccccc;
}

.checkout-payment-method .payment-method form.form-purchase-order {
	margin-bottom: 15px;
}

.checkout-payment-method .payment-method-content {
	/**
               * @codingStandardsIgnoreStart
               */
}

.checkout-payment-method .payment-method-content #po_number {
	margin-bottom: 20px;
}

.checkout-payment-method .payment-method-title {
	padding: 20px 0;
	margin: 0;
}

.checkout-payment-method .payment-method-title .payment-icon {
	display: inline-block;
	margin-right: 5px;
	vertical-align: middle;
}

.checkout-payment-method .payment-method-title .action-help {
	display: inline-block;
	margin-left: 5px;
}

.checkout-payment-method .payment-method-content {
	display: none;
	padding: 0 0 20px 22px;
}

.checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
	margin: 0 0 20px;
}

.checkout-payment-method .payment-group + .payment-group .step-title {
	margin: 20px 0 0;
}

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
	max-width: 500px;
}

.checkout-payment-method .billing-address-same-as-shipping-block {
	margin: 0 0 10px;
}

.checkout-payment-method .checkout-billing-address {
	margin: 0 0 20px;
}

.checkout-payment-method .checkout-billing-address .primary .action-update {
	margin-bottom: 20px;
	margin-right: 0;
}

.checkout-payment-method .checkout-billing-address .billing-address-details {
	line-height: 27px;
	padding: 10px 20px;
	border: 1px solid $silver-lt;
}

.checkout-payment-method .payment-method-note + .checkout-billing-address {
	margin-top: 20px;
}

.checkout-payment-method .payment-method-iframe {
	background-color: transparent;
	display: none;
	width: 100%;
}

.checkout-payment-method .no-payments-block {
	margin: 20px 0;
}

.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
}

.checkout-payment-method .payment-option._collapsible .payment-option-title {
	cursor: pointer;
}

.checkout-payment-method .payment-option._collapsible .payment-option-content {
	display: none;
}

.checkout-payment-method .payment-option-title {
	border-top: 1px solid #cccccc;
	padding: 20px;
}

.checkout-payment-method .payment-option-title .action-toggle {
	color: #006bb4;
	display: inline-block;
	text-decoration: none;
}

.checkout-payment-method .payment-option-title .action-toggle:after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	line-height: 16px;
	color: #757575;
	content: "\e622";
	font-family: "luma-icons";
	margin: 0 0 0 10px;
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}

.checkout-payment-method .payment-option-title .action-toggle:hover:after {
	color: #333333;
}

.checkout-payment-method .payment-option-title .action-toggle:active:after {
	color: #757575;
}

.checkout-payment-method .payment-option-content {
	padding: 0 0 20px 22px;
}

.checkout-payment-method .payment-option-content .primary .action.action-apply {
	margin-right: 0;
}

.checkout-payment-method .payment-option-inner {
	margin-bottom: 0;

	.field {
		margin-bottom: 0;
	}
}

.checkout-payment-method .credit-card-types {
	padding: 0;
}

.checkout-payment-method .credit-card-types .item {
	display: inline-block;
	list-style: none;
	margin: 0 5px 0 0;
	vertical-align: top;
}

.checkout-payment-method .credit-card-types .item._active {
	font-weight: 700;
}

.checkout-payment-method .credit-card-types .item._active img {
	-webkit-filter: grayscale(0%);
}

.checkout-payment-method .credit-card-types .item._inactive {
	opacity: 0.4;
}

.checkout-payment-method .credit-card-types .item span {
	display: inline-block;
	padding-top: 6px;
	vertical-align: top;
}

.checkout-payment-method .ccard .fields > .year {
	padding-left: 5px;
}

.checkout-payment-method .ccard .fields .select {
	padding-left: 5px;
	padding-right: 5px;
}

.checkout-payment-method .ccard .month .select {
	width: 140px;
}

.checkout-payment-method .ccard .year .select {
	width: 80px;
}

.checkout-payment-method .ccard .captcha .input-text,
.checkout-payment-method .ccard .number .input-text {
	width: 225px;
}

.checkout-payment-method .ccard > .field.cvv > .control {
	display: inline-block;
	padding-right: 20px;
}

.checkout-payment-method .ccard .cvv .label {
	display: block;
}

.checkout-payment-method .ccard .cvv .input-text {
	width: 55px;
}

.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
	width: auto !important;
}

.checkout-agreements-block .checkout-agreements {
	margin-bottom: 20px;
}

.checkout-agreements-block .action-show {
	vertical-align: baseline;
}

.checkout-agreements-items {
	padding-bottom: 30px;
}

.checkout-agreements-items .checkout-agreements-item {
	margin-bottom: 20px;
}

.checkout-agreements-items .checkout-agreements-item-title {
	border-bottom: 0;
}

.checkout-agreement-item-content {
	overflow: auto;
}

.form-giftcard-account {
	.actions-toolbar {
		display: flex;
		justify-content: space-between;
		margin-top: $half-spacing;
	}
}

.form.payments {
	.legend {
		display: none;
	}
}

.checkout-container {
	margin: 0 0 20px;
}
.opc-wrapper {
	margin: 0 0 20px;
}
.opc-wrapper .step-title {
	border-bottom: 1px solid #cccccc;
	margin-bottom: 15px;
}
.opc-wrapper .step-content {
	margin: 0 0 40px;
}
.checkout-index-index .nav-sections,
.checkout-index-index .nav-toggle {
	display: none;
}
.checkout-index-index .logo {
	margin-left: 0;
}
.checkout-onepage-success .print {
	display: none;
}
.abs-discount-code .form-discount,
.checkout-payment-method .payment-option-content .form-discount {
	display: table;
	width: 100%;
}
.abs-discount-code .form-discount .actions-toolbar,
.checkout-payment-method .payment-option-content .form-discount .actions-toolbar {
	display: table-cell;
	vertical-align: bottom;
	width: 1%;
}
.opc-payment-additional {
    clear: both;
}
.abs-discount-code .form-discount .actions-toolbar .primary,
.checkout-payment-method .payment-option-content .form-discount .actions-toolbar .primary {
	float: left;
}
.abs-discount-code .form-discount .actions-toolbar .primary .action,
.checkout-payment-method .payment-option-content .form-discount .actions-toolbar .primary .action {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	margin: 0 0 0 -2px;
	white-space: nowrap;
	width: auto;
}
.abs-discount-code .form-discount > .field > .label,
.checkout-payment-method .payment-option-content .form-discount > .field > .label {
	display: none;
}
.opc-estimated-wrapper {
	background: #f4f4f4;
	border-bottom: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
	margin: 0 -15px 15px;
	padding: 18px 15px;
}
.opc-estimated-wrapper .estimated-block {
	font-size: 18px;
	float: left;
	font-weight: 700;
}
.opc-estimated-wrapper .estimated-block .estimated-label {
	display: block;
	margin: 0 0 5px;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart {
	background-image: none;
	background: none;
	-moz-box-sizing: content-box;
	border: 0;
	box-shadow: none;
	line-height: inherit;
	margin: 0;
	padding: 0;
	text-decoration: none;
	text-shadow: none;
	font-weight: 400;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:focus,
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:active {
	background: none;
	border: none;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:hover {
	background: none;
	border: none;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart.disabled,
.opc-estimated-wrapper .minicart-wrapper button.action.showcart[disabled],
fieldset[disabled] .opc-estimated-wrapper .minicart-wrapper button.action.showcart {
	pointer-events: none;
	opacity: 0.5;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:before {
	color: #333333;
}
.opc-progress-bar {
	display: none;
}
.field.choice .field-tooltip {
	display: inline-block;
	margin-left: 10px;
	position: relative;
	top: -3px;
}
.field.choice .field-tooltip-action {
	line-height: 21px;
}
.field._error .control input,
.field._error .control select,
.field._error .control textarea {
	border-color: #ed8380;
}
.checkout-index-index .modal-popup .fieldset .field .label {
	font-weight: 400;
}
.checkout-index-index .modal-popup .modal-footer .action-hide-popup {
	margin-top: 8px;
}
.field-tooltip {
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 1px;
}
.field-tooltip._active {
	z-index: 100;
}
.field-tooltip._active .field-tooltip-content {
	display: block;
}
.field-tooltip._active .field-tooltip-action:before {
	color: #333333;
}
.field-tooltip .label {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.field-tooltip .field-tooltip-action {
	display: inline-block;
	text-decoration: none;
}
.field-tooltip .field-tooltip-action > span {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.field-tooltip .field-tooltip-action:before {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 21px;
	line-height: inherit;
	color: #bbbbbb;
	content: "\e623";
	font-family: "luma-icons";
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}
.field-tooltip .field-tooltip-action:hover:before {
	color: #333333;
}
.field-tooltip .field-tooltip-action:before {
	padding-left: 1px;
}
._keyfocus .field-tooltip .field-tooltip-action:focus {
	z-index: 100;
}
._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
	display: block;
}
._keyfocus .field-tooltip .field-tooltip-action:focus:before {
	color: #333333;
}
.field-tooltip .field-tooltip-content {
	background: #f4f4f4;
	border: 1px solid #999999;
	border-radius: 1px;
	font-size: 14px;
	padding: 12px;
	width: 270px;
	display: none;
	left: 38px;
	position: absolute;
	text-transform: none;
	top: -9px;
	word-wrap: break-word;
	z-index: 2;
}
.field-tooltip .field-tooltip-content:before,
.field-tooltip .field-tooltip-content:after {
	border: 10px solid transparent;
	height: 0;
	width: 0;
	border-right-color: #f4f4f4;
	left: -21px;
	top: 12px;
	content: "";
	display: block;
	position: absolute;
	z-index: 3;
}
.field-tooltip .field-tooltip-content:before {
	border-right-color: #666666;
}
.field-tooltip .field-tooltip-content:after {
	border-right-color: #f4f4f4;
	width: 1px;
	z-index: 4;
}
@media only screen and (max-width: 768px) {
	.field-tooltip .field-tooltip-content {
		left: auto;
		right: -10px;
		top: 40px;
	}
	.field-tooltip .field-tooltip-content::before,
	.field-tooltip .field-tooltip-content::after {
		border: 10px solid transparent;
		height: 0;
		left: auto;
		margin-top: -21px;
		right: 10px;
		top: 0;
		width: 0;
	}
	.field-tooltip .field-tooltip-content::before {
		border-bottom-color: #999999;
	}
	.field-tooltip .field-tooltip-content::after {
		border-bottom-color: #f4f4f4;
		top: 1px;
	}
}
.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
	margin-top: 28px;
	margin-bottom: 28px;
}
.opc-wrapper .form-login .fieldset .field .label,
.opc-wrapper .form-shipping-address .fieldset .field .label {
	font-weight: 400;
}
.opc-wrapper .form-login .fieldset .note,
.opc-wrapper .form-shipping-address .fieldset .note {
	font-size: 14px;
	margin-top: 10px;
}
.opc-wrapper .shipping-address-items {
	font-size: 0;
}
.opc-wrapper .shipping-address-item {
	border: 2px solid transparent;
	font-size: 14px;
	line-height: 30px;
	margin: 0 0 20px;
	padding: 20px 35px 20px 20px;
	transition: 0.3s border-color;
	width: 50%;
	display: inline-block;
	position: relative;
	vertical-align: top;
	word-wrap: break-word;
}
.opc-wrapper .shipping-address-item.selected-item {
	border-color: #ff5501;
}
.opc-wrapper .shipping-address-item.selected-item:after {
	background: #ff5501;
	color: #ffffff;

	height: 27px;
	width: 29px;
	font-size: 19px;
	line-height: 21px;
	padding-top: 2px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
}
.opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
	visibility: hidden;
}
.opc-wrapper .action-show-popup {
	margin: 0 0 20px;
}
.opc-wrapper .action-show-popup > span:before {
	content: "+";
	padding-right: 5px;
}
.opc-wrapper .action-select-shipping-item {
	float: right;
	margin: 20px 0 0;
}
.opc-wrapper .edit-address-link {
	display: block;
	float: left;
	margin: 26px 5px 0 0;
}
.checkout-shipping-method .step-title {
	margin-bottom: 0;
}
.checkout-shipping-method .no-quotes-block {
	margin: 20px 0;
}
.methods-shipping .actions-toolbar .action.primary {
	margin: 20px 0 0;
}
.table-checkout-shipping-method thead th {
	display: none;
}
.table-checkout-shipping-method tbody td {
	border-top: 1px solid #cccccc;
	padding-bottom: 20px;
	padding-top: 20px;
}
.table-checkout-shipping-method tbody td:first-child {
	padding-left: 0;
	padding-right: 0;
	width: 20px;
}
.table-checkout-shipping-method tbody tr:first-child td {
	border-top: none;
}
.table-checkout-shipping-method tbody .col-price {
	font-weight: 600;
}
.table-checkout-shipping-method tbody .row-error td {
	border-top: none;
	padding-bottom: 10px;
	padding-top: 0;
}
.checkout-shipping-method {
	position: relative;
}
.shipping-policy-block.field-tooltip {
	top: 12px;
}
.shipping-policy-block.field-tooltip .field-tooltip-action {
	color: #006bb4;
	cursor: pointer;
}
.shipping-policy-block.field-tooltip .field-tooltip-action:before {
	display: none;
}
.shipping-policy-block.field-tooltip .field-tooltip-content {
	width: 420px;
	top: 30px;
}
.opc-block-shipping-information {
	padding: 0 30px;
}
.opc-block-shipping-information .shipping-information-title {
	border-bottom: 1px solid #cccccc;
	margin: 0 0 20px;
	position: relative;
}
.opc-block-shipping-information .shipping-information-title .action-edit {
	top: 10px;
	display: inline-block;
	text-decoration: none;
	margin: 0;
	position: absolute;
	right: 0;
	background: none;
	border: none;
}
.opc-block-shipping-information .shipping-information-title .action-edit > span {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.opc-block-shipping-information .shipping-information-title .action-edit:before {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 18px;
	line-height: 20px;
	color: #757575;
	content: "\e601";
	font-family: "luma-icons";
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}
.opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
	color: #333333;
}
.opc-block-shipping-information .shipping-information-title .action-edit:active:before {
	color: #757575;
}
.opc-block-shipping-information .shipping-information-content {
	line-height: 27px;
}
.opc-block-shipping-information .shipping-information-content .actions-toolbar {
	margin-left: 0;
}
.opc-block-shipping-information .ship-to,
.opc-block-shipping-information .ship-via {
	margin: 0 0 20px;
}
.opc-block-summary {
	margin: 0 0 20px;
	padding: 22px 30px;
}
.opc-block-summary > .title {
	display: block;
}
.opc-block-summary .mark .value {
	color: #666666;
	display: block;
}
.opc-block-summary .discount.coupon {
	display: none;
}
.opc-block-summary .grand.incl + .grand.excl .mark,
.opc-block-summary .grand.incl + .grand.excl .amount {
	border-top: 0;
	font-size: 1.4rem;
	padding-top: 0;
}
.opc-block-summary .grand.incl + .grand.excl .mark strong,
.opc-block-summary .grand.incl + .grand.excl .amount strong {
	font-weight: 400;
}
.opc-block-summary .not-calculated {
	font-style: italic;
}
.opc-block-summary .items-in-cart > .title {
	border-bottom: 1px solid #cccccc;
	padding: 10px 40px 10px 0;
	cursor: pointer;
	display: block;
	text-decoration: none;
	margin-bottom: 0;
	position: relative;
}
.opc-block-summary .items-in-cart > .title > span {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.opc-block-summary .items-in-cart > .title:after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 12px;
	line-height: 12px;
	color: inherit;
	content: "\e622";
	font-family: "luma-icons";
	margin: 3px 0 0;
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}
.opc-block-summary .items-in-cart > .title:after {
	position: absolute;
	right: 0;
	top: 10px;
}
.opc-block-summary .items-in-cart > .title strong {
	@include font-size(16px);
	font-weight: 300;
	margin: 0;
}
.opc-block-summary .items-in-cart.active > .title:after {
}
.opc-block-summary .items-in-cart .product {
	position: relative;
}
.opc-block-summary .minicart-items-wrapper {
	margin: 0 -15px 0 0;
	max-height: 370px;
	padding: 15px 15px 0 0;
	border: 0;
}
.column.main .opc-block-summary .product-item {
	margin: 0;
	padding-left: 0;
}
.opc-block-summary .product-item .product-item-inner {
	display: table;
	margin: 0 0 10px;
	width: 100%;
}
.opc-block-summary .product-item .product-item-name-block {
	display: block;
	padding-right: 5px;
	text-align: left;
}
.opc-block-summary .product-item .subtotal {
	display: block;
	text-align: left;
	font-weight: bold;
	color: $accent;
}
.opc-block-summary .product-item .price {
	font-size: 1.6rem;
	font-weight: 400;
}
.opc-block-summary .product-item .price-including-tax + .price-excluding-tax .price {
	font-size: 1rem;
}
.opc-block-summary .product-item .message {
	margin-top: 10px;
}
.opc-block-summary .actions-toolbar {
	position: relative;
	z-index: 1;
}
.opc-block-summary .actions-toolbar .secondary {
	border-top: 1px solid #cccccc;
	display: block;
	margin: -1px 0 0;
	padding: 15px 0 0;
	text-align: left;
}
.opc-block-summary .actions-toolbar .secondary .action {
	margin: 0;
}
@media only screen and (max-width: 768px) {
	.opc-block-summary .product-item .product-item-inner {
		display: block;
	}
	.opc-block-summary .product-item .product-item-name-block {
		display: block;
		text-align: left;
	}
	.opc-block-summary .product-item .subtotal {
		display: block;
		text-align: left;
	}
}
.authentication-dropdown {
	box-sizing: border-box;
}
.authentication-dropdown .modal-inner-wrap {
	padding: 25px;
}
.authentication-wrapper {
	float: right;
	margin-top: -80px;
	max-width: 50%;
	position: relative;
	z-index: 1;
}
._has-auth-shown .authentication-wrapper {
	z-index: 900;
}
.block-authentication .block-title {
	font-size: 1.8rem;
	border-bottom: 0;
	margin-bottom: 25px;
}
.block-authentication .block-title strong {
	font-weight: 300;
}
.block-authentication .field .label {
	font-weight: 400;
}
.block-authentication .actions-toolbar {
	margin-bottom: 5px;
}
.block-authentication .actions-toolbar > .secondary {
	padding-top: 25px;
	text-align: left;
}
.block-authentication .block[class] {
	margin: 0;
}
.block-authentication .block[class] ul {
	list-style: none;
	padding-left: 10px;
}
.block-authentication .block[class] .field .control,
.block-authentication .block[class] .field .label {
	float: none;
	width: auto;
}
.block-authentication .block[class] + .block {
	border-top: 1px solid #c1c1c1;
	margin-top: 40px;
	padding-top: 40px;
	position: relative;
}
.block-authentication .block[class] + .block:before {
	height: 36px;
	line-height: 34px;
	margin: -19px 0 0 -18px;
	min-width: 36px;
	background: #ffffff;
	border: 1px solid #c1c1c1;
	border-radius: 50%;
	box-sizing: border-box;
	color: #c1c1c1;
	content: attr(data-label);
	display: inline-block;
	left: 50%;
	letter-spacing: normal;
	padding: 0 0.2rem;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: 0;
}
.checkout-payment-method .step-title {
	border-bottom: 0;
	margin-bottom: 0;
}
.checkout-payment-method .payment-method:first-child .payment-method-title {
	border-top: 0;
}
.checkout-payment-method .payment-method._active .payment-method-content {
	display: block;
}
.checkout-payment-method .payment-method + .payment-method .payment-method-title {
	border-top: 1px solid #cccccc;
}
.checkout-payment-method .payment-method form.form-purchase-order {
	margin-bottom: 15px;
}
.checkout-payment-method .payment-method-content {
	/**
               * @codingStandardsIgnoreStart
               */
}
.checkout-payment-method .payment-method-content #po_number {
	margin-bottom: 20px;
}
.checkout-payment-method .payment-method-title {
	padding: 20px 0;
	margin: 0;
}
.checkout-payment-method .payment-method-title .payment-icon {
	display: inline-block;
	margin-right: 5px;
	vertical-align: middle;
}
.checkout-payment-method .payment-method-title .action-help {
	display: inline-block;
	margin-left: 5px;
}
.checkout-payment-method .payment-method-content {
	display: none;
	padding: 0 0 20px 22px;
}
.checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
	margin: 0 0 20px;
}
.checkout-payment-method .payment-group + .payment-group .step-title {
	margin: 20px 0 0;
}
.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
	max-width: 500px;
}
.checkout-payment-method .billing-address-same-as-shipping-block {
	margin: 0 0 10px;
}
.checkout-payment-method .checkout-billing-address {
	margin: 0 0 20px;
}
.checkout-payment-method .checkout-billing-address .primary .action-update {
	margin-bottom: 20px;
	margin-right: 0;
}
.checkout-payment-method .checkout-billing-address .billing-address-details {
	line-height: 27px;
	padding: 0 0 0 23px;
}
.checkout-payment-method .payment-method-note + .checkout-billing-address {
	margin-top: 20px;
}
.checkout-payment-method .payment-method-iframe {
	background-color: transparent;
	display: none;
	width: 100%;
}
.checkout-payment-method .no-payments-block {
	margin: 20px 0;
}
.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
}
.checkout-payment-method .payment-option._collapsible .payment-option-title {
	cursor: pointer;
}
.checkout-payment-method .payment-option._collapsible .payment-option-content {
	display: none;
}
.checkout-payment-method .payment-option-title {
	border-top: 1px solid #cccccc;
	padding: 20px 0;
}
.checkout-payment-method .payment-option-title .action-toggle {
	color: #006bb4;
	display: inline-block;
	text-decoration: none;
}
.checkout-payment-method .payment-option-title .action-toggle:after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	line-height: 16px;
	color: #757575;
	content: "\e622";
	font-family: "luma-icons";
	margin: 0 0 0 10px;
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}
.checkout-payment-method .payment-option-title .action-toggle:hover:after {
	color: #333333;
}
.checkout-payment-method .payment-option-title .action-toggle:active:after {
	color: #757575;
}
.checkout-payment-method .payment-option-content {
	padding: 0 0 20px 22px;
}
.checkout-payment-method .payment-option-content .primary .action.action-apply {
	margin-right: 0;
}
.checkout-payment-method .payment-option-inner {
	margin: 0 0 20px;
}
.checkout-payment-method .credit-card-types {
	padding: 0;
}
.checkout-payment-method .credit-card-types .item {
	display: inline-block;
	list-style: none;
	margin: 0 5px 0 0;
	vertical-align: top;
}
.checkout-payment-method .credit-card-types .item._active {
	font-weight: 700;
}
.checkout-payment-method .credit-card-types .item._active img {
	-webkit-filter: grayscale(0%);
}
.checkout-payment-method .credit-card-types .item._inactive {
	opacity: 0.4;
}
.checkout-payment-method .credit-card-types .item span {
	display: inline-block;
	padding-top: 6px;
	vertical-align: top;
}
.checkout-payment-method .ccard .fields > .year {
	padding-left: 5px;
}
.checkout-payment-method .ccard .fields .select {
	padding-left: 5px;
	padding-right: 5px;
}
.checkout-payment-method .ccard .month .select {
	width: 140px;
}
.checkout-payment-method .ccard .year .select {
	width: 80px;
}
.checkout-payment-method .ccard .captcha .input-text,
.checkout-payment-method .ccard .number .input-text {
	width: 225px;
}
.checkout-payment-method .ccard > .field.cvv > .control {
	display: inline-block;
	padding-right: 20px;
}
.checkout-payment-method .ccard .cvv .label {
	display: block;
}
.checkout-payment-method .ccard .cvv .input-text {
	width: 55px;
}
.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
	width: auto !important;
}
.checkout-agreements-block .checkout-agreements {
	margin-bottom: 20px;
}
.checkout-agreements-block .action-show {
	vertical-align: baseline;
}
.checkout-agreements-items {
	padding-bottom: 30px;
}
.checkout-agreements-items .checkout-agreements-item {
	margin-bottom: 20px;
}
.checkout-agreements-items .checkout-agreements-item-title {
	border-bottom: 0;
}
.checkout-agreement-item-content {
	overflow: auto;
}
.login-container .block-new-customer .actions-toolbar {
	margin-top: 25px;
}
.login-container .block .block-title {
	font-size: 1.8rem;
}
.login-container .fieldset:after {
	margin: 10px 0 0;
	content: attr(data-hasrequired);
	display: block;
	letter-spacing: normal;
	word-spacing: normal;
	color: #e02b27;
	@include font-size(16px);
}
.login-container .fieldset:after {
	margin-top: 35px;
}
.block-addresses-list .items.addresses > .item {
	margin-bottom: 20px;
}
.block-addresses-list .items.addresses > .item:last-child {
	margin-bottom: 0;
}
.block-addresses-list .items.addresses .item.actions {
	margin-top: 5px;
}
.form-address-edit .region_id {
	display: none;
}
.form-edit-account .fieldset.password {
	display: none;
}
.form-create-account .fieldset-fullname .fields .field {
	float: none;
}
.form-create-account .fieldset.create.account:after {
	margin: 10px 0 0;
	content: attr(data-hasrequired);
	display: block;
	letter-spacing: normal;
	word-spacing: normal;
	color: #e02b27;
	@include font-size(16px);
}
.form-create-account .fieldset.create.account:after {
	margin-top: 35px;
}
.form.password.forget .fieldset:after {
	margin: 10px 0 0;
	content: attr(data-hasrequired);
	display: block;
	letter-spacing: normal;
	word-spacing: normal;
	color: #e02b27;
	@include font-size(16px);
}
.form.password.forget .fieldset:after {
	margin-top: 35px;
}

.checkout-index-index .modal-popup .form-shipping-address {
	max-width: 500px;
}
.checkout-index-index .modal-popup .modal-footer .action-save-address {
	float: right;
	margin: 0 0 0 20px;
}
.checkout-shipping-method .actions-toolbar > .primary {
	float: right;
}
.checkout-shipping-method .actions-toolbar .action.primary {
	margin: 0;
}
.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
	max-width: 500px;
}
.opc-wrapper .form-login {
	border-bottom: 1px solid #cccccc;
	padding-bottom: 20px;
}
.table-checkout-shipping-method {
	width: auto;
}
.opc-sidebar {
	margin: 46px 0 20px;
	width: 33.33333333%;
	float: right;
	-ms-flex-order: 2;
	-webkit-order: 2;
	order: 2;
}
.opc-summary-wrapper .modal-header .action-close {
	display: none;
}
.authentication-dropdown {
	border: 1px solid #aeaeae;
	-webkit-transform: scale(1, 0);
	-webkit-transform-origin: 0 0;
	-webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0.1s;
	text-align: left;
	transform: scale(1, 0);
	transform-origin: 0 0;
	transition: transform linear 0.1s, visibility 0s linear 0.1s;
	visibility: hidden;
	width: 100%;
    overflow: hidden;
}
.authentication-dropdown._show {
	z-index: 100;
	-webkit-transform: scale(1, 1);
	-webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0s;
	transform: scale(1, 1);
	transition: transform linear 0.1s, visibility 0s linear 0s;
	visibility: visible;
}
.authentication-wrapper {
	width: 33.33333333%;
	text-align: right;
}
.block-authentication .block-title {
	font-size: 2.6rem;
	border-bottom: 0;
	margin-bottom: 25px;
}
.block-authentication .actions-toolbar > .primary {
	display: inline;
	float: right;
	margin-right: 0;
}
.block-authentication .actions-toolbar > .primary .action {
	margin-right: 0;
}
.block-authentication .actions-toolbar > .secondary {
	float: left;
	margin-right: 2rem;
	padding-top: 1rem;
}
.popup-authentication .modal-inner-wrap {
	min-width: 768px;
	width: 60%;
}
.popup-authentication .block-authentication {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	border-top: 1px solid #c1c1c1;
}
.popup-authentication .block[class],
.popup-authentication .form-login,
.popup-authentication .fieldset,
.popup-authentication .block-content {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-grow: 1;
	flex-grow: 1;
}
.popup-authentication .block[class] {
	box-sizing: border-box;
	float: left;
	padding: 10px 30px 0 0;
	width: 50%;
}
.popup-authentication .block[class] + .block {
	border-left: 1px solid #c1c1c1;
	border-top: 0;
	margin: 0;
	padding: 10px 0 0 40px;
}
.popup-authentication .block[class] + .block:before {
	left: 0;
	top: 50%;
}
.popup-authentication .actions-toolbar {
	margin-bottom: 0;
	margin-top: auto;
}
.checkout-payment-method .actions-toolbar .primary {
	float: right;
	margin: 0;
}
.checkout-payment-method .fieldset > .field-select-billing > .control {
	float: none;
	width: 100%;
}
.checkout-billing-address .action-update {
	float: right;
}
.checkout-billing-address .actions-toolbar .action-cancel {
	margin: 6px 20px 0 0;
}
.checkout-payment-method .payment-option-title {
	padding-left: 22px;
}
.checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
	margin-left: 0;
}
.login-container .block.login .actions-toolbar > .primary {
	margin-bottom: 0;
	margin-right: 30px;
}
.login-container .block.login .actions-toolbar > .secondary {
	float: left;
}
.login-container .fieldset > .field > .control {
	width: 80%;
}
.form-create-account .fieldset-fullname .fields {
	display: table;
	width: 100%;
}
.form-create-account .fieldset-fullname .fields .field {
	display: table-cell;
}
.form-create-account .fieldset-fullname .fields .field + .field {
	padding-left: 10px;
}
.form-create-account .fieldset-fullname .field-name-prefix,
.form-create-account .fieldset-fullname .field-name-suffix {
	width: 50px;
}
.form.password.reset,
.form.send.confirmation,
.form.password.forget,
.form.create.account,
.form.search.advanced,
.form.form-orders-search {
	min-width: 280px;
	width: 50%;

	@include media-query(min, $lap-start) {
		min-width: 600px;
	}
}

.opc-estimated-wrapper {
	background: #f4f4f4;
	border-bottom: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
	margin: -21px -15px 15px;
	padding: 18px 15px;
}
.opc-estimated-wrapper .estimated-block {
	font-size: 18px;
	float: left;
	font-weight: 700;
}
.opc-estimated-wrapper .estimated-block .estimated-label {
	display: block;
	margin: 0 0 5px;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart {
	background-image: none;
	background: none;
	-moz-box-sizing: content-box;
	border: 0;
	box-shadow: none;
	line-height: inherit;
	margin: 0;
	padding: 0;
	text-decoration: none;
	text-shadow: none;
	font-weight: 400;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:focus,
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:active {
	background: none;
	border: none;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:hover {
	background: none;
	border: none;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart.disabled,
.opc-estimated-wrapper .minicart-wrapper button.action.showcart[disabled],
fieldset[disabled] .opc-estimated-wrapper .minicart-wrapper button.action.showcart {
	pointer-events: none;
	opacity: 0.5;
}
.opc-estimated-wrapper .minicart-wrapper button.action.showcart:before {
	color: #333333;
}

.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
	border: 10px solid transparent;
	height: 0;
	width: 0;
	margin-top: -21px;
	right: 10px;
	left: auto;
	top: 0;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:before {
	border-bottom-color: #666666;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
	border-bottom-color: #f4f4f4;
	top: 1px;
}
.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.opc-block-summary > .title,
.checkout-agreements-items .checkout-agreements-item-title {
	padding-bottom: 10px;
	color: #333333;
	font-weight: 300;
	font-size: 1.8rem;
}
.cart-totals,
.opc-block-summary .table-totals {
	border-top: 1px solid #cccccc;
	padding-top: 10px;
}
.cart-totals tbody .mark,
.cart-totals tfoot .mark,
.opc-block-summary .table-totals tbody .mark,
.opc-block-summary .table-totals tfoot .mark {
	border: 0;
	font-weight: 400;
	padding: 6px 0;
}
.cart-totals tbody .amount,
.cart-totals tfoot .amount,
.opc-block-summary .table-totals tbody .amount,
.opc-block-summary .table-totals tfoot .amount {
	border: 0;
	font-weight: 400;
	padding: 6px 0 6px 14px;
	text-align: right;
	white-space: nowrap;
}
.cart-totals .grand th,
.cart-totals .grand td,
.opc-block-summary .table-totals .grand th,
.opc-block-summary .table-totals .grand td {
	padding: 11px 0;
}
.cart-totals .grand strong,
.opc-block-summary .table-totals .grand strong {
	display: inline-block;
	font-weight: 300;
	padding: 3px 0 0;
	font-size: 1.8rem;
	font-family: $sans;
}
.cart-totals .grand .mark,
.opc-block-summary .table-totals .grand .mark {
	font-size: 1.8rem;
	border-top: 1px solid #cccccc;
	padding-right: 10px;
}
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
	font-size: 1.8rem;
	border-top: 1px solid #cccccc;
}
.cart-totals .msrp,
.opc-block-summary .table-totals .msrp {
	margin-bottom: 10px;
}
.cart-totals tbody tr:last-child td,
.opc-block-summary .table-totals tbody tr:last-child td {
	padding-bottom: 19px;
}
.cart-totals .totals-tax-summary .mark,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .mark,
.opc-block-summary .table-totals .totals-tax-summary .amount {
	border-bottom: 1px solid #cccccc;
	border-top: 1px solid #cccccc;
	cursor: pointer;
}
.cart-totals .totals-tax-summary .amount .price,
.opc-block-summary .table-totals .totals-tax-summary .amount .price {
	padding-right: 20px;
	position: relative;
	display: inline-block;
	text-decoration: none;
}
.abs-sidebar-totals .totals-tax-summary .amount .price > span,
.cart-totals .totals-tax-summary .amount .price > span,
.opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.abs-sidebar-totals .totals-tax-summary .amount .price:after,
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 12px;
	line-height: 12px;
	color: inherit;
	content: "\e622";
	font-family: "luma-icons";
	vertical-align: middle;
	display: inline-block;
	font-weight: normal;
	overflow: hidden;
	speak: none;
	text-align: center;
}
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
	position: absolute;
	right: 3px;
	top: 3px;
}
.cart-totals .totals-tax-summary.expanded .mark,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
	border-bottom: 0;
}
.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after,
.cart-totals .totals-tax-summary.expanded .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
	content: "\e621";
}
.cart-totals .totals-tax-details,
.opc-block-summary .table-totals .totals-tax-details {
	border-bottom: 1px solid #cccccc;
	display: none;
}
.cart-totals .totals-tax-details.shown,
.opc-block-summary .table-totals .totals-tax-details.shown {
	display: table-row;
}
.cart-totals .table-wrapper,
.opc-block-summary .table-totals .table-wrapper {
	margin-bottom: 0;
}
.paypal-review-discount .block > .title strong,
.cart-discount .block > .title strong {
	color: #006bb4;
	font-weight: 400;
}
.paypal-review-discount .actions-toolbar,
.cart-discount .actions-toolbar {
	display: table-cell;
	vertical-align: top;
	width: 1%;
}
.paypal-review-discount .actions-toolbar .action.primary,
.paypal-review-discount .actions-toolbar .action.cancel,
.cart-discount .actions-toolbar .action.primary,
.cart-discount .actions-toolbar .action.cancel {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	margin: 0 0 0 -1px;
	white-space: nowrap;
	width: auto;
}
.paypal-review-discount .actions-toolbar .secondary,
.cart-discount .actions-toolbar .secondary {
	bottom: 5px;
	left: 15px;
	position: absolute;
}
.paypal-review-discount .action.check,
.cart-discount .action.check {
	font-weight: 400;
}
.paypal-review-discount .fieldset,
.cart-discount .fieldset {
	display: table;
	width: 100%;
}
.paypal-review-discount .fieldset > .field > .label,
.cart-discount .fieldset > .field > .label {
	display: none;
}
.paypal-review-discount .field,
.cart-discount .field {
	display: table-cell;
}

.payment-group .payment-method  {
    clear: both;
}

.checkout-payment-method .actions-toolbar .primary {
    margin-bottom: 20px;
}
