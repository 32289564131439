div[data-content-type='slider'] {
    display: none;
}

div[data-content-type='slider'].slick-initialized {
    display: block;
    margin-bottom: 35px !important;
}

div[data-content-type='slider'] .slick-list,
div[data-content-type='slider'] .slick-track,
div[data-content-type='slider'] .slick-slide {
    min-height: inherit;
}

div[data-content-type='slider'] .slick-list > div,
div[data-content-type='slider'] .slick-track > div,
div[data-content-type='slider'] .slick-slide > div {
    min-height: inherit;
    overflow: hidden;
    width: 100%;
}

[data-content-type='slide'] {
    box-sizing: border-box;
    min-height: inherit;
    overflow: hidden;
}

[data-content-type='slide'] > [data-element='link'],
[data-content-type='slide'] > [data-element='empty_link'] {
    color: inherit;
    min-height: inherit;
    text-decoration: inherit;
}

[data-content-type='slide'] > [data-element='link']:hover,
[data-content-type='slide'] > [data-element='empty_link']:hover {
    color: inherit;
    text-decoration: inherit;
}

.pagebuilder-slide-wrapper {
    border-radius: inherit;
    box-sizing: border-box;
    min-height: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.pagebuilder-slide-wrapper .pagebuilder-overlay {
    -moz-transition: background-color 500ms ease;
    -o-transition: background-color 500ms ease;
    -webkit-transition: background-color 500ms ease;
    border-radius: inherit;
    box-sizing: border-box;
    padding: 30px;
    transition: background-color 500ms ease;
}

.pagebuilder-slide-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: inherit;
}

.pagebuilder-slide-wrapper
    .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
    max-width: 540px;
}

.pagebuilder-slide-wrapper [data-element='content'] {
    min-height: 50px;
}

.pagebuilder-slide-wrapper .pagebuilder-slide-button {
    -moz-transition: opacity 500ms ease;
    -o-transition: opacity 500ms ease;
    -webkit-transition: opacity 500ms ease;
    margin: 20px 0 0 0;
    max-width: 100%;
    text-align: inherit;
    transition: opacity 500ms ease;
    word-break: break-word;
}

@media only screen and (max-width: 768px) {
    .pagebuilder-slide-wrapper
        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
        max-width: none;
    }
}

.pagebuilder-slide-wrapper .pagebuilder-poster-content {
    width: 100%;
}

[data-appearance='collage-centered']
    .pagebuilder-slide-wrapper
    .pagebuilder-overlay {
    margin-left: auto;
    margin-right: auto;
}

[data-appearance='collage-left']
    .pagebuilder-slide-wrapper
    .pagebuilder-overlay {
    margin-right: auto;
}

[data-appearance='collage-right']
    .pagebuilder-slide-wrapper
    .pagebuilder-overlay {
    margin-left: auto;
}

.slick-slider {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}

.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;
}

.slick-track:before,
.slick-track:after {
    content: '';
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    left: 2rem;
}

.slick-prev:before {
    content: '\f103';
    padding-right: 5px;
}

.slick-next {
    right: 2rem;
}

.slick-next:before {
    content: '\f102';
    padding-left: 5px;
}

.slick-prev,
.slick-next {
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    background: rgba(252, 252, 252, 0.6);
    border: none;
    border-radius: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 60px;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 60px;
    z-index: 101;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    opacity: 0.2;
}

.slick-prev:hover,
.slick-next:hover {
    background: #fcfcfc;
    border: none;
}

.slick-prev:hover.slick-disabled,
.slick-next:hover.slick-disabled {
    opacity: 0.2;
}

.slick-prev:hover:before,
.slick-next:hover:before {
    color: #494949;
}

.slick-prev:before,
.slick-next:before,
.slick-prev:after,
.slick-next:after {
    color: #555;
    font-size: 38px;
    opacity: 1;
}

.slick-prev:active,
.slick-next:active,
.slick-prev:focus,
.slick-next:focus,
.slick-prev:not(.primary),
.slick-next:not(.primary) {
    background: rgba(252, 252, 252, 0.7);
    border: none;
    box-shadow: none;
}

.slick-prev:active:active,
.slick-next:active:active,
.slick-prev:focus:active,
.slick-next:focus:active,
.slick-prev:not(.primary):active,
.slick-next:not(.primary):active {
    box-shadow: none;
}

.slick-dots {
    bottom: -30px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.slick-dots li {
    cursor: pointer;
    display: inline-block;
    height: 14px;
    margin: 0 5px;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: 14px;
}

.slick-dots li button,
.slick-dots li .cart.table-wrapper .actions-toolbar > .action,
.slick-dots li .action-gift {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    display: block;
    height: 14px;
    padding: 0;
    text-indent: -99999px;
    transition: 0.3s;
    width: 14px;
}

.slick-dots li button:hover,
.slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover,
.slick-dots li .action-gift:hover {
    border: 1px solid #b3b3b3;
}

.slick-dots li button:active,
.slick-dots li button:focus,
.slick-dots li button:not(.primary),
.slick-dots li .cart.table-wrapper .actions-toolbar > .action:active,
.slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus,
.slick-dots li .cart.table-wrapper .actions-toolbar > .action:not(.primary),
.slick-dots li .action-gift:active,
.slick-dots li .action-gift:focus,
.slick-dots li .action-gift:not(.primary) {
    box-shadow: none;
}

.slick-dots li.slick-active button,
.slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action,
.slick-dots li.slick-active .action-gift {
    background: #1c1918;
}
