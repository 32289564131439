.block-search {
    @include media-query(max, $lap-start) {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateX(-100%);
        width: 100%;
        padding: $base-spacing;
        transition: transform ease .3s;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        background-color: $white;
        z-index: 1;

        &.active {
            transform: translateX(0);
        }
    }
    @include media-query(min, $lap-start) {
        flex-grow: 1;
        flex-shrink: 1;
    }
}

.minisearch {
    display: flex;
    margin-bottom: 0;

    .field {
        width: 100%;
    }

    .control {
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

    }

    .actions {

    }
}

.search-autocomplete {
    z-index: 1;

}

.smile-elasticsuite-autocomplete-result {
    background-color: $white;
    border: 1px solid $black;
}

.autocomplete-list {
    .product-item {
        box-shadow: initial;
        border: initial;
        border-radius: initial;
        margin-bottom: initial;
        transition: initial;

        @include hover {
            border: initial;
            box-shadow: initial;
            margin-top: initial;
            padding-top: initial;

            .photo.image {
                transform: initial;
            }
            .product-item-info:after {
                background-color: initial;
            }

        }
    }
}


.search-autocomplete {
    position: absolute;
    z-index: 3;
    overflow: hidden;
    margin-top: 0;
}

.search-autocomplete dl {
    margin: 0;
    padding: 0;
    list-style: none none
}

.search-autocomplete dl dt {
    border-top: 1px solid #e5e5e5;
    cursor: default;
    margin: 0;
    padding: 5px 40px 5px 10px;
    position: relative;
    text-align: left;
    white-space: normal
}

.search-autocomplete dl dt:not(:empty) {
    background: #fff;
    border: 1px solid #c2c2c2;
    border-top: 0;
    border-bottom: 0
}

.search-autocomplete dl dt:first-child {
    border-top: none
}

.search-autocomplete dl dt:hover, .search-autocomplete dl dt.selected {
    background: #e8e8e8
}

.search-autocomplete dl dd {
    border-top: 1px solid #e5e5e5;
    cursor: pointer;
    margin: 0;
    padding: 5px 10px;
    position: relative;
    text-align: left;
    white-space: normal
}

.search-autocomplete dl dd:not(:empty) {
    background: #fff;
    border: 1px solid #c2c2c2;
    border-top: 0
}

.search-autocomplete dl dd:first-child {
    border-top: none
}

.search-autocomplete dl dd:hover, .search-autocomplete dl dd.selected {
    background: #e8e8e8
}

.search-autocomplete dl dd .amount {
    color: #999;
    position: absolute;
    right: 7px;
    top: 5px
}

.search-autocomplete dl dd .clear {
    clear: both
}

.smile-es-range-slider [data-role=from-label] {
    display: block;
    float: left;
    padding: 0 0 5px
}

.smile-es-range-slider [data-role=to-label] {
    display: block;
    float: right;
    padding: 0 0 5px
}

.smile-es-range-slider [data-role=message-box] {
    font-size: 12px;
    flex: 1;
    padding: 6px 0 0
}

.smile-es-range-slider [data-role=message-box].empty {
    color: #e02b27;
    font-size: 1.2rem;
    padding: 0
}

.smile-es-range-slider .actions-toolbar {
    display: flex;
    margin: 15px 0
}

.smile-es-range-slider .ui-slider {
    margin: 5px;
    clear: both
}

.smile-es-range-slider .ui-slider-handle {
    background: #ff5501;
    padding: 0;
    margin: -3px 0 0 -7px;
    border-radius: 15px;
    width: 15px;
    height: 15px
}

.filter .filter-content .item {
    margin-left: 10px;
}

.filter .block-subtitle {
    border-bottom: none;
    font-weight: 700
}

.filter .field.search input[type="text"] {
    font-size: 12px
}

.filter .actions {
    text-align: right;
    margin: 10px 0 0
}

.filter .actions div.secondary {
    display: inline-block
}

.filter .actions div.secondary a {
    color: $black;
    text-decoration: underline;
    cursor: pointer
}

.filter .actions div.secondary a:hover {
    background: transparent
}

.filter .actions div.secondary a.show-more span:after {
    content: " +"
}

.filter .actions div.secondary a.show-less span:after {
    content: "-"
}

.filter .filter-options dt.filter-options-title[aria-expanded="true"]:after {
    content: '\e621'
}

.filter .no-results-message p {
    margin: 9px 10px 0;
    background-color: #FFEEEE;
    padding: 5px;
    color: #AA0000
}

.smile-elasticsuite-autocomplete-result .product-image-box {
    float: left;
    padding: 0 5px;
    width: 55px
}

.smile-elasticsuite-autocomplete-result .product-shop {
    float: left;
    margin: 0 10px 0 0;
    padding: 0;
    white-space: normal;
    width: 60%
}

.smile-elasticsuite-autocomplete-result .product-item .price-box {
    margin: 5px 0
}

.smile-elasticsuite-autocomplete-result .category-mini-crumb {
    font-style: italic;
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
    color: #929292
}

.smile-elasticsuite-autocomplete-result .product-attribute-label {
    text-transform: uppercase;
    vertical-align: super;
    font-size: 10px;
    color: #777
}

.smile-elasticsuite-autocomplete-result .price-box span.old-price {
    display: block
}

.filter.block.elasticsuite-filter-recommender {
    margin-bottom: 10px;
    background: #f0f0f0;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 0 #fff, inset 0 -1px 0 0 rgba(204, 204, 204, 0.3);
    border: 1px solid #ccc
}

.filter.block.elasticsuite-filter-recommender .filter-current .item {
    background: #FFFFFF;
    display: inline-block;
    border-radius: 3px;
    padding: 5px 10px;
    border: 1px solid #757575;
    box-shadow: inset 0 1px 0 0 #fff, inset 0 -1px 0 0 rgba(204, 204, 204, 0.3)
}

.filter.block.elasticsuite-filter-recommender .filter-current .item a {
    color: #757575
}

.filter.block.elasticsuite-filter-recommender .filter-current .item.active {
    border: 1px solid $black;
    box-shadow: inset 0 1px 0 0 #fff, inset 0 -1px 0 0 rgba(0, 107, 180, 0.3)
}

.filter.block.elasticsuite-filter-recommender .filter-current .item.active a {
    color: $black
}

.filter.block.elasticsuite-filter-recommender .filter-current .item.active a span {
    font-weight: bold
}

.multi-checkout-fields label {
    display: block;
    margin-bottom: 7px
}

.multi-checkout-fields .field {
    margin: 1.5em 0
}

.multi-checkout-fields .field.checkbox label {
    display: inline-block;
    margin: 0 0 0 12px
}