.livesearch.product-sku {
    font-weight: 400;
}

.livesearch.product-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2;
    max-height: 38px;
}