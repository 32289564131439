.order-links {
    list-style: none;
    margin-bottom: 0;

    .item {
        position: relative;
        top: 1px;

        strong, a {
            display: block;
            padding: $half-spacing;
            border: 1px solid $mercury-dk;
            text-decoration: none;
            background-color: $white;
        }

        strong {
            background-color: $mercury;
        }
    }
}
.orders-history {

    tbody {
        tr {
            @include media-query(min, $desk-start) {
                border-bottom: 1px solid $mercury-dk;
            }

        }
    }

}
.order-details-items {

}

.order-items {

    tbody {

        tr {
            border-bottom: 1px solid $silver-lt;

            &:last-of-type {
                border-bottom: none;
            }
        }

    }

    tfoot {
        background-color: $silver-lt;
        text-align: right;
        .mark {
            @include media-query(min, $lap-start) {
                padding-right: 65px;
                text-align: right;
            }
        }
        .amount {
            @include media-query(min, $lap-start) {
                text-align: right;
            }
        }
    }

}


.order-title {
    margin-left:$base-spacing/2;
}
table#my-orders-table {
    margin-bottom: 0;
}

@media only screen and (max-width: 639px) {
    .table-wrapper.orders-recent .table-order-items.table tbody>tr>td.col {
        padding-left: 0
    }

    .order-details-items thead {
        display: block
    }

    .order-details-items thead tr {
        display: block
    }

    .order-pager-wrapper {
        display: block
    }

    .account .table-order-items tbody tr {
        display: block
    }

    .account .table-order-items .product-item-name {
        display: block;
        margin: 0;
    }

    .account .table-order-items .action.show {
        margin-top: 0
    }

    .order-details-items .order-title strong {
        display: block
    }

    .order-details-items .action {
        margin-top: 10px
    }

    .order-details-items .items-qty {
        display: inline-block;
        vertical-align: top
    }

    .order-details-items .col.price .price-including-tax,.order-details-items .col.subtotal .price-including-tax,.order-details-items .col.price .price-excluding-tax,.order-details-items .col.subtotal .price-excluding-tax {
        display: inline-block
    }

    .order-details-items .data.table .col.options {
        padding: 0 10px 15px
    }

    .order-details-items .data.table .col.options:before {
        display: none
    }

    .order-details-items .options-label+.item-options-container[data-th]:before,.order-details-items .item-options-container+.item-options-container[data-th]:before {
        content: attr(data-th) ':';
        display: block;
        font-weight: 700;
        padding-left: 10px
    }

    .order-details-items .options-label+.item-options-container .col,.order-details-items .item-options-container+.item-options-container .col {
        font-size: 1.2rem;
        padding: 0 10px
    }

    .order-details-items .options-label+.item-options-container .col:first-child,.order-details-items .item-options-container+.item-options-container .col:first-child {
        padding-top: 3px
    }

    .order-details-items .options-label+.item-options-container .col:last-child,.order-details-items .item-options-container+.item-options-container .col:last-child {
        padding-bottom: 20px
    }

    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
        display: none
    }

    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
        display: none
    }

    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
        display: block
    }

    .my-credit-cards .card-type img {
        vertical-align: middle
    }
}

.order-links .item {
    line-height: 40px;
    margin: 0 0 -1px
}

.order-links .item strong {
    background: #fff;
    border: 1px solid #ccc;
    display: block;
    font-weight: 400;
    padding: 0 20px
}

.order-links .item a {
    background: #f6f6f6;
    color: #6d6d6d;
    border: 1px solid #ccc;
    display: block;
    font-weight: 400;
    padding: 0 20px
}

.order-links .item a:hover {
    background: #fff;
    text-decoration: none
}

.order-details-items {
    border: 1px solid #ccc;
    margin-bottom: 40px;
    padding: 10px
}

.order-details-items .order-title {
    padding: 0 0 10px;
}

.order-details-items .order-title>strong {

}

.order-details-items .order-title .action {
    display: inline-block
}

.order-details-items .table-wrapper {
    margin: 0
}

.order-details-items .table-wrapper+.actions-toolbar {
    display: none
}

.order-details-items .table-wrapper:not(:last-child) {
    margin-bottom: 30px
}

.order-details-items .table-wrapper.order-items-shipment {
    overflow: visible
}

.order-details-items .table-order-items>thead>tr>th {
    border-bottom: 1px solid #ccc
}

.order-details-items .table-order-items tbody+tbody {
    border-top: 1px solid #ccc
}

.account .order-details-items .table-order-items tbody tr:nth-child(even) td {
    background: none
}

.order-details-items .table-order-items tbody td {
    padding-bottom: 20px;
    padding-top: 20px;
}

.order-details-items .table-order-items tbody .col.label {
    font-weight: 700;
    padding-bottom: 5px;
    padding-top: 0
}

.order-details-items .table-order-items tbody .col.options {
    padding: 10px 10px 15px
}

.order-details-items .table-order-items tbody>.item-parent td {
    padding-bottom: 5px
}

.order-details-items .table-order-items tbody>.item-parent+tr td {
    padding-top: 5px
}

.order-details-items .table-order-items tbody .item-options-container td {
    padding-bottom: 15px;
    padding-top: 0
}

.order-details-items .table-order-items .product-item-name {
    margin: 0 0 10px
}

.order-details-items .item-options {
    margin: 0
}

.order-details-items .item-options dt {
    margin: 0
}

.order-details-items .item-options dd {
    margin: 0 0 15px
}

.order-details-items .item-options dd:last-child {
    margin-bottom: 0
}

.order-details-items .item-options.links dt {
    display: inline-block
}

.order-details-items .item-options.links dt:after {
    content: ': '
}

.order-details-items .item-options.links dd {
    margin: 0
}

.order-details-items thead .col.qty {
    text-align: center
}

.order-details-items .col.name {
    padding-top: 16px
}

.order-details-items .action.show {
    display: inline-block;
    text-decoration: none;
    color: #333
}

.order-details-items .action.show>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.order-details-items .action.show:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: inherit;
    content: '\e622';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center
}

.order-details-items .action.show.expanded:after {
    content: '\e621'
}

.order-details-items .product-item-name {

}

.order-details-items .items-qty .item {
    white-space: nowrap
}

.order-details-items .items-qty .title:after {
    content: ': '
}

.order-details-items .pages-items .item:first-child,.order-details-items .pages-items .item:last-child {
    margin-right: 0
}

.block-order-details-comments {
    margin: 0 0 40px
}

.block-order-details-comments .comment-date {
    font-weight: 600
}

.block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px
}

.block-order-details-view .block-content .box {
    margin-bottom: 30px
}

.block-order-details-view .box-title {
    font-weight: 600
}

.block-order-details-view .box-content .payment-method .title {
    font-weight: 400
}

.block-order-details-view .box-content .payment-method .content {
    margin: 0
}

.block-order-details-view .box-content .payment-method .content>strong {
    font-weight: 400
}

.block-order-details-view .box-content .payment-method .content>strong:after {
    content: ': '
}

.column.main .order-details-items .block-order-details-gift-message {
    margin: 40px 0 0
}

.block-order-details-gift-message+.actions-toolbar {
    display: none
}

.order-tracking {
    margin: 0;
    padding: 20px 0
}

.order-title+.order-tracking {
    border-bottom: 1px solid #ccc
}

.order-tracking .tracking-title {
    display: inline-block
}

.order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px
}

.order-actions-toolbar {
    margin-bottom: 25px
}

.order-actions-toolbar .action {
    margin-right: 30px
}

.order-actions-toolbar .action.print {
    display: none;
    margin: 0
}

.form-orders-search .field:last-child {
    margin-bottom: 20px
}

.block-reorder .actions-toolbar {
    margin: 17px 0
}

.block-reorder .product-item-name {
    float: left;
    width: calc(80%)
}

.block-reorder .product-item::after {
    clear: both;
    content: '';
    display: table
}

.block-reorder .product-item .field.item {
    float: left;
    width: 20px
}

.paypal-review .table-paypal-review-items .col.price .price-including-tax,.paypal-review .table-paypal-review-items .col.price .price-excluding-tax,.paypal-review .table-paypal-review-items .col.subtotal .price-including-tax,.paypal-review .table-paypal-review-items .col.subtotal .price-excluding-tax,.cart.table-wrapper .col.price .price-including-tax,.cart.table-wrapper .col.price .price-excluding-tax,.cart.table-wrapper .col.subtotal .price-including-tax,.cart.table-wrapper .col.subtotal .price-excluding-tax,.cart.table-wrapper .col.msrp .price-including-tax,.cart.table-wrapper .col.msrp .price-excluding-tax,.order-details-items .col.price .price-including-tax,.order-details-items .col.price .price-excluding-tax,.order-details-items .col.subtotal .price-including-tax,.order-details-items .col.subtotal .price-excluding-tax {
    color: #666;
    display: block;
    line-height: 1;
    white-space: nowrap;
}