.customer-type__select {
    display: flex;
    flex-wrap: wrap;
    input[type=checkbox] {

    }

}

.customer-type__input-wrapper {
    display: flex;

    &:not(:last-of-type) {
        margin-right: $base-spacing;
    }
}