#tab-videos-downloads {
    @include media-query(min, $lap-start) {

    }

    @include media-query(min, $desk-start) {
    }


}

.video-and-download__block {
    margin-top: $base-spacing;
    border: 1px solid $silver-lt;
    border-radius: 2px;

    @include media-query(min, $lap-start) {

    }

    h2 {
        @include font-size(20px);
        font-weight: bold;
        padding: $base-spacing;
        margin-bottom: 0;
        @include media-query(min, $lap-start) {
            @include font-size(23px);
        }
    }
    h3 {
        @include font-size(18px);
        font-weight: bold;
        padding: 0 $base-spacing;

        @include media-query(min, $lap-start) {
            @include font-size(20px);
        }
    }

    p {
        @include font-size(16px);
        padding: 0 $base-spacing;
    }

    img {
        padding: 0 $base-spacing;
        margin-bottom: $base-spacing;
    }

    a {
        text-decoration: none;

        @include hover {
            text-decoration: underline;
        }
    }

    #video-frame {
        display: block;
        position: relative;
        padding-bottom: 50%;
        background-color: #fff;
        width: 100%;
        height: 0;
        margin-bottom: $base-spacing;

        @include media-query(min, $lap-start) {
            margin-left: $base-spacing;
            padding-bottom: 25%;
            width: 30%;
        }
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.video-download__wrapper {
    display: flex;

    .description {
        @include media-query(min, $lap-start) {
            width: 70%;
        }
    }
}


