.faqcat-title {
  @include font-size($h3-font-size);
  color: $black !important;
  text-transform: uppercase;
  margin-top: $base-spacing;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .35s;
  -o-transition-duration: .35s;
  transition-duration: .35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
}


.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.panel > .table-responsive {
  margin-bottom: 0;
  border: 0;
}
.panel-group {
  margin-bottom: 20px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel-heading {
  border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}
.panel-group .panel-footer {
  border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}
.panel-default {
  border-color: #ddd;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}
.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.main-faqs-page-index {
  .md_faq_main_left {

    margin-bottom: $base-spacing;
  }
  .block-title {
    @include font-size($h3-font-size);
    padding: $half-spacing;
    text-transform: uppercase;
    font-weight: bold;
  }

  .category-all-list {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;

  }

  .faq-category-list {
    border: 1px solid $silver-lt;
    padding: $half-spacing;

    &:last-of-type {
      border-top: none;
    }
    @include hover {
      background-color: $silver-lt;
    }

    a {
      text-decoration: none;
      display: block;
      position: relative;
      @include font-size($base-font-size);
      font-weight: bold;


      //&:after {
      //  content: "";
      //  position: absolute;
      //  background-image: url(../img/arrow_right.svg);
      //  background-position: 50%;
      //  background-repeat: no-repeat;
      //  background-size: 40px;
      //  height: 24px;
      //  width: 24px;
      //  right: 0;
      //  top: 50%;
      //  transform: translateY(-50%);
      //}
    }
  }
}
.main-faqs-page-index-view {
  .faq_search {
    margin-bottom: $base-spacing;
  }
}
.faq-index-list-view {
  a {
    text-decoration: none !important;
    @include hover {
      h3 {
        text-decoration: underline;
      }

    }

  }
  h3 {
    color: $black !important;
    text-decoration: none;
    .line {
      border-bottom-color: $black !important;
    }
  }

  .faqcat-desc {
    color: $black;
  }
}
.faq-category-icon {
  color: $accent;
}
.faq-category {
  margin-bottom: $base-spacing;
  padding: 0 $half-spacing;
}