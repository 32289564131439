figure[data-content-type='image'] img {
    margin: 0 auto;
    width: 100%;
}

figure[data-content-type='image'] > [data-element='link'],
figure[data-content-type='image'] > [data-element='link'] img {
    border-radius: inherit;
}

figure[data-content-type='image'] figcaption {
    word-wrap: break-word;
}
