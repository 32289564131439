.site-actions {

}

.site-actions__list {
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.site-actions__item {

    @include media-query (min, $lap-start) {
        display: none;
    }
}

.site-actions__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-decoration: none;

    //button reset
    padding: 0;
    background: none;
    border: none;
    color: $base-color;

    @include hover {
        background: none;
        color: $base-color;
    }

    .svg-icon {
        width: 1.7em;
        height: 1.7em;
        transition: opacity ease .3s;

        @include media-query (min, $lap-start) {
            width: 1.7em;
            height: 1.7em;
            margin-right: $base-spacing;
            position: relative;
            top: 4px;
        }

        &.site-actions__close {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            opacity: 0;
            width: 2.2em;
            height: 2.2em;
        }
    }

    &.active {
        color: $accent;
        .svg-icon {
            opacity: 0;

            &.site-actions__close {
                opacity: 1;
            }
        }
    }

    @include hover {
        text-decoration: none;
    }

    @include media-query (min, $lap-start) {
        display: flex;
        align-items: flex-end;
        @include font-size(18px);
        padding: 0;
    }
}

.site-actions__item--menu {
    order: 9;
}

.site-actions__item--basket {
    @include media-query (min, $lap-start) {
        display: flex;
    }
}
