.store-selector {


    @include media-query(min, $lap-start) {
        padding-left: $base-spacing;
    }
}

.store-selector__store-switcher {
    list-style-type: none;
    cursor: pointer;
    margin: 0;
}

.store-selector__option {
    width: 24px;

    @include media-query(min, $lap-start) {
        width: 28px;
    }
}

.store-selector__flag {

}

.store-selector__name {
    display: none;
}

.store-selector__store-dropdown {
    list-style-type: none;
    margin: 0;
    display: none;
    position: absolute;
    top: $base-spacing * 1.25;
    right: 0;

    @include media-query(min, $lap-start) {
        top: $base-spacing * 2.25;
        right: 30px;
    }

    &.active {
        display: block;
        background-color: $black;
        z-index: 4;
        min-width: 110px;

        @include media-query(min, $lap-start) {
            min-width: 320px;
        }
    }
}

.store-dropdown__switch-option {

}

.store-dropdown__switch {
    display: flex;
    align-items: center;
    padding: $half-spacing;
    color: $white;
    text-decoration: none;
    @include font-size(10px);
    font-weight: normal;

    @include media-query(min, $lap-start) {
        @include font-size(14px);
    }

    @include hover {
        text-decoration: none;
        background-color: $charcoal;
        color: $white;
    }
}

.store-dropdown__flag {
    width: 24px;
    margin-right: $half-spacing;
}

.store-dropdown__switch-option-wrapper {

}

.store-dropdown__name {
    display: none;

    @include media-query(min, $lap-start) {
        display: inline-block;
        margin-right: $half-spacing;
    }
}

.store-dropdown__symbol {

}

.store-dropdown_currency {

}