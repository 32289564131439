[data-content-type='buttons'] {
    max-width: 100%;
    @include button;
    padding: ($half-spacing + 3) $base-and-half-spacing ($half-spacing + 3) !important;
    margin-bottom: $base-spacing !important;

    &.btn-left {
        margin-right: auto !important;
    }
    &.btn-centre {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    &.btn-right {
        margin-left: auto !important;
    }
}

[data-content-type='button-item'] {
    max-width: 100%;
}

[data-content-type='button-item'] [data-element='link'],
[data-content-type='button-item'] [data-element='empty_link'] {
    max-width: 100%;
    word-wrap: break-word;
    cursor: default;
}
