div[data-content-type='tabs'] {
    ul.tabs-navigation {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;

        @include media-query(min, $lap-start) {

        }
    }

    li.tab-header {
        background: $silver-lt;
        max-width: 100%;
        overflow-wrap: break-word;
        position: relative;

        &.ui-state-active {
            a.tab-title {
                color: $white;
                text-decoration: none;
                background: $primary;
            }
        }

        @include media-query(min, $lap-start) {
            margin-right: $base-spacing;
        }
    }

    a.tab-title {
        color: $grey;
        text-decoration: none;
        display: block;
        padding: $base-spacing;
        line-height: 1;
        @include uppercase(2px);
        font-weight: 400;
        background-color: $white;
        font-family: $hn-font-family;
        @include font-size($base-font-size);
        outline: 0;

        @include media-query(min, $lap-start) {
            padding-left: spacing(2);
            padding-right: spacing(2);
        }

        @include media-query(min, $desk-start) {
            @include font-size($h3-font-size);
        }

        @include hover {
            text-decoration: none;
        }
    }

    .tabs-content {
        position: relative;
        z-index: 1;
        border: 1px solid transparent !important;
        border-top: none !important;

        .active + & {
            border-color: $primary !important;
        }

        @include media-query(min, $lap-start) {
            width: 100%;
            border: none !important;
            border-top: 4px solid $primary !important;
            padding: $base-spacing 0;
        }
    }
}

div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item'] {
    box-sizing: border-box;
    min-height: inherit;
}

div[data-content-type='tabs']
    .tabs-content
    [data-content-type='tab-item']:not(:first-child) {
    display: none;
}

div[data-content-type='tabs'].tab-align-left .tabs-content {
    border-top-left-radius: 0 !important;
}

div[data-content-type='tabs'].tab-align-right .tabs-content {
    border-top-right-radius: 0 !important;
}

@media only screen and (max-width: 768px) {
    [data-content-type='tab-item'] {
        background-attachment: scroll !important;
    }
}
