.columns {
  @include container;
}

.page-layout-1column {

}

.category-products.page-layout-2columns-left {

  .column.main {
    width: 100%;
  }

}

.catalog-category-view.page-layout-2columns-left {
  .column.main {
    width: 100%;
  }
}

.page-with-filter.catalog-category-view.page-layout-2columns-left {
  .column.main {
    @include media-query(min, $lap-start) {
      float: right;
      width: calc(100% - (220px + #{$base-spacing}));
    }

    @include media-query (min, $desk-start) {
      width: calc(100% - (290px + #{$double-spacing}));
    }
  }

}

.page-layout-2columns-left {
  .columns {
    @include media-query (max, $lap-start) {
      display: flex;
      flex-direction: column;
    }

    @include media-query(min, $lap-start) {
      @include clearfix;
    }
  }

  .column.main {
    @include media-query(min, $lap-start) {
      float: right;
      width: calc(100% - (220px + #{$base-spacing}));
    }

    @include media-query (min, $desk-start) {
      width: calc(100% - (290px + #{$double-spacing}));
    }
  }



  .sidebar-main {
    margin-bottom: $base-spacing;

    @include media-query (max, $lap-start) {
      order: -1;
    }

    @include media-query(min, $lap-start) {
      margin-bottom: $base-and-half-spacing;
      width: 220px;
      float: left;
    }

    @include media-query(min, $desk-start) {
      width: 290px;
    }
  }

  .sidebar-additional {
    margin-bottom: 0;

    @include media-query(min, $lap-start) {
      width: 220px;
      float: left;
      clear: left;
    }

    @include media-query(min, $desk-start) {
      width: 290px;
    }
  }

}

