.dealer-view-page__title {
  text-align: center;
  width: 100%;
  display: block;
  @include font-size(33px);
  @include media-query(min, $lap-start) {
    @include font-size($h1-font-size);
    text-align: left;
  }
}

.dealer-view-page__sub-title {
  width: 100%;
  display: block;
  text-align: center;
  @include font-size(15px);
  @include media-query(min, $lap-start) {
    @include font-size($h4-font-size);
    text-align: left;
  }
}

.dealer-view-page__map {
  width: 100%;
  display: block;
  margin-bottom: $double-spacing;

  .dealer-detail-map {
    height: 400px;
    width: 100%;
  }
}

.dealer-view-page__block {
  margin-bottom: $double-spacing;
}

.dealer-view-page__header {
  @include font-size(15px);
  padding-top: 0;

  &:not(:first-of-type) {
    margin-top: $double-spacing;
  }

  @include media-query(min, $lap-start) {
    @include font-size($h4-font-size);
  }
}

.dealer-view-page__sub-header {
  @include font-size(13px);
  font-weight: normal;
  @include media-query(min, $lap-start) {
    @include font-size($base-font-size);
  }

  p {
    @include font-size($small-font-size);
    font-weight: normal;
    @include media-query(min, $lap-start) {
      @include font-size($table-font-size);
    }
  }
}

.dealer-view-page__img {
  max-width: 200px;
  display: inline-block;
  margin-right: $base-spacing;
}

.dealer-view-page__sub-header--details {
  @include font-size(13px);
  font-weight: normal;
  @include media-query(min, $lap-start) {
    @include font-size($base-font-size);
  }

  p {
    @include font-size($small-font-size);
    font-weight: normal;
    @include media-query(min, $lap-start) {
      @include font-size($table-font-size);
    }
  }
}

.dealer-page__wrapper {
  @include media-query(min, $lap-start) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }
}

.dealer-view-page__block {
  @include media-query(min, $lap-start) {
    width: calc(33% - #{$base-spacing});
  }

  .search-location {
    margin-bottom: $half-spacing;
    max-width: 35em;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    button {
      max-height: 48px;
      white-space: nowrap;
    }
  }

  .search-location__btn {
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      background-color: $accent;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border-color: #333333;
      transform: scale(0.5);
    }

    @include hover {
      &:before {
        background-color: $white;
        border: $accent 10px solid;
      }
    }
  }

  .button--no-style {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      @include font-size($small-font-size);

      @include media-query(min, $lap-start) {
        @include font-size($base-font-size);
      }
    }

    @include hover {
      color: $accent;
      background: transparent;
      .search-location__btn:before {
        background-color: $white;
        border: $accent 10px solid;
      }
    }
  }
}

.dealer-view-page__payment-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -$base-spacing;
}

.payment-img {
  width: 38px;
  margin-right: $half-spacing;
  margin-bottom: $base-spacing;

  &:last-of-type {
    margin-right: 0;
  }
}

.dealer-view-page__status-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.status-img {
  width: 38px;
  margin-right: $half-spacing;

  &:last-of-type {
    margin-right: 0;
  }
}

.dealer__name {
  color: $accent;
  @include font-size(16px);
  font-weight: bold;
  padding-top: 0;

}

.dealer__telephone {
  @include font-size(16px);
  font-weight: bold;
}

.dealer__link {
  display: block;
  margin-bottom: $half-spacing;
}

.dealer__link-img {
  max-width: 62px !important;
}

.dealer__address {
  @include font-size(16px);
  text-align: center;
}

.dealer-status__icon {
  display: flex;
  flex-wrap: wrap;
}

.dealer-status__img {
  width: 24px;
}