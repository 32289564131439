.product-tabs-section {
  clear: both;
  position: relative;
  margin-bottom: $base-spacing;

  @include media-query(min, $lap-start) {
    display: block;
    margin-bottom: 0;


    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $silver-lt;
      top: 71px;
    }
  }
}

.product-tabs {
  @include media-query(min, $lap-start) {
    display: flex;
    flex-wrap: wrap;
  }

  .title {
    position: relative;
    padding: $half-spacing 0;
    font-weight: bold;

    &:after {
      content: '';
      position: absolute;
      background-image: url("../img/expand_more_blk.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px;
      height: 24px;
      width: 24px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @include media-query(min, $lap-start) {
        display: none;
      }
    }

    &.active {
      @include media-query(min, $lap-start) {
        background-color: $white;
        border-bottom: 1px solid $white;
      }
    }

    &.active:after {
      content: '';
      position: absolute;
      background-image: url("../img/expand_less_blk.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px;
      height: 24px;
      width: 24px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @include media-query(min, $lap-start) {
        display: none;
      }
    }

    @include media-query(min, $lap-start) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      @include font-size(16px);
      text-transform: uppercase;
      border: 1px solid $silver-lt;
      border-radius: 2px;
      text-align: center;
      background-color: $silver-lt;
      min-height: 4.5rem;

      width: calc(185px - (#{$base-spacing * 6} / 7));
      margin-left: $base-spacing;

      &:nth-child(7n + 1) {
        margin-left: 0;
      }

    }

    @include media-query(min, $wide-start) {
      @include font-size(18px);
      padding: 5px 0;
    }
  }

  .switch {
    text-decoration: none;
    display: block;
  }

  .content {

    @include media-query(min, $lap-start) {
      display: block;
      width: 100%;
      order: 9999;
      margin-bottom: $double-spacing;
    }
  }

  #tab-spec {

    dl {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      width: 100%; /* set the container width*/
      overflow: visible;
      @include font-size(14px);
      font-weight: 400;
      margin: $base-spacing 0;
      color: #5e6f75;

      @include media-query(min, $lap-start) {
        margin: 0;
      }

      dd:nth-of-type(even) {
        background-color: $white;
      }

      dt:nth-of-type(even) {
        background-color: $white;
      }

    }

    dl + p {
      @include media-query(min, $lap-start) {
        margin-bottom: 0;
        width: 100%;
        display: block;
        margin-top: $base-spacing;
      }
    }

    dt {
      flex: 0 0 50%;
      text-overflow: ellipsis;
      overflow: hidden;
      background-color: $silver-lt;
      margin-bottom: 0;
      font-weight: 400;
      @include font-size(14px);
      padding: $base-spacing;
      color: #5e6f75;
    }

    dd {
      flex: 0 0 50%;
      margin-left: auto;
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      background-color: $silver-lt;
      padding: $base-spacing;
    }
  }
}

.product-spec__details {
  @include font-size(16px);
  font-weight: bold;
}

.product-tabs__wrapper--desktop {

}

.product-tab-spec__container {
  margin: $base-spacing 0;
  @include media-query(min, $lap-start) {
    display: flex;
    margin: $double-spacing 0;
    flex-wrap: wrap;
  }
}

.product-tab-spec__img-link {
  @include media-query(min, $lap-start) {
    margin-right: $double-spacing;
  }
}

.product-tab-spec__img {
  min-width: 300px;
}

