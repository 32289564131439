.login-container {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        margin-bottom: $double-spacing;
    }
}
.company-account-create,
.customer-account-create,
.customer-account-createpassword,
.customer-account-forgotpassword {
    .page-main {
        margin: 0 auto;
        max-width: 34em;
    }
}

.customer-account-login {
    .login-container {
        @include media-query(min, $lap-start) {
            display: flex;
            justify-content: space-between;
        }

        .block-title {
            margin-bottom: $half-spacing;
            @include font-size($h3-font-size);
        }

    }

    .block-customer-login {
        margin-bottom: $base-spacing;

        @include media-query(min, $lap-start) {
            margin-bottom: 0;
            width: calc(50% - #{$double-spacing});
        }

        @include media-query(min, $desk-start) {
            width: calc(50% - #{$double-spacing * 4});
        }

        .primary {
            margin-bottom: $half-spacing;
            @include media-query(min, $lap-start) {
                margin-bottom: 0;
            }
        }

        form {
            @include media-query(min, $lap-start) {
                margin-bottom: 0;
            }
        }

        .fieldset:last-child {
            @include media-query(min, $lap-start) {
                margin-bottom: 0;
            }
        }
    }

    .block-new-customer {
        padding-top: $base-spacing;
        border-top: 1px solid $mercury-dk;

        @include media-query(min, $lap-start) {
            padding-top: 0;
            border-top: 0;
            width: calc(50% - #{$double-spacing});
            display: flex;
            flex-direction: column;

            @include media-query(min, $desk-start) {
                width: calc(50% - #{$double-spacing * 4});
            }

        }

        .block-content {
            @include media-query(min, $lap-start) {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            p {
                @include media-query(min, $lap-start) {
                    margin-bottom: 0;
                }
            }
        }

        .form-login {
            @include media-query(min, $lap-start) {
                margin-bottom: 0;
            }
        }

        .login {
            @include media-query(min, $lap-start) {
                margin-bottom: 0;
            }
        }

        .actions-toolbar {

            @include media-query(min, $lap-start) {
                align-self: flex-end;
                margin-top: auto;
                position: relative;
                top: -61px;

                .login-container .fieldset:after {
                    @include font-size(16px);
                }
            }

            .action.create {
                @include button;
            }
        }

    }

    .block-new-company {
        padding-top: $base-spacing;
        border-top: 1px solid $mercury-dk;

        @include media-query(min, $lap-start) {
            padding-top: 0;
            border-top: 0;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-column: 2 / span 1;
            grid-row: 2 / span 1;
        }
    }
}
.newsletter {
    .control {
        display: flex;
    }
}
.newsletter-radio__wrapper {
    display: flex;
    margin-right: $base-spacing;
    &:last-child {
        margin-right: 0;
    }
}
.form.send.confirmation {
    .primary {
        margin-bottom: $base-spacing;
    }
    .field.email.required {
        margin-bottom: $base-spacing;
    }
}


