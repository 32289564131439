.category-landing-tile {
}

.category-landing-tile--desktop {
	position: relative;
	margin-bottom: $base-and-half-spacing;

	@include media-query(min, $lap-start) {
		margin-bottom: 0;
	}
}

.category-landing-tile--img {
	display: block;
	position: relative;
	height: 0;

	@include media-query(min, $lap-start) {
		margin-top: -$base-spacing;
		height: 100%;
	}

	picture {
		display: block;
		background-color: $silver-lt;
		padding-bottom: 86.67%;

		@include media-query(min, $lap-start) {
			padding-bottom: 20%;
		}
	}

	img {
		display: block;
		position: absolute;
		min-width: 100%;
		min-height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
	}
}

.category-landing-tile--img-no-desktop {
	@include media-query(min, $lap-start) {
		display: none;
	}
}

.category-landing-tile--img-no-desktop + .category-landing-content__container {
	position: relative;

	.category-landing-tile__content {
		margin-top: 0;
		padding: 0;
	}
}

.category-landing-content__container {
	position: relative;
	top: initial;
	left: initial;
	right: initial;
	bottom: initial;
	background: none;
	background-color: rgba($white, 0.5);
}

.category-landing-tile__content {
	position: relative;
	padding-top: $base-and-half-spacing;

	@include media-query(min, $lap-start) {
		padding: $double-spacing;
		margin-top: -100px;
		background-color: $white;
	}

	@include media-query(min, $desk-start) {
		padding: ($base-spacing * 1.5);
		margin-top: -100px;
	}

	p {
		@include font-size(13px);
		text-align: center;

		&:last-of-type {
			margin-bottom: 0;
		}

		@include media-query(min, $desk-start) {
			@include font-size(20px);
		}
	}
}

.category-landing-tile__title {
	@include font-size(33px);
	text-transform: uppercase;
	text-align: center;
	padding-top: 0;

	@include media-query(min, $desk-start) {
		@include font-size(52px);
	}
}

.category-landing-tiles__item {
	p {
	}
}

.category-landing-tiles__link {
}

.category-tiles-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@include media-query(min, $lap-start) {
		justify-content: start;
	}
}

.category-tiles__header {
	@include font-size(24px);
	margin-bottom: $base-and-half-spacing;
	text-transform: uppercase;
	text-align: center;
	padding-top: 0;

	@include media-query(min, $lap-start) {
		margin-bottom: $double-spacing;
	}
}

.category-tiles__item {
	width: calc(50% - #{$base-spacing});
	margin-left: $double-spacing;
	margin-bottom: $half-spacing;

	@include media-query-bracket(0, $lap-start) {
		&:nth-child(odd) {
			margin-left: 0;
		}
	}

	@include media-query-bracket($lap-start, $desk-start) {
		width: calc(33.3333% - (#{$base-spacing * 2} / 3));
		margin-left: $base-spacing;

		&:nth-child(3n + 1) {
			margin-left: 0;
		}
	}

	@include media-query(min, $lap-start) {
		margin-bottom: $double-spacing;
	}

	@include media-query(min, $desk-start) {
		width: calc(25% - (#{$base-spacing * 3} / 4));
		margin-left: $base-spacing;

		&:nth-child(4n + 1) {
			margin-left: 0;
		}
	}

	p {
		@include font-size(13px);
		text-align: center;

		@include media-query(min, $lap-start) {
			@include font-size(16px);
		}
	}
}

.category-tiles__wrapper {
	transform: translateY(0);
	transition: all 0.3s ease-in-out;

	@include media-query(min, $lap-start) {
		position: relative;

		@include hover {
			.category-tiles__image-link {
				transform: translateY(-20px);
			}

			.category-tiles__link {
				background-color: $accent;
				color: $white;
				height: 100px;
				transform-origin: bottom;
				padding: 42px 0;
			}
		}
	}
}

.category-tiles__image-link {
	display: block;
	background-color: $silver-lt;
	padding-bottom: 100%;
	margin-bottom: $base-spacing * 0.75;
	position: relative;
	@include media-query(min, $lap-start) {
		transition: all 0.2s ease-in-out;
		margin-bottom: $base-spacing;
	}
}

.category-tiles__image {
	width: 100%;
	border-radius: 2px 2px 0 0;
	display: block;
	position: absolute;
	min-width: 100%;
	min-height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
}

.category-tiles__link {
	text-decoration: none;
	@include font-size(15px);
	line-height: 1.2em;
	font-weight: bold;
	display: block;
	text-align: center;
	margin-bottom: $base-spacing;
	cursor: pointer;

	@include hover {
		color: $accent;
		text-decoration: none;
	}

	@include media-query(min, $lap-start) {
		@include font-size(21px);
		position: absolute;
		width: 100%;
		display: block;
		height: 90px;
		line-height: 1.2em;
		margin-bottom: 0;
		padding: 32px 0;
		bottom: 0;
		color: $white;
		background-color: rgba($black, 0.5);
		border-radius: 0 0 2px 2px;
		transition: all 0.2s ease-in-out;
	}
}
