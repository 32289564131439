.account .table-wrapper {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  overflow-x: auto
}

.account .table-wrapper:last-child {
  margin-bottom: 0
}

.account .data.table .col.actions .action.delete,[class^='sales-guest-'] .data.table .col.actions .action.delete,.sales-guest-view .data.table .col.actions .action.delete {
  color: #d10029
}



.order-products-toolbar,.customer-addresses-toolbar {
  position: relative
}

.order-products-toolbar .toolbar-amount,.customer-addresses-toolbar .toolbar-amount {
  position: relative;
  text-align: center
}

.order-products-toolbar .pages,.customer-addresses-toolbar .pages {
  position: relative
}

.additional-addresses table>thead>tr>th {
  white-space: nowrap
}
.pages>.label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.pages .items {
  font-size: 0;
  letter-spacing: -1px;
  line-height: 0;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style: none none;
  display: inline-block;
  font-weight: 400
}

.pages .item {
  font-size: 1.2rem;
  line-height: 3rem;
  letter-spacing: normal;
  margin: 0 8px 0 0;
  display: inline-block
}

.pages .item .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.pages a.page {
  color: #006bb4;
  display: inline-block;
  padding: 0 5px;
  text-decoration: none
}

.pages a.page:visited {
  color: #006bb4
}

.pages a.page:hover {
  color: #006bb4;
  text-decoration: none
}

.pages a.page:active {
  color: #ff5501
}

.pages strong.page {
  background: #e5e5e5;
  font-size: 1.2rem;
  line-height: 3rem;
  letter-spacing: normal;
  color: #333;
  display: inline-block;
  font-weight: 700;
  padding: 0 5px
}

.pages .action {
  background: #f0f0f0;
  border: 1px solid #ccc;
  color: #7d7d7d;
  display: inline-block;
  padding: 0;
  text-decoration: none
}

.pages .action:visited {
  color: #7d7d7d
}

.pages .action:hover {
  background: #dedede;
  color: #7d7d7d;
  text-decoration: none
}

.pages .action:active {
  color: #7d7d7d
}

.pages .action.next {
  display: inline-block;
  text-decoration: none
}

.pages .action.next:visited:before {
  color: #7d7d7d
}

.pages .action.next:active:before {
  color: #7d7d7d
}

.pages .action.next>span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.pages .action.next:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: inherit;
  color: #7d7d7d;
  content: '\e608';
  font-family: 'luma-icons';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center
}

.pages .action.next:hover:before {
  color: #7d7d7d
}

.pages .action.next:active:before {
  color: #7d7d7d
}

.pages .action.previous {
  display: inline-block;
  text-decoration: none
}

.pages .action.previous:visited:before {
  color: #7d7d7d
}

.pages .action.previous:active:before {
  color: #7d7d7d
}

.pages .action.previous>span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.pages .action.previous:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: inherit;
  color: #7d7d7d;
  content: '\e617';
  font-family: 'luma-icons';
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center
}

.pages .action.previous:hover:before {
  color: #7d7d7d
}

.pages .action.previous:active:before {
  color: #7d7d7d
}

.pages .action {
  box-shadow: inset 0 1px 0 0 #fff,inset 0 -1px 0 0 rgba(204,204,204,0.3);
  border-radius: 3px;
  padding: 0 8px
}

.pages .action.previous {
  margin-right: 8px
}

.pages .action.next {
  margin-left: 8px
}

.toolbar {
  margin-bottom: 30px;
  text-align: center
}

.toolbar select {
  background-color: #f0f0f0;
  box-shadow: inset 0 1px 0 0 #fff,inset 0 -1px 0 0 rgba(204,204,204,0.3);
  border-radius: 3px
}

.toolbar .sorter-action {
  position: relative;
  top: -2px
}

.toolbar-amount {
  left: 0;
  line-height: 18px;
  margin: 0;
  padding: 7px 0;
  text-align: left;
  top: 0;
  vertical-align: middle
}

.products.wrapper~.toolbar .toolbar-amount {
  display: none
}

.page-with-filter .toolbar-amount {
  position: static
}

.toolbar-products {
  background-color: transparent
}

.toolbar-products .pages {
  display: none
}

.products.wrapper~.toolbar-products .pages {
  display: block
}

.toolbar .pages {
  margin-bottom: 25px
}

.sorter {
  float: right
}

.page-products .sorter {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.products.wrapper~.toolbar .sorter {
  display: none
}

.sorter-options {
  margin: 0 5px 0 7px;
  width: auto
}

.sorter-action {
  display: inline-block;
  text-decoration: none
}

.sorter-action:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: inherit;
  color: #757575;
  content: '\e613';
  font-family: 'luma-icons';
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center
}

.sorter-action:hover:before {
  color: #333
}

.sorter-action>span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.sorter-action.sort-desc:before {
  content: '\e614'
}

@media only screen and (max-width: 639px) {

  .table-wrapper {
    border-top: 1px solid #ccc;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    position: relative
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) {
    border: none;
    display: block
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>thead>tr>th {
    display: none
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody {
    display: block
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr {
    display: block;
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr td,.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr th {
    border-bottom: none;
    display: block;
    padding: 5px 10px
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr td[data-th]:before,.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr th[data-th]:before {
    padding-right: 10px;
    content: attr(data-th) ': ';
    display: inline-block;
    color: #111;
    font-weight: 700
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr>th {
    background-color: #f6f6f6
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody>tr>td:first-child {
    padding-top: 15px
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody>tr>td:last-child {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody>tr>td.col.qty {
    text-align: left
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison).additional-attributes tbody td:last-child {
    border: none;
    padding: 0 0 5px
  }

  .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
    display: block
  }

  .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
    display: block
  }

  .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,.table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
    padding-top: 20px
  }

  .table-wrapper .table:not(.totals):not(.table-comparison) tfoot .mark {
    box-sizing: border-box;
    float: left;
    text-align: left;
    width: 70%
  }

  .table-wrapper .table:not(.totals):not(.table-comparison) tfoot .amount {
    box-sizing: border-box;
    float: left;
    text-align: right;
    width: 30%
  }

  .table-wrapper .table:not(.totals):not(.table-comparison) tfoot .grand.totals {
    font-size: 1.6rem
  }

  .table-wrapper .table:not(.totals):not(.table-comparison) tfoot .grand.totals .amount {
    padding-bottom: 10px
  }

  .data-table-definition-list thead {
    display: none
  }

  .data-table-definition-list tbody th {
    padding-bottom: 0
  }

  .data-table-definition-list tbody th,.data-table-definition-list tbody td {
    display: block;
    padding-left: 0;
    padding-right: 0
  }

  .pages .pages-items {
    display: block;
    padding: 2px 40px 0;
    position: relative;
    white-space: normal;
    z-index: 1
  }

  .pages-item-previous {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1
  }

  .pages-item-next {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
  }
}


@media only screen and  (min-width: 768px) {
  .account .data.table {
    margin-bottom: 0
  }

  .account .data.table .col.actions {
    white-space: nowrap
  }

}

table#my-orders-table {
  margin-bottom: 0;
}

@media only screen and (max-width: 639px) {
  .table-wrapper.orders-recent .table-order-items.table tbody>tr>td.col {
    padding-left: 0
  }

  .order-details-items thead {
    display: block
  }

  .order-details-items thead tr {
    display: block
  }

  .order-pager-wrapper {
    display: block
  }

  .account .table-order-items tbody tr {
    display: block
  }

  .account .table-order-items .product-item-name {
    display: inline-block;
    margin: 0
  }

  .account .table-order-items .action.show {
    margin-top: 0
  }

  .order-details-items .order-title strong {
    display: block
  }

  .order-details-items .action {
    margin-top: 10px
  }

  .order-details-items .items-qty {
    display: inline-block;
    vertical-align: top
  }

  .order-details-items .col.price .price-including-tax,.order-details-items .col.subtotal .price-including-tax,.order-details-items .col.price .price-excluding-tax,.order-details-items .col.subtotal .price-excluding-tax {
    display: inline-block
  }

  .order-details-items .data.table .col.options {
    padding: 0 10px 15px
  }

  .order-details-items .data.table .col.options:before {
    display: none
  }

  .order-details-items .options-label+.item-options-container[data-th]:before,.order-details-items .item-options-container+.item-options-container[data-th]:before {
    content: attr(data-th) ':';
    display: block;
    font-weight: 700;
    padding-left: 10px
  }

  .order-details-items .options-label+.item-options-container .col,.order-details-items .item-options-container+.item-options-container .col {
    font-size: 1.2rem;
    padding: 0 10px
  }

  .order-details-items .options-label+.item-options-container .col:first-child,.order-details-items .item-options-container+.item-options-container .col:first-child {
    padding-top: 3px
  }

  .order-details-items .options-label+.item-options-container .col:last-child,.order-details-items .item-options-container+.item-options-container .col:last-child {
    padding-bottom: 20px
  }

  .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
    display: none
  }

  .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
    display: none
  }

  .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
    display: block
  }

  .my-credit-cards .card-type img {
    vertical-align: middle
  }
}

select#limiter {
  width: auto;
  display: inline-block;
}