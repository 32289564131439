.account {

  .page-title-wrapper {
    .page-title {
      @include font-size(30px);

      @include media-query(min, $lap-start) {
        @include font-size($h2-font-size);
        display: inline-block;
        margin-right: $base-spacing;
      }
    }
  }


  .actions-toolbar {
    padding-top: $base-spacing;
    @include media-query(min, $lap-start) {
      margin-bottom: $double-spacing;
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }


  .block-order-details-view {
    .block-title {
      @include font-size(30px);

      @include media-query(min, $lap-start) {
        @include font-size($h2-font-size);
        border-bottom: none;
      }
    }

    .block-content {
      @include media-query(min, $lap-start) {
        display: flex;

        .box {
          width: calc(25% - #{$base-spacing * 3} / 4);
          margin-left: $double-spacing *2;

          &:nth-child(4n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .field.choice {
    display: flex;
  }
}

.sales-order-view {
  #my-orders-table > tbody {

    @include hover {

    }
  }
}

