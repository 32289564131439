div[data-content-type='video'] .pagebuilder-video-inner {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
}

div[data-content-type='video'] .pagebuilder-video-container {
    border-radius: inherit;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
}

div[data-content-type='video'] iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
