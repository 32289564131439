.site-nav {
	background-color: $black;
	position: relative;
	width: 100%;

	@include media-query(max, $lap-start) {
		position: absolute;
		top: 100%;
		left: 0;
		transform: translateX(-100%);
		width: 100%;
		height: 50vh;
		overflow-x: hidden;
		z-index: 2;
		transition: transform ease 0.3s;

		&.active {
			transform: translateX(0);
		}

		&.overflow {
			overflow-y: hidden;
		}
	}

	@include media-query(min, $lap-start) {
		margin-bottom: $base-spacing;
	}
}

.site-nav__list {
	list-style: none;
	margin: 0;

	@include media-query(max, $lap-start) {
		height: 50vh;
		overflow-x: hidden;
	}

	@include media-query(min, $lap-start) {
		@include container;
		display: flex;
		justify-content: space-between;
	}

	> li {
		border-bottom: 1px solid $charcoal;

		&.parent {
			> a {
				display: flex;
				align-items: center;
				justify-content: space-between;

				@include media-query(min, $lap-start) {
					justify-content: flex-start;
				}

				&:after {
					content: "";
					display: inline-block;
					width: 0;
					height: 0;
					border-top: 6px solid transparent;
					border-bottom: 6px solid transparent;
					border-left: 8px solid $accent;
					margin-left: 8px;

					@include media-query(min, $lap-start) {
						transform: rotate(90deg);
					}
				}
			}
		}

		@include media-query(min, $lap-start) {
			border: none;
		}

		> a {
			display: block;
			padding: $half-spacing;
			color: $white;
			text-decoration: none;

			@include hover {
				text-decoration: none;
			}

			@include media-query(min, $lap-start) {
				text-transform: uppercase;
				padding: $half-spacing 0;
				@include font-size($small-font-size);
			}

			@include media-query(min, $desk-start) {
				@include font-size($base-font-size);
			}
		}
	}

	.level0.submenu {
		position: absolute;
		left: 0;
		list-style: none;
		margin: 0;
		background-color: $black;
		z-index: 2;

		@include media-query(max, $lap-start) {
			top: 0;
			transform: translateX(100%);
			width: 100%;
			height: 50vh;
			overflow-x: hidden;
			transition: transform ease 0.3s;

			&.active {
				transform: translateX(0);
			}
			&.overflow {
				overflow-y: hidden;
			}
		}

		@include media-query(min, $lap-start) {
			width: 100%;
			max-width: $page-width;
			margin: 0 auto;
			top: 100%;
			right: 0;
			opacity: 0;
			visibility: hidden;
			transition: all ease 0.3s;
			border-top: 1px solid $grey;
			min-height: 300px;
			display: block;
			overflow: auto;

			&.active {
				opacity: 1;
				visibility: visible;
			}
		}

		> li {
			border-bottom: 1px solid $grey;

			&.parent {
				> a {
					display: flex;
					align-items: center;
					justify-content: space-between;

					&:after {
						content: "";
						display: inline-block;
						width: 0;
						height: 0;
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
						border-left: 8px solid $accent;
						margin-left: 8px;
					}
				}
			}

			@include media-query(min, $lap-start) {
				border: none;
				width: 25%;
			}

			> a {
				display: block;
				padding: $half-spacing;
				color: $white;
				text-decoration: none;
				width: 100%;

				@include hover {
					text-decoration: none;
				}

				@include media-query(min, $lap-start) {
					padding: 4px $base-spacing;
					@include font-size($small-font-size);

					@include hover {
						text-decoration: underline;
					}
				}

				@include media-query(min, $desk-start) {
					@include font-size($base-font-size);
				}
			}
		}
	}

	.level1.submenu {
		position: absolute;
		left: 0;
		list-style: none;
		margin: 0;
		background-color: $black;
		z-index: 1;

		@include media-query(max, $lap-start) {
			top: 0;
			transform: translateX(100%);
			width: 100%;
			height: 50vh;
			overflow-x: hidden;
			transition: transform ease 0.3s;

			&.active {
				transform: translateX(0);
			}
			&.overflow {
				overflow-y: hidden;
			}
		}

		@include media-query(min, $lap-start) {
			width: 25%;
			top: 0;
			left: 25%;
			border-left: 1px solid $grey;
			opacity: 0;
			visibility: hidden;
			transition: all ease 0.3s;
			z-index: 2;
			display: block;
			min-height: 300px;
			overflow: auto;
			height: 100%;

			&.active {
				opacity: 1;
				visibility: visible;
			}
		}

		> li {
			border-bottom: 1px solid $grey;

			&.parent {
				> a {
					display: flex;
					align-items: center;
					justify-content: space-between;

					&:after {
						content: "";
						display: inline-block;
						width: 0;
						height: 0;
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
						border-left: 8px solid $accent;
						margin-left: 8px;
						@include media-query(min, $lap-start) {
							display: none;
						}
					}
				}
			}

			@include media-query(min, $lap-start) {
				border: none;
			}

			> a {
				display: block;
				padding: $half-spacing;
				color: $white;
				text-decoration: none;

				@include hover {
					text-decoration: none;
				}

				@include media-query(min, $lap-start) {
					@include font-size($small-font-size);
					padding: 4px $base-spacing;

					@include hover {
						text-decoration: underline;
					}
				}

				@include media-query(min, $desk-start) {
					@include font-size($base-font-size);
					padding: 4px $base-spacing;
				}
			}
		}
	}

	li.level2 {
		> a {
			&:after {
				display: none!important;
			}
		}
	}
	
	.level2.submenu {
		position: absolute;
		left: 0;
		list-style: none;
		margin: 0;
		background-color: $black;
		z-index: 1;

		@include media-query(max, $lap-start) {
			display: none;
			top: 0;
			transform: translateX(100%);
			width: 100%;
			height: 50vh;
			overflow-x: hidden;
			transition: transform ease 0.3s;

			&.active {
				transform: translateX(0);
			}
			&.overflow {
				overflow-y: hidden;
			}
		}

		@include media-query(min, $lap-start) {
			display: none;
			width: 100%;
			top: 0;
			left: 0;
			transform: translateX(100%);
			border-left: 1px solid $grey;
			opacity: 0;
			visibility: hidden;
			transition: all ease 0.3s;
			z-index: 2;
			overflow-y: auto;
			overflow-x: auto;
			&.active {
				opacity: 1;
				visibility: visible;
			}
		}

		> li {
			border-bottom: 1px solid $grey;

			&.parent {
				> a {
					display: flex;
					align-items: center;
					justify-content: space-between;

					&:after {
						content: "";
						display: inline-block;
						width: 0;
						height: 0;
						border-top: 6px solid transparent;
						border-bottom: 6px solid transparent;
						border-left: 8px solid $accent;
						margin-left: 8px;
					}
				}
			}

			@include media-query(min, $lap-start) {
				border: none;
			}

			> a {
				display: block;
				padding: $half-spacing;
				color: $white;
				text-decoration: none;

				@include hover {
					text-decoration: none;
				}

				@include media-query(min, $lap-start) {
					@include font-size($small-font-size);
					padding: 4px $base-spacing;

					@include hover {
						text-decoration: underline;
					}
				}

				@include media-query(min, $desk-start) {
					@include font-size($base-font-size);
					padding: 4px $base-spacing;
				}
			}
		}
	}

	.level3 {
		a {
			display: block;
			padding: $half-spacing;
			color: $white;
			text-decoration: none;
			@include hover {
				text-decoration: none;
			}

			@include media-query(min, $lap-start) {
				display: none;
				@include font-size($small-font-size);
				padding: 4px $base-spacing;

				@include hover {
					text-decoration: underline;
				}
			}

			@include media-query(min, $desk-start) {
				@include font-size($base-font-size);
				padding: 4px $base-spacing;
			}
		}
	}
}

.submenu {
	pointer-events: none;
}

.submenu.active,
.submenu.active:hover {
	pointer-events: auto;
}

.submenu-cms {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	min-height: 100%;
	clear: none;
	color: $white;
	padding: $base-spacing;
	visibility: hidden;
	z-index: 2;
	border-left: 1px solid $grey;
	@include font-size($small-font-size);

	&.active {
		opacity: 1;
		visibility: visible;
	}

	@include media-query(min, $lap-start) {
		display: block;
	}

	@include media-query(min, $desk-start) {
		@include font-size($base-font-size);
	}

	img {
		margin-bottom: $base-spacing;
	}
}

.site-nav__back {
	button {
		background: $accent;
		border: none;
		color: $white;
		padding: $half-spacing;
		width: 100%;
		text-align: left;

		@include hover {
			background: $accent;
			color: $white;
		}
	}

	@include media-query(min, $lap-start) {
		display: none;
	}
}
