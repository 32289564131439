$elementsToHide: (
    ".site-header .site-nav",
    ".site-header .site-actions",
    ".site-header .site-header__search",
    ".top-bar__container",
    ".box-tocart",
    ".product-page-dealer-search",
    ".product-page__online-dealer",
    ".site-footer__newsletter",
    ".site-footer .social-links",
    ".product-addto-links",
    ".description-container .social-buttons",
    ".site-footer__info",
    ".resources__links-wrapper",
    ".bottom-links-container",
    ".product-social-links",
    ".pagebuilder-slider",
    "[data-content-type='video']",
    ".products-list.related-products",
    ".related-products__heading",
    ".eapp-popup-layout-variation-slide-in-right-component",
    "#tab-detail .block-products-list",
    "div[class*='BackToTop__StyledButton']",
    ".trustpilot-widget"
);

@media only print {
    @each $class in $elementsToHide {
        #{$class} {
            display: none !important;
        }
    }

    // Page builder elements, force desktop images to show 
    // and hide mobile images
    .pagebuilder-mobile-hidden {
        display: block !important;
    }

    .pagebuilder-mobile-only {
        display: none !important;
    }

    // Header
    .site-header {
        border: 0;
    }

    .site-header__container {
        padding-top: 20px;
    }

    .site-header__logo {
        margin: 0 !important;
        padding: 10px !important;
        background-color: $black !important;
        width: 200px !important;
    }

    // Product page price
    .product-info-price {
        margin-bottom: 0;

        .price-container.price-final_price {
            display: block;
            text-align: left;
        }
    }

    // Product page gallery
    .fotorama__stage {
        height: 300px !important;
    }

    .fotorama__stage__frame .fotorama__img {
        max-width: 300px !important;
    }

    // Product page tabs
    .product-tabs-section .item.content#tab-detail,
    .product-tabs-section .item.content#tab-spec {
        display: block !important;
    }

    .product-tabs-section .item.content#tab-detail {
        div,
        figure {
            break-inside: avoid;
        }
    }

    .product-tabs #tab-spec dd,
    .product-tabs #tab-spec dt {
        padding: 0;
    }

    // Product page tabs hide tab titles
    .product-tabs-section .item.title {
        display: none !important;
    }
}
