#compatibility-form-container {
    padding-top: $base-spacing;
    @include media-query(min, $desk-start) {
        .fields {
            width: 60%;
            float: left;
            margin-left: -$base-spacing;
            
            .field {
                width: 50%;
                max-width: 50%;
                float: left;
                padding-left: $base-spacing;
                
                &.compatibility-type-select {
                    width: 100%;
                    max-width: 100%;
                    float: none;
                }
            }
        }
        .model-picked {
            width: 40%;
            float: right;
            padding-left: $double-spacing;
        }
    }

    @include media-query(min, $wide-start) {
       .product-item-name {
           margin-bottom: 20px;
       }
    }

    .product-item-details {
        text-align: center;
    }

    .price-container > span {
        display: block;
        text-align: center;
    }
}

.compatibility-page-header {
    margin-bottom: $base-spacing;
}

.compatibility-type {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $base-spacing;
    
    @include media-query(min, $desk-start) {
        grid-template-columns: repeat(4, 1fr);
    }
    
    .type {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        
        input {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding: 0;

            &.active {
                border: 2px solid $accent;
            }
        }
    }
}


.model-picked {
    margin-bottom: $base-spacing;
    
    .compatibility-image {
        margin-bottom: $half-spacing;
        width: 100%;
        max-width: 35em;
    }
    
    .headline-caption {
        font-weight: bold;
    }
    
    .bullet-points {
        padding-top: $half-spacing;
        column-count: 2;
        column-gap: $half-spacing;
    }
}


.field-grade-select {
    clear: both;
}

.compatible-products-tiers {
    clear: both;
    
    h4 {
        margin-bottom: 0;
    }
}

.compatibility-grade {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.2em;
    padding-right: 22px;

    @include media-query(min, $desk-start) {
        padding-right: 15px;
    }

    .compatibility-icon {
        width: 20%;
        margin-right: 7px;
    }
}
