.catalog-product-view {
    .price-box {
        display: flex;

        .special-price {
            text-align: left;
        }
        .old-price {
            text-align: right;
        }
    }
}
.price-box {

}


.price-container {

}

.price-wrapper  {

}

.price {

}