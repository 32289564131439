.page-with-filter {
    .page-main {
        position: relative;
    }
}

.grouped-products {
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;

    @include media-query(min, $lap-start) {
        margin-right: initial;
        margin-left: initial;
        &:after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $silver-lt;
            bottom: 0;
            left: 0;
        }
    }
}

.grouped-products__filter {
    .filter-actions {
        position: relative;
        margin-top: $double-spacing * 1.5;
        @include media-query(min, $lap-start) {
            margin-top: 0;
        }
    }
    .filter-current {
        .item {
            margin-left: 0;
        }
    }
    .button {

        @include media-query(min, $lap-start) {
            width: 200px;
            top: -70px;
        }
        position: absolute;
        right: 0;
        top: -70px;
        height: 48px;
        padding: 0 20px;
        width: 100%;

        &--close {
            display: none;

            .svg-icon {
                height: 16px;
                width: 16px;
            }
        }

        &--close.active {
            display: block;
        }

        &--filter {
            display: block;
        }

        &--filter.active {
            display: none;
        }

        span {
            float: left;
        }

        .svg-icon {
            float: right;
            margin-left: $half-spacing;
        }
    }


    .filter__wrapper {

        &.active {
            .filter {
                display: block;
            }
        }

        @include media-query(min, $lap-start) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: $base-and-half-spacing;
            background-color: $silver-lt;
            border-top: 4px solid $silver;
            border-bottom: 4px solid $silver;
            overflow: auto;
            margin: 0;
            display: none;
            z-index: 1;

            &.active {
                display: block;
            }
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: $grey;
            -webkit-box-shadow: 0 0 1px $silver;
        }
    }

    .filter {

    }
}

.filter-options__items {

    list-style-type: none;
    margin: 0;
    @include media-query(min, $lap-start) {
        display: flex;
        flex-wrap: wrap;
    }
}

.filter-options__item {
    margin-bottom: $base-spacing;
    @include media-query(min, $lap-start) {
        width: calc(33.33% - (20px * 2 / 3));
        margin-left: 20px;
        margin-bottom: $half-spacing;

        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }
    @include media-query(min, $desk-start) {
        width: calc(33.33% - (150px * 2 / 3));
        margin-left: 150px;
        margin-bottom: $half-spacing;

        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }
}

.filter__container {
    @include media-query (min, $lap-start) {
        max-width: $page-width;
        margin: 0 auto;
        padding: 0 $base-spacing + $half-spacing;
    }
}

.grouped-products__wrapper {
    .filter {

    }

    .filter-title {

    }

    .filter-content {

    }

    .filter-subtitle {
        display: inline-block;
        position: relative;
        width: auto;
        height: auto;
        text-transform: uppercase;
        overflow: auto;
        @include font-size(20px);
        font-weight: bold;
        margin-bottom: $base-spacing;
    }

    .filter-options {

    }

    .filter-options-content {
        select {
            border: 1px solid $silver;
            background: url(../img/select-down-arrow.svg) no-repeat right 10px center #fff;
            background-size: 15px;
        }

        .items {

        }

        .item {

        }
    }

    .filter-options-title {
        @include font-size($base-font-size);
        margin-bottom: $half-spacing;
    }
}

.grouped-products__list {

    .table__image-block {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $base-spacing;
        @include media-query(min, $lap-start) {
            flex-wrap: nowrap;
            justify-content: initial;
            align-items: center;
        }

        .table__image {
            margin-left: $base-spacing;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }

    .table__product-info {
        order: 2;
        @include media-query(min, $lap-start) {
            order: initial;
            margin-right: auto;
            margin-left: $double-spacing;
        }
    }

    .table--filtered {
        padding-bottom: $double-spacing;
        padding-right: $base-spacing;
        padding-left: $base-spacing;
        border-bottom: 1px solid $silver;
        margin-bottom: $base-spacing;

        &:last-of-type {
            border-bottom: none;
            padding-bottom: $base-spacing;
        }

        @include media-query(min, $lap-start) {
            flex-wrap: nowrap;
            padding-right: initial;
            padding-left: initial;

            th {
                text-align: center;
            }
            td {
                text-align: center;
                width: 120px;
            }
        }
    }
}

@include media-query(min, $lap-start) {
    .grouped-products__list .table__image-block {
        justify-content: space-between;
    }
}

@include media-query(max, $desk-start) {
    .grouped-products__list .table__image-block {
        .table__image-2,
        .table__image-3 {
            display: none;
        }
    }
}

@include media-query(max, $lap-start) {
    .grouped-products__list .table__image-block {
        align-items: center;
        .table__image-1,
        .table__image-0 {
            display: none;
        }
    }
}
