.social-buttons {
  margin-bottom: $base-spacing;

}

.social-message {
  display: inline-block;
  @include font-size(13px);
  font-weight: 400;
}

.social {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  color: $black;

  li {
    display: inline-block;
    padding: 5px;
  }

  a {
    background-color: $black;
    color:$white;
    width: 32px;
    height: 32px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
  }
  .svg-icon {
    width: 24px;
    height: 24px;

  }
}