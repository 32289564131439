.pagebuilder-accordion {
    margin: 0;
    padding: 0;
}

.pagebuilder-accordion > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%;
}

.pagebuilder-accordion > .item.title > .switch {
    display: block;
}

.pagebuilder-accordion > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0;
}

.pagebuilder-accordion > .item.content:before,
.pagebuilder-accordion > .item.content:after {
    content: '';
    display: table;
}

.pagebuilder-accordion > .item.content:after {
    clear: both;
}

.pagebuilder-accordion > .item.content.active {
    display: block;
}

.pagebuilder-accordion > .item.title {
    margin: 0 0 5px;
}

.pagebuilder-accordion > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    height: 40px;
    padding: 5px 20px 5px 20px;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #7d7d7d;
    text-decoration: none;
}

.pagebuilder-accordion > .item.title > .switch:visited {
    color: #7d7d7d;
    text-decoration: none;
}

.pagebuilder-accordion > .item.title > .switch:hover {
    color: #7d7d7d;
    text-decoration: none;
}

.pagebuilder-accordion > .item.title > .switch:active {
    color: #333;
    text-decoration: none;
}

.pagebuilder-accordion > .item.title:not(.disabled) > .switch:focus,
.pagebuilder-accordion > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc;
}

.pagebuilder-accordion > .item.title:not(.disabled) > .switch:active,
.pagebuilder-accordion > .item.title.active > .switch,
.pagebuilder-accordion > .item.title.active > .switch:focus,
.pagebuilder-accordion > .item.title.active > .switch:hover {
    background: #fff;
    color: #333;
    text-decoration: none;
    padding-bottom: 5px;
}

.pagebuilder-accordion > .item.content {
    background: #fff;
    border: 1px solid #d1d1d1;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px;
}
