@import "module/_variables";

// custom overrides for trend
$amgdprcookie-link__color: $accent;
$amgdprcookie-link__base_color: $accent;
$amgdprcookie-toogle-checked__background: $accent;
$amgdprcookie-button__focus: $accent;
$amgdprcookie-popup__shadow: $accent-dk;
$amgdprcookie-btn-done__highlight: $accent;
.amgdprcookie-modal-template .action-close,
.amgdprcookie-bar-container .action-close {
    display: none;
}
// end custom overrides

@import "module/_mixins";
@import "module/_base";
@import "module/_modal";
