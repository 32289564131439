.minicart {
    .action {
        position: relative;

        .counter {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-10%);
            line-height: 1;

            @include media-query (min, $lap-start) {
                top: -10px;
                left: 0;
                transform: translateX(75%);
            }

            .counter-number {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                background-color: $accent;
                color: $white;
                border-radius: 100%;
                @include font-size($micro-font-size);

                @include media-query (min, $lap-start) {
                    width: 25px;
                    height: 25px;
                    @include font-size($small-font-size);
                }
            }

            .svg-icon {
                width: 1.5em;
                height: 1.5em;
            }
        }

        .subtotal {
            display: none;

            @include media-query (min, $lap-start) {
                display: inline-block;
                position: relative;
                top: 5px;
            }
        }
    }

    > div {
        position: absolute;
        top: 120px;
        right: 0;
        width: 290px;
        z-index: 2;

        @include media-query (min, $lap-start) {
            width: 390px;
        }
    }

    .minicart__dropdown {
        padding: $base-spacing;
        background-color: $smoke;
        border: 1px solid $mercury-dk;
        border-radius: $half-radius;
        position: relative;

        &.empty {
            display: none;
        }

        &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            right: $base-spacing;
            transform: translateY(-100%);
            border-left: $half-spacing solid transparent;
            border-right: $half-spacing solid transparent;
            border-bottom: $half-spacing solid $mercury-dk;

            @include media-query (min, $lap-start) {
                right: $double-spacing;
            }
        }

        .block-title {
            display: block;
            margin-bottom: $base-spacing;
        }

        .block-content {
            display: block;

            .action.close {
                position: absolute;
                top: $base-spacing;
                right: $base-spacing;
                z-index: 1;
                @include font-size($micro-font-size);
                min-height: 22px;
                line-height: $base-spacing;
                padding: 0 $half-spacing;
            }

            .items-total {
                display: none;
                color: $grey;
            }

            .subtotal {
                display: flex;
                justify-content: space-between;

                .label {
                    margin-bottom: 0;
                }

                .price-container {
                    @include font-size($base-font-size);
                }
            }

            > .actions {
                margin-top: $base-spacing;
                padding-top: $base-spacing;
                border-top: 1px solid $mercury-dk;

                .action.checkout {
                    width: 100%;
                    margin-bottom: $half-spacing;
                }
            }

            .subtitle {
                display: block;
                margin-bottom: $base-spacing;
                margin-top: $base-spacing;
                padding-top: $base-spacing;
                border-top: 1px solid $mercury-dk;
            }

            .action.viewcart {
                display: block;
                text-align: center;
            }

            .button.action.viewcart {
                width: 100%;
                background-color: $primary;
                border-color: $primary;

                @include hover {
                    background-color: darken($primary, 10%);
                    border-color: darken($primary, 10%);
                }

            }
        }

        .product-item {
            margin-bottom: 0;
        }
    }

    .minicart-items-wrapper {
        height: auto !important;
        max-height: 40vh;
        padding: 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        @include media-query (min, $lap-start) {
            max-height: 320px;
        }

        .minicart-items {
            margin: 0;
            list-style: none;
        }

        .product-item {
            padding-top: $base-spacing;
            margin-top: $base-spacing;
            margin-left: 0;
            border: none;
            border-top: 1px solid $mercury-dk;
            border-radius: 0;
            outline: none;
            transition: none;
            width: 100%;


            @include hover {
                transform: none;
                box-shadow: none;
            }

            &:first-child {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
            }

            > .product {
                display: flex;
            }
        }

        .product-image-container,
        .product-item-photo {
            display: block;
            flex-shrink: 0;
            padding: 0;

            img {
                position: static;
                width: 100%;
            }
        }

        .product-item-details {
            display: block;
            margin-left: $base-spacing;
            padding: 0;

            .product-item-inner {
                padding: 0;
            }

            .product-item-name-block {
                display: block;
                text-decoration: none;
            }

            .product-item-name {
                display: block;
                margin-bottom: $half-spacing;
                line-height: 1.3;

                a {
                    text-decoration: none;
                }
            }

            .details-qty {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: $half-spacing;
                align-items: baseline;
                padding-top: $half-spacing;

                .label {
                    margin-right: $half-spacing;
                    margin-bottom: 0;
                }

                .cart-item-qty {
                    margin-bottom: $half-spacing;
                    width: 54px;
                    text-align: center;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }

                button {
                    display: block;
                }
            }

            .subtotal {
                display: block;
            }

            .product.options {
                display: block;

                .toggle {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    &:after {
                        content: '';
                        display: block;
                        width: ($base-spacing - 5px);
                        height: ($base-spacing - 5px);
                        margin-left: $half-spacing;
                        background: url("#{$image-path}/arrow-down.svg") no-repeat center;
                        background-size: contain;
                    }

                    @include hover {
                        outline: none;
                    }
                }

                .content {
                    .subtitle {
                        display: none;
                    }
                }

                .product.options.list,
                .item-options {
                    padding-top: $half-spacing;
                    margin-bottom: 0;

                    .label {
                        display: block;
                        margin-bottom: $micro-spacing;
                        @include font-size($micro-font-size);
                        line-height: 1;

                        &:after {
                            content: ':';
                        }
                    }

                    .values {
                        display: block;
                        @include font-size($micro-font-size);
                        line-height: 1;
                        margin-bottom: $base-spacing;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                }
            }

            .product-item-pricing {
                .price-container {
                    @include font-size($base-font-size);
                }
            }

            .product.actions {
                display: flex;

                .primary {
                    margin-right: $half-spacing;
                }
            }
        }

    }
}
.minicart-wrapper .action.close {
height: 30px;
position: absolute;
right: 0;
top: 0;
width: 25px;
background-image: none;
background: none;
-moz-box-sizing: content-box;
border: 0;
box-shadow: none;
line-height: inherit;
margin: 0;
padding: 0;
text-shadow: none;
font-weight: 400;
display: inline-block;
text-decoration: none;
}
.minicart-wrapper .action.close:focus,
.minicart-wrapper .action.close:active {
    background: none;
    border: none;
}
.minicart-wrapper .action.close:hover {
    background: none;
    border: none;
}
.minicart-wrapper .action.close.disabled,
.minicart-wrapper .action.close[disabled],
fieldset[disabled] .minicart-wrapper .action.close {
    pointer-events: none;
    opacity: 0.5;
}
.minicart-wrapper .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.minicart-wrapper .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 14px;
    color: #757575;
    content: '\e616';
    font-family: 'luma-icons';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-wrapper .action.close:hover:before {
    color: inherit;
}
.minicart-wrapper .action.close:active:before {
    color: inherit;
}
.minicart-wrapper .action.showcart {
    white-space: nowrap;
}
.minicart-wrapper .action.close {
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
}
.minicart-wrapper .action.close:focus,
.minicart-wrapper .action.close:active {
    background: none;
    border: none;
}
.minicart-wrapper .action.close:hover {
    background: none;
    border: none;
}
.minicart-wrapper .action.close.disabled,
.minicart-wrapper .action.close[disabled],
fieldset[disabled] .minicart-wrapper .action.close {
    pointer-events: none;
    opacity: 0.5;
}
.minicart-wrapper .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.minicart-wrapper .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 14px;
    color: #757575;
    content: '\e616';
    font-family: 'luma-icons';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-wrapper .action.close:hover:before {
    color: #757575;
}
.minicart-wrapper .action.close:active:before {
    color: #757575;
}
.minicart-wrapper .action.showcart {
    white-space: nowrap;
}


.minicart-items .action.edit > span,
.minicart-items .action.delete > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.minicart-items .action.edit:before,
.minicart-items .action.delete:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 20px;
    color: #757575;
    content: '\e601';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-items .action.edit:hover:before,
.minicart-items .action.delete:hover:before {
    color: #333333;
}
.minicart-items .action.edit:active:before,
.minicart-items .action.delete:active:before {
    color: #757575;
}
.minicart-items .action.delete:before {
    content: '\e604';
}
