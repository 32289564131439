.page-layout-checkout {

  .page-header {
    width: 100%;
    max-width: $page-width;
    margin: 0 auto;
    padding: 0 $base-spacing;
    margin-bottom: $double-spacing;

    @include media-query (min, $lap-start) {
      padding: 0 $base-spacing + $half-spacing;


    }

  }

  .header {

  }

  .site-header__logo {
    display: block;
    margin-right: auto;
  }

  .page-title-wrapper {
    display: none;
  }
}
