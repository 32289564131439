.opc-progress-bar {
  display: flex;
  position: relative;
  list-style: none;
  margin-bottom: $base-spacing;
  margin-left: 0;

  @include media-query(min, $lap-start) {
    margin-bottom: $double-spacing;
  }
}

.opc-progress-bar-item {


  @include font-size($base-font-size);

  @include media-query(min, $lap-start) {

  }

  &._active {
    &:before {
      background-color: $white;
      border-color: $accent;
      color: $accent;
    }
  }

  &._complete {
    &:before {
      background-color: $accent;
      border-color: $accent;
      color: $white;
    }
  }

  &:first-child {
    margin-right: $double-spacing;

    &:before {
      content: '1';
      margin-bottom: $base-spacing;
    }
  }

  &:last-child {
    &:before {
      content: '2';
      margin-bottom: $base-spacing;
    }
  }

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: $button-height;
    height: $button-height;
    border-radius: 100%;
    border: 3px solid $black;
    background-color: $white;
    color: $black;
    font-weight: bold;
    @include font-size($h4-font-size);

    @include media-query(min, $lap-start) {
      width: 70px;
      height: 70px;
      @include font-size($h2-font-size);
    }
  }

  span {
    display: block;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    @include hover {
      text-decoration: underline;
    }
  }
}