.site-header {
    position: relative;
    border-bottom: 1px solid $black;

    @include media-query(min, $lap-start) {
        border-bottom: 0;
    }
}

.site-header__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        align-items: flex-end;
        position: relative;
    }
}

.site-header__top-bar {
    width: 100%;
    display: block;

}

.top-bar__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;
    margin-bottom: $base-spacing;
    padding: $half-spacing $base-spacing;

    @include media-query(min, $lap-start) {
        margin: $base-spacing 0 (-$base-spacing * 2.5) auto;
        padding: 0;
        justify-content: flex-end;
        background-color: initial;
        max-width: 520px;
    }
    @include media-query(min, $desk-start) {
        margin: $base-spacing 0 (-$base-spacing * 2.5) auto;
        padding: 0;
        justify-content: flex-end;
        background-color: initial;
        max-width: 560px;
    }
}

.site-header__logo {
    display: flex;
    align-items: center;
    width: 90px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $half-spacing * 1.5 $half-spacing * 0.5;
    margin-right: $base-spacing;
    background-color: $black;

    @include media-query(min, 450px) {
        width: 120px;
    }

    @include media-query(min, $lap-start) {
        width: 150px;
        padding: $double-spacing $base-spacing;
        margin-right: 0;
    }

    @include media-query(min, $desk-start) {
        width: 236px;
        height: 118px;
        padding: $base-spacing ($base-spacing + 10px);
    }
}

.site-header__search {
    @include media-query(max, $lap-start) {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateX(-100%);
        width: 100%;
        padding: $base-spacing;
        transition: transform ease .3s;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        background-color: $white;
        z-index: 1;

        &.active {
            transform: translateX(0);
        }
    }
    @include media-query(min, $lap-start) {
        flex-grow: 1;
        flex-shrink: 1;
    }
}

.site-header__actions {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    @include media-query(min, $lap-start) {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.site-header__links {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include media-query(min, $lap-start) {
    }
}

