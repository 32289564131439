.product-page__online-dealer {
    margin-bottom: 0;
}

.online-dealer {

}

.online-dealer__header {
    text-transform: uppercase;
    @include font-size($h4-font-size);
    background-color: $black;
    color: $white;
    padding: $base-spacing;
    margin-bottom: 0;
}

.online-dealer__list {
    list-style: none;
    margin: 0;
    background-color: #e0e0e0;

}

.online-dealer__item {

    &:last-of-type {
        margin-bottom: 0;
    }
}

.online-dealer__link {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: $half-spacing;
    align-items: center;
    text-decoration: none;
    padding: $base-spacing;


    @include hover {
        text-decoration: none;
        background-color: $silver;
    }
}

.online-dealer__img {
    max-width: 70px;

}

.online-dealer__name {
    margin-bottom: 0;
    padding: 0;
    color: $black;
    @include font-size(14px);
}

.online-dealer__text {
    margin-bottom: 0;
    font-weight: bold;
    @include font-size(13px);
}


