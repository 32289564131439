
.main-faqs-page-index-view,.main-faqs-page-index {
    width: 100%;
    margin-top: $base-spacing;

    @include media-query(min, $lap-start) {
        margin-top: $double-spacing;
    }
}

.main-faqs-page-index-view .faq_search,.main-faqs-page-index .faq_search {
    padding: 8px 14px 14px;
    background: #e4e4e4;
    margin: 0 0 20px;
    border-radius: 4px
}

.main-faqs-page-index-view .faq_search .ui-helper-hidden-accessible,.main-faqs-page-index .faq_search .ui-helper-hidden-accessible {
    padding: 0 0 6px;
    display: block;
    font-weight: 700
}

.main-faqs-page-index-view ul,.main-faqs-page-index ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.main-faqs-page-index-view ul li,.main-faqs-page-index ul li {
    font-size: 14px;
    color: #000;
    text-decoration: none;
    margin: 0
}

.main-faqs-page-index-view ul li a,.main-faqs-page-index ul li a {
    color: #333;
    text-decoration: none;
    font-weight: 700
}

.main-faqs-page-index-view .md_faq_main_left,.main-faqs-page-index .md_faq_main_left {
    border: #ddd solid 1px;
    border-radius: 4px
}

.main-faqs-page-index-view .md_faq_main_left ul li a,.main-faqs-page-index .md_faq_main_left ul li a {
    padding: 14px 32px 14px 16px;
    border-bottom: #ddd solid 1px;
    display: block
}

.main-faqs-page-index-view .md_faq_main_left ul li a:after,.main-faqs-page-index .md_faq_main_left ul li a:after {
    content: "\f105";
    margin: 0 -16px 0 0;
    color: #333;
    font-size: 18px;
    font-family: 'FontAwesome';
    float: right;
    line-height: 18px
}

.main-faqs-page-index-view .md_faq_main_left ul li a:hover,.main-faqs-page-index .md_faq_main_left ul li a:hover {
    background: $primary;
    color: #fff
}

.main-faqs-page-index-view .md_faq_main_left ul li a:hover:after,.main-faqs-page-index .md_faq_main_left ul li a:hover:after {
    color: #fff
}

.main-faqs-page-index-view .md_faq_main_left ul li.ui-state-active a,.main-faqs-page-index .md_faq_main_left ul li.ui-state-active a {
    background: #ddd
}

.main-faqs-page-index-view .md_faq_main_left ul li.ui-state-active a:after,.main-faqs-page-index .md_faq_main_left ul li.ui-state-active a:after {
    color: #333
}

.main-faqs-page-index-view .md_faq_main_left ul li.ui-state-active a:hover,.main-faqs-page-index .md_faq_main_left ul li.ui-state-active a:hover {
    color: #333
}

.main-faqs-page-index-view .md_faq_main_left ul li.active a,.main-faqs-page-index .md_faq_main_left ul li.active a {
    background: $primary;
    color: #fff
}

.main-faqs-page-index-view .md_faq_main_left ul li.active a:after,.main-faqs-page-index .md_faq_main_left ul li.active a:after {
    color: #fff
}

.main-faqs-page-index-view .md_faq_main_right ul li,.main-faqs-page-index .md_faq_main_right ul li {
    margin: 0 0 18px
}

.main-faqs-page-index-view .md_faq_main_right ul li a,.main-faqs-page-index .md_faq_main_right ul li a {
    background: url(../Magedelight_Faqs/images/faq-icon-gray.png) no-repeat left top;
    padding: 4px 0 3px 40px;
    display: block
}

.main-faqs-page-index-view .md_faq_main_right ul li .md_faq_questions_content,.main-faqs-page-index .md_faq_main_right ul li .md_faq_questions_content {
    display: block;
    background: url(../Magedelight_Faqs/images/faq-line.png) repeat-y 14px top;
    padding: 6px 0 0 40px
}

.main-faqs-page-index-view .md_faq_main_right ul li.questionExpand a,.main-faqs-page-index .md_faq_main_right ul li.questionExpand a {
    background: url(../Magedelight_Faqs/images/faq-icon.png) no-repeat left top;
    color: #1979c3
}

.main-faqs-page-tags .md_faq_main_left {
    border: #ddd solid 1px;
    border-radius: 4px
}

.main-faqs-page-tags .md_faq_main_left ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.main-faqs-page-tags .md_faq_main_left ul li a {
    padding: 14px 16px;
    border-bottom: #ddd solid 1px;
    display: block
}

.main-faqs-page-tags .md_faq_main_left ul li a:hover {
    background: $primary;
    color: #fff
}

.main-faqs-page-tags .md_faq_main_left ul li a:hover:after {
    color: #fff
}

.main-faqs-page-tags .md_faq_main_left ul li.ui-state-active a {
    background: #ddd
}

.main-faqs-page-tags .md_faq_main_left ul li.ui-state-active a:after {
    color: #333
}

.main-faqs-page-tags .md_faq_main_left ul li.ui-state-active a:hover {
    color: #333
}

.faqs-index-category .ui-autocomplete,.faqs-index-search .ui-autocomplete,.faqs-index-index .ui-autocomplete {
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 2px 2px 5px #ccc;
    list-style: none;
    border: #ccc solid 1px;
    padding: 10px;
    width: 45% !important;
    margin: -1px 0 0 !important
}

.faqs-index-category .ui-autocomplete .ui-autocomplete-category,.faqs-index-search .ui-autocomplete .ui-autocomplete-category,.faqs-index-index .ui-autocomplete .ui-autocomplete-category {
    font-weight: 700;
    font-size: 16px
}

.faqs-index-category .ui-autocomplete .ui-menu-item a,.faqs-index-search .ui-autocomplete .ui-menu-item a,.faqs-index-index .ui-autocomplete .ui-menu-item a {
    font-size: 13px;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer
}

.faqs-index-category .ui-autocomplete .ui-menu-item a:hover,.faqs-index-search .ui-autocomplete .ui-menu-item a:hover,.faqs-index-index .ui-autocomplete .ui-menu-item a:hover {
    text-decoration: underline
}

.faqs-index-category a.accordion-toggle.faq_questions_title,.faqs-index-search a.accordion-toggle.faq_questions_title,.faqs-index-index a.accordion-toggle.faq_questions_title {
    text-decoration: none
}

.faqs-index-category .faq-index-list-view .faq-category,.faqs-index-search .faq-index-list-view .faq-category,.faqs-index-index .faq-index-list-view .faq-category {
    line-height: 100px
}

.faqs-index-category .faqcat-block.col-md-6.col-xs-12,.faqs-index-search .faqcat-block.col-md-6.col-xs-12,.faqs-index-index .faqcat-block.col-md-6.col-xs-12 {
    height: auto;
    min-height: 309px
}

.faqs-index-category .panel-group .panel-heading,.faqs-index-search .panel-group .panel-heading,.faqs-index-index .panel-group .panel-heading {
    border-bottom: 1px solid #ccc
}

.faqs-index-category .panel-default>.panel-heading+.panel-collapse>.panel-body,.faqs-index-search .panel-default>.panel-heading+.panel-collapse>.panel-body,.faqs-index-index .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: none;
    margin: 15px 0 20px
}

.faqs-index-category .faq-index-list-view .faqcat-desc,.faqs-index-search .faq-index-list-view .faqcat-desc,.faqs-index-index .faq-index-list-view .faqcat-desc {
    margin-bottom: 15px;
    text-align: justify
}

.faqs-question-tags h3.faqcat-title {
    margin-top: 0;
    margin-bottom: 2rem
}

.faqs-question-view .mage-faq-social {
    float: right;
    margin-top: 20px
}

.main-faqs-page-index-view .md_faq_main_heading h3 {
    clear: both;
    display: block;
    float: left;
    font-size: 18px;
    font-weight: 700;
    margin: 20px 0 16px;
    padding: 0;
    width: 100%
}

.main-faqs-page-index-view .md_faq_main_heading h3 a {
    color: #000;
    text-decoration: none;
    background: #ddd;
    display: block;
    padding: 12px 32px 12px 16px;
    border-radius: 4px
}

.main-faqs-page-index-view .md_faq_main_heading h3 a:after {
    content: "\f105";
    line-height: 18px;
    margin: 0 -16px 0 0;
    color: #333;
    font-size: 20px;
    font-family: 'FontAwesome';
    float: right
}

.main-faqs-page-index-view .md_faq_main_heading h3 a:hover {
    background: $primary;
    color: #fff
}

.main-faqs-page-index-view .md_faq_main_heading h3 a:hover:after {
    color: #fff
}

.faqs-index-category .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: none
}

.faq-index-list-view .faq-category {
    border-bottom: 1px solid #e6e6e6
}

.faq-index-list-view .faq-category .faq-category-icon {
    height: 35px;
    margin: 0 25px 0 0;
    width: 35px;
    font-size: 45px;
    display: table-cell;
    vertical-align: middle;
    padding-right: 20px
}

.faq-index-list-view .faq-category .faq-category-title {
    display: table-cell;
    vertical-align: middle;
    width: 100%
}

.faq-index-list-view .faq-category-title h3 {
    margin: 0;
    font-size: 24px
}

.faq-index-list-view .faqcat-desc {
    margin-top: 10px;
    font-size: 15px;
    line-height: 20px;
    color: #8a8a8a
}

.faq-index-list-view .faq-category a {
    display: table;
    min-height: 75px;
    padding: 20px 10px;
    width: 100%;
    text-decoration: none
}

.question-list .panel-heading {
    border: 1px solid #ccc;
    cursor: pointer
}

.panel_title {
    padding: 10px 0 10px 15px;
    margin: 0;
    font-size: 14px;
    line-height: 1.2
}

.mage-faq-social {
    float: right
}

.question-details .like-text {
    font-weight: 600;
    float: left;
    padding: 8px 8px 8px 0
}

.product-custometab-faq .main-faqs-page-index-view h3.faqcat-title {
    margin: 0 0 15px
}

.catalog-product-view .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top: none;
    margin: 0
}

.catalog-product-view .main-faqs-page-index-view ul,.catalog-product-view .main-faqs-page-index ul {
    margin: 0 0 12px
}

.catalog-product-view .main-faqs-page-index-view ul li,.catalog-product-view .main-faqs-page-index ul li {
    padding: 0 0 5px
}

.main-faqs-page-index .block-title,.main-faqs-page-tags .block-title {
    border-bottom: 1px solid #ccc;
    background-color: #f4f4f4;
    color: #636363;
    text-transform: uppercase;
    padding: 14px 16px
}

.main-faqs-page-tags .tags-all-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.main-faqs-page-tags .md_faq_main_left ul li a {
    border-bottom: 0 none;
    border-radius: 10px;
    color: #333;
    display: block;
    font-weight: 700;
    padding: 10px 8px;
    text-decoration: none
}

.main-faqs-page-tags .faq-tag-list {
    display: inline-block;
    margin: 5px 0 5px 5px
}

.main-faqs-page-tags .faq-tag-list.active a {
    background: $primary;
    color: #fff
}

.panel_title {
    padding: 10px 0 10px 15px;
    margin: 0;
    font-size: 14px;
    line-height: 1.2
}

.faqs-cat-image,.faqs-cat-desc {
    margin-bottom: 20px
}

.faqs-cat-desc {
    text-align: justify
}

.question-list .panel-heading {
    border: 1px solid #ccc;
    cursor: pointer
}

.product-custometab-faq .block-title {
    font-weight: 700;
    font-size: 18px;
    color: #333;
    padding: 0 0 10px;
    margin: 0 0 20px;
    border-bottom: #ddd dashed 1px
}

.product-custometab-faq .faq-block-content {
    margin: 0 0 30px;
    display: block
}

.product-custometab-faq .faq-block-content ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.product-custometab-faq .faq-block-content ul li {
    margin: 0 0 18px
}

.product-custometab-faq .faq-block-content ul li a {
    color: #333;
    text-decoration: none;
    font-weight: 700;
    background: url(../Magedelight_Faqs/images/faq-icon-gray.png) no-repeat left top;
    padding: 4px 0 3px 40px;
    display: block
}

.product-custometab-faq .faq-block-content ul li .md_faq_questions_content {
    display: block;
    background: url(../Magedelight_Faqs/images/faq-line.png) repeat-y 14px top;
    padding: 6px 0 0 40px
}

.product-custometab-faq .faq-block-content ul li.questionExpand a {
    background: url(../Magedelight_Faqs/images/faq-icon.png) no-repeat left top;
    color: #1979c3
}

.faqs-question-tags .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: none
}
