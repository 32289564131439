.home-featured {
    text-align: center;
    margin-bottom: $double-spacing;

    @include media-query(min, $lap-start) {
        margin-bottom: ($double-spacing * 2);
    }
}

.home-featured__title {
    padding-top: 0;
    margin-bottom: $base-spacing;
    display: none;

    @include media-query(min, $lap-start) {
        display: block;
        @include font-size($h4-font-size);
        text-transform: uppercase;
    }
}

.home-featured__block {


}

.home-featured__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-query(min, $lap-start) {
        justify-content: start;
        border-radius: 5px;
    }
}

.home-featured__wrapper {

    &.active {
        .featured__block {
            @include media-query-bracket(0, $lap-start) {
                &:nth-child(n + 5) {
                    display: block;
                }
            }

            @include media-query(min, $lap-start) {
                &:nth-child(n + 10) {
                    display: block;
                }
            }
        }
    }
}

.featured__block {
    position: relative;
    width: calc(50% - #{$half-spacing * 1.5});
    margin-left: $base-spacing * 1.5;

    @include media-query-bracket(0, $lap-start) {
        &:nth-child(n + 5) {
            display: none;

        }
        &:nth-child(odd) {
            margin-left: 0;
        }
    }


    @include media-query(min, $lap-start) {
        &:nth-child(n + 10) {
            display: none;
        }
    }


    @include media-query(min, $lap-start) {
        width: calc(33.3333% - (#{$base-spacing * 2} / 3));
        margin-left: $base-spacing;
        margin-bottom: $base-spacing;
        transform: translateY(0);
        transition: all .3s ease-in-out;

        @include hover {
            .featured-block__image-link {
                transform: translateY(-15px);
            }

            .featured-block__link {
                background-color: $accent;
                color: $white;
                height: 90px;
                transform-origin: bottom;
            }

        }

        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }

}

.featured-block__image-link {
    display: block;
    background-color: $lightgrey;
    padding-bottom: 100%;
    margin-bottom: $half-spacing;

    @include media-query(min, $lap-start) {
        transform: translateY(0);
        transition: all .3s ease-in-out;
        margin-bottom: 0;
    }
}

.featured-block__link {
    text-decoration: none;
    @include font-size(15px);
    font-weight: bold;
    display: block;
    text-align: center;
    margin-bottom: $half-spacing;
    cursor: pointer;

    @include hover {
        color: $accent;
        text-decoration: none;
    }

    @include media-query(min, $lap-start) {
        @include font-size(21px);
        position: absolute;
        width: 100%;
        display: block;
        height: 70px;
        margin-bottom: 0;
        padding: $base-spacing $half-spacing;
        bottom: 0;
        color: $white;
        background-color: rgba($black, 0.8);
        border-radius: 0 0 2px 2px;
        transition: all .2s ease-in-out;

    }
}


.featured-block__img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include media-query(min, $lap-start) {
        border-radius: 5px;
    }
}

.featured-block__button-wrapper--expanded {
    display: none;
}

.featured-block__button {

    .svg-icon {
        width: 30px;
        height: 30px;
        position: relative;
        top:-5px;
    }

    &.active {
        .featured-block__button-wrapper {
            display: none;
        }

        .featured-block__button-wrapper--expanded {
            display: flex;
            align-items: end;
        }
    }
}

.featured-block__button-wrapper {
    display: flex;
    align-items: end;
}