.product.media {
	.fotorama__stage {
		border: 1px solid $silver-lt;
		margin-bottom: $base-spacing;
	}

	.fotorama__caption {
		@include visuallyhidden;
	}

	.fotorama__nav {
		margin-bottom: $base-and-half-spacing;
	}
}

// Global stick styles
.product-item__sticker {
	position: absolute;
	top: $base-spacing;
	left: -$double-spacing;
	padding: $half-spacing $double-spacing;
	background-color: $charcoal;
	color: $white;
	transform: rotate(-45deg);
	@include font-size(13px);
	text-align: center;
	z-index: 1;
}

.product-item__sticker--new-product {
	background-color: #ffeb00;
	color: $accent;
	@include font-size(14px);
	font-weight: 700;
}

// Hide the sticker inside the cart table, too small to display correctly
.cart.item {
	.product-item__sticker {
		display: none;
	}  
}