.content-promo {
    text-align: center;
    margin-bottom: $base-spacing * 3;

    @include media-query(min, $lap-start) {
        position: relative;
        display: flex;
        width: calc(100% - #{$half-spacing});
        margin-bottom: $double-spacing * 1.5;
    }
}

.content-promo__block {

}

.content-promo__block--two-up {
    @include media-query(min, $lap-start) {
        display: flex;
        justify-content: space-between;
        width: calc(66.666%);

        .content-promo__wrapper {
            width: calc(50% - 10px);
            transition: all .3s ease-in-out;
            cursor: pointer;

            @include hover {
                transform: translateY(-15px);

                .content-promo__img {
                    border: 2px solid $accent;

                }
            }
        }

        .content-promo__img-link {
            display: block;
        }

        .content-promo__img-link {
            transition: all .3s ease-in-out;
            @include hover {

            }
        }
    }
}

.content-promo__block--three-up {
    @include media-query(min, $lap-start) {
        position: relative;
        right: -$base-spacing;
        width: calc(33.3333% - #{$half-spacing});

        .content-promo__wrapper {
            width: 100%;
            margin-bottom: $base-spacing;

            transition: all .3s ease-in-out;
            cursor: pointer;

            @include hover {
                transform: translateX(15px);

                .content-promo__img {
                    border: 2px solid $accent;
                }

                .content-promo__img-link {

                }

                .content-promo__link {

                }
            }


            &:last-child {
                margin-bottom: 0;
            }
        }

        .content-promo__img-link {
            display: block;
            padding-top: 50%;
            transition: all .3s ease-in-out;
        }

        .content-promo__sub-title {
            display: none;
        }

    }

}

.content-promo__wrapper {
    margin-bottom: $base-spacing;
    border: 1px solid $silver-lt;

    @include media-query(min, $lap-start) {
        position: relative;
        margin-bottom: 0;
    }

    &:last-of-type {

    }
}

.content-promo__img-link {
    display: block;
    padding-top: 50%;
    margin-bottom: $base-spacing;
    position: relative;
    background-color: $silver-lt;
    overflow: hidden;

    @include media-query(min, $lap-start) {
        margin-bottom: 0;
        overflow: initial;
        padding-top: 170%;
    }

    @include media-query(min, $desk-start) {
        padding-top: 165%;
    }
    @include media-query(min, $wide-start) {
        padding-top: 162%;
    }
}

.content-promo__img {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    border-radius: 5px;
    height: 100%;
}

.content-promo__content {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        position: absolute;
        top: 0;
        left: $base-spacing;
        text-align: left;
        margin-bottom: 0;
        padding-top: $base-spacing;
    }
}

.content-promo__text-link {
    text-decoration: none;
    @include hover {
        color: $accent;
        text-decoration: none;
    }
}

.content-promo__title {
    @include font-size(15px);
    padding-top: 0;
    margin-bottom: $half-spacing;

    @include media-query(min, $lap-start) {
        @include font-size($h3-font-size);
        color: $white;
    }
    @include media-query(min, $desk-start) {
        @include font-size(42px);
    }
}

.content-promo__sub-title {
    @include font-size(13px);
    margin-bottom: 0;

    @include media-query(min, $lap-start) {
        @include font-size(16px);
        color: $white;
    }


}

.content-promo__link {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        position: absolute;
        display: block;
        bottom: 0;
        right: 0;
        z-index: 2;
        margin-bottom: 0;
        transition: all .3s ease-in-out;
    }

}