.table-content__wrapper {
    margin-top: $base-spacing;

    .table.dataTable thead th {
        padding: 0;
        padding-bottom: $half-spacing;

        @include media-query(min, $lap-start) {
            padding-bottom: $base-spacing;
        }
    }

    table.dataTable thead th {
        border-bottom: none;
    }

    table.dataTable.no-footer {
        border-bottom: none;
    }

    table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
    table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
        content: "";
        position: absolute;
        background-image: url(../img/expand_more.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: $base-spacing;
        height: 14px;
        width: 14px;
        left: $half-spacing;
        top: 47%;
        transform: translateY(-50%);
        border: none;
        box-shadow: none;
        background-color: $accent;
    }

    table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
        background-image: url(../img/expand_less.svg);
    }

    .table.dataTable thead td, table.dataTable thead th {
        padding-bottom: $half-spacing;
        @include font-size(11px);
    }

    table.dataTable tbody th,
    table.dataTable tbody td {
        padding: 7px $half-spacing;
        @include font-size(13px);
    }
    .special-price {
        display: block;
    }
    .old-price {
        display: block;
    }
    .product-item-link {


        .price {
            @include font-size(13px);

            @include hover {
                text-decoration: underline;
            }
        }
    }
}
