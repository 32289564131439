.info-block {
    text-align: center;
    margin-bottom: $base-spacing * 4.5;

    @include media-query(min, $lap-start) {
        margin-bottom: $base-spacing * 4;
    }
}

.info-block__title {
    @include font-size(30px);
    text-transform: uppercase;
    margin-bottom: $base-spacing * 2.5;
    @include media-query(min, $lap-start) {
        @include font-size($h4-font-size);
        margin-bottom: $base-spacing * 1.5;
    }
}

.info-block__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-query(min, $lap-start) {
        justify-content: start;
    }
}

.info-block__content {
    position: relative;
    width: calc(50% - #{$half-spacing * 1.5});
    margin-left: $base-spacing * 1.5;
    margin-bottom: $base-spacing;

    @include media-query-bracket(0, $lap-start) {

        &:nth-child(odd) {
            margin-left: 0;
        }

    }

    @include media-query(min, $lap-start) {
        width: calc(33.3333% - (#{$base-spacing * 2} / 3));
        margin-left: $base-spacing;
        transition: all .3s ease-in-out;
        margin-bottom: 0;

        &:nth-child(3n + 1) {
            margin-left: 0;
        }

        @include hover {
            transform: translateY(-15px);

            .content__image {
                border: 2px solid $accent;
            }
        }
    }
}

.content__image-link {
    display: block;
    position: relative;
}

.content__link {
    text-decoration: none;
    cursor: pointer;
    @include hover {
        color: $accent;
        text-decoration: none;
    }

    @include media-query(min, $lap-start) {
        position: absolute;
        top: 0;
        left: $base-spacing;
        padding-top: $base-spacing;
    }
}

.content__title {
    @include font-size(15px);
    font-weight: bold;
    display: block;
    text-align: center;
    margin-bottom: 0;
    padding-top: 0;

    @include media-query(min, $lap-start) {
        @include font-size($h3-font-size);
        color: $white;
    }
    @include media-query(min, $desk-start) {
        @include font-size($h2-font-size);
    }

    @include media-query(min, $wide-start) {
        @include font-size($h1-font-size);
    }
}

.content__sub-title {

    @include media-query(min, $lap-start) {
        @include font-size($h1-font-size);
        color: $white;
    }
}

.content__image-link {
    display: block;
    background-color: $silver-lt;
    padding-bottom: 100%;
    margin-bottom: $half-spacing;

    @include media-query(min, $lap-start) {
        margin-bottom: 0;
    }

}

.content__image {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;

    @include media-query(min, $lap-start) {
        border-radius: 5px;
    }
}

.content__btn-link {
    display: none;

    @include media-query(min, $lap-start) {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: 0;
    }
}
