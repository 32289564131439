.checkout-cart-index {
  .cart-container {

    @include media-query(min, $lap-start) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

  }

  .cart-summary {
    background: $silver-lt;
    padding: 0 $base-spacing $base-spacing $base-spacing;

    @include media-query(min, $lap-start) {
      width: calc(30% - #{$base-spacing});
      order: 2;
    }


    button {
      @include font-size(12px);
      width: 100%;

      @include media-query(min, $lap-start) {
        @include font-size(14px);

      }

      @include media-query(min, $desk-start) {
        @include font-size($base-font-size);
      }
    }
  }

  .summary.title {
    display: block;
    @include font-size($h3-font-size);
    margin-bottom: $half-spacing;

    @include media-query(min, $lap-start) {
      margin-bottom: $base-spacing;
      @include font-size(35px);
    }
  }

  .block.shipping {
    position: relative;

    @include media-query(min, $lap-start) {

    }

    .title {

      strong {
        position: relative;
        display: block;
        @include font-size($base-font-size);
        padding-right: $double-spacing;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 2px;
          width: 30px;
          height: 20px;
          background: url(../img/arrow-down.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }

    &.active {

      .title {
        strong {
          &:after {
            background: url(../img/arrow-up.svg) no-repeat center;
            background-size: 30px;

          }
        }
      }
    }

    .content {

    }

    .estimate {

    }

    .legend {
      display: none;
    }

    .note {

    }

    .rate {

    }

    .methods {

    }

    .item-title {

    }

    .item-options {

      .item {
        display: flex;

        .label {
          .price {
            margin-left: 2px;
          }
        }
      }
    }
  }

  .fieldset.rate {
    margin-bottom: $base-spacing;
    border-bottom: 1px solid $silver-lt;

  }

  .cart-totals {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {

    }

    .table-wrapper {

    }

    .table.totals {

    }

    .table-caption {

    }

    tr {
      padding: 0;
      display: table-row;

    }

    th {
      display: table-cell;
      padding: 0;
      vertical-align: middle;
      text-align: left;

    }

    td {
      display: table-cell;
      text-align: right;
    }

    td[data-th]:before {
      display: none;
    }

  }

  .block.discount {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {

    }

    .title {

      strong {
        position: relative;
        display: block;
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 2px;
          width: 30px;
          height: 20px;
          background: url(../img/arrow-down.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }

    &.active {
      .title {
        strong {
          &:after {
            background: url(../img/arrow-up.svg) no-repeat center;
            background-size: 30px;

          }
        }
      }

    }

    .coupon {
      .label {
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }

      }
    }
  }

  .block.giftcard {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {

    }

    .title {

      strong {
        position: relative;
        display: block;
        @include font-size(13px);
        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }


        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 2px;
          width: 30px;
          height: 20px;
          background: url(../img/arrow-down.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }

    &.active {
      .title {
        strong {
          &:after {
            background: url(../img/arrow-up.svg) no-repeat center;
            background-size: 30px;
          }
        }
      }

    }

    .giftcard {
      .label {
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }

      }
    }

    .actions-toolbar {

      .primary {
        @include media-query(min, $lap-start) {
          margin-bottom: $half-spacing;
        }

        .action {

        }
      }

      .secondary {
        @include media-query(min, $lap-start) {
          margin-bottom: 0;
        }

        .action {
          @include media-query(min, $lap-start) {
            padding: 0;
          }

          span {

          }

        }
      }
    }
  }

  .checkout-methods-items {
    list-style-type: none;
    margin-left: 0;

    @include media-query(min, $lap-start) {

    }

    .item {
      @include media-query(min, $lap-start) {
        margin-bottom: $half-spacing;
      }
    }
  }

  .multicheckout {
    text-decoration: none;
    @include font-size(13px);
    font-weight: bold;
    color: $accent;

    @include media-query(min, $lap-start) {
      @include font-size($base-font-size);
    }

    @include hover {
      text-decoration: underline;
    }
  }

  .action.checkout {

  }

  .form-cart {

    @include media-query(min, $lap-start) {
      width: calc(70% - #{$base-spacing});
      order: 1;
    }

    .cart {

    }

    thead {
      @include media-query(min, $lap-start) {
        border-bottom: 1px solid $silver-lt;
      }
    }

    .table-caption {

    }

    tr {
      .col.item {
        text-align: left;
      }

      th {
        @include media-query(min, $lap-start) {
          text-align: right;
          padding: $base-spacing 0;
          @include font-size($base-font-size);
        }

      }
    }

    .cart.item {
      @include media-query(min, $desk-start) {
        border-bottom: 1px solid $silver-lt;
      }

    }


    .item-info {
      padding: 0;

      td {
        @include media-query(min, $lap-start) {
          text-align: right;
          padding: $half-spacing 0;
          @include font-size($base-font-size);
        }
      }

      .col.item {
        display: flex;
        align-items: center;

        @include media-query(min, $lap-start) {
          align-items: initial;
        }

        .product-item-photo {

        }

        .product-item-details {


          .product-item-name {
            a {
              text-decoration: none;
              @include hover {
                text-decoration: underline;
              }
            }
          }
        }

        &[data-th]:before {
          display: none;
        }

      }

      .col.qty {


        .label {
          @include visuallyhidden;

        }

        .field.qty {
          display: inline-block;
          margin-bottom: 0;
        }

        .control.qty {
          margin-left: auto;

          input {
            width: 50px;
            padding: 0;
            height: 36px;
            margin-top: -7px;
            text-align: center;

          }
        }
      }
    }

    .item-actions {
      td {

      }

      .actions-toolbar {

        @include media-query(min, $lap-start) {
          text-align: right;
        }

      }
    }

    .gift-options-cart-item {

    }

    .action-edit {
      margin-right: $half-spacing / 2;
      text-decoration: none;
      @include font-size(13px);
      font-weight: bold;
      @include hover {
        text-decoration: underline;
      }
    }

    .action-delete {
      text-decoration: none;
      @include font-size(13px);
      font-weight: bold;
      @include hover {
        text-decoration: underline;
      }
    }
  }

  .cart.main.actions {
    .continue {
      display: block;
      margin-bottom: $base-spacing;
      @include font-size($base-font-size);
      text-decoration: none;
      font-weight: bold;


      @include media-query(min, $lap-start) {
        text-align: right;
      }

      @include hover {
        text-decoration: underline;
      }
    }

    .update {
      margin-bottom: $base-spacing;
      @include media-query(min, $lap-start) {
        margin-bottom: 0;
      }
    }

    .clear {
      margin-bottom: $base-spacing;

      @include media-query(min, $lap-start) {
        margin-bottom: 0;
      }
    }
  }

  #gift-options-cart {
    @include media-query(min, $lap-start) {
      width: 70%;
      order: 3;
    }
  }

  .cart-gift-item {

    @include media-query(min, $lap-start) {

    }
  }

  .gift-item-block {
    position: relative;

    .title {
      font-weight: bold;

      span {
        position: relative;
        display: block;
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 2px;
          width: 30px;
          height: 20px;
          background: url(../img/arrow-down.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }

    &.active {

      .title {
        span {
          &:after {
            background: url(../img/arrow-up.svg) no-repeat center;
            background-size: 30px;
          }
        }
      }
    }

    .content {

    }

    .gift-options {
      .gift-options-content {

      }
    }

    .gift-wrapping {

    }

    .gift-options-title {
      @include font-size(12px)
    }

    .gift-receipt {
      display: flex;

      .label {
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }
      }
    }

    .gift-printed-card {
      display: flex;

      .label {
        @include font-size(13px);
        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }
      }
    }
  }
}

.checkout-cart-index .page-main {
  padding-left: 15px;
  padding-right: 15px
}

.cart-container .form-cart .actions.main {
  text-align: right
}

.cart-container .widget {
  float: left
}

.cart-summary {
  width: 100%;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
  padding: 1px 20px 25px;
  z-index: 1;
  @include media-query(min, $lap-start) {
    width: 25%;
  }
}

.cart-summary>.title {
  display: block
}

.cart-summary .fieldset .actions-toolbar {
  margin-left: 0
}

.cart-summary .fieldset .actions-toolbar>.secondary {
  float: none
}

.cart-summary .block>.title {
  padding-left: 0
}

.cart-summary .block>.title:after {
  right: 3px
}

.cart-summary .block .fieldset .field {
  margin: 0 0 20px;
  margin: 0 0 10px
}

.cart-summary .block .fieldset .field:not(.choice)>.label {
  box-sizing: content-box;
  float: none;
  width: auto;
  text-align: left;
  padding: 0
}

.cart-summary .block .fieldset .field:not(.choice)>.control {
  float: none;
  width: auto
}

.cart-summary .block .fieldset .field>.label {
  margin: 0 0 8px;
  display: inline-block
}

.cart-summary .block .fieldset .field.choice:before,.cart-summary .block .fieldset .field.no-label:before {
  display: none
}

.cart-summary .checkout-methods-items {
  padding: 0
}

.cart.table-wrapper .items {
  min-width: 100%;
  width: auto
}

.cart.table-wrapper tbody td {
  padding-top: 20px
}

.cart.table-wrapper .item .col.item {
  padding: 20px 8px 20px 0
}

.cart.table-wrapper .item-actions td {
  padding: 0
}

.cart.table-wrapper .product-item-photo {
  display: table-cell;
  max-width: 100%;
  padding-right: 20px;
  position: static;
  vertical-align: top;
  width: 1%
}

.cart.table-wrapper .product-item-details {
  display: table-cell;
  padding-bottom: 35px;
  vertical-align: top;
  white-space: normal;
  width: 99%
}

.cart-products-toolbar {
  margin: 2px 0 0
}

.cart-products-toolbar .toolbar-amount {
  line-height: 30px;
  margin: 0
}

.cart-products-toolbar .pages {
  float: right
}

.cart-products-toolbar .pages .item:last-child {
  margin-right: 0
}

.cart.table-wrapper .cart-products-toolbar+.cart thead tr th.col {
  padding-bottom: 10px;
  padding-top: 10px
}

.cart.table-wrapper .cart+.cart-products-toolbar {
  margin-top: 25px
}

.cart-discount {
  width: 75%;
  float: left;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
  border: 0;
  box-sizing: border-box;
  padding-right: 4%
}

.cart-discount .block .title:after {
  display: inline;
  margin-left: 10px;
  position: static
}

.cart-discount .block.discount {
  width: auto
}

.checkout-cart-index .page-main {
  padding-left: 0;
  padding-right: 0
}

.checkout-cart-index .page-title-wrapper {
  padding-left: 15px;
  padding-right: 15px;

  @include media-query(min, $lap-start) {
    padding: 0 30px;
  }
}

.cart-summary {
  background: #f5f5f5;
  margin-bottom: 25px;
  padding: 1px 0 25px

}

.cart-summary>.title {
  font-size: 2.4rem;
  display: none;
  font-weight: 300;
  margin: 12px 0
}

.cart-summary .block form:not(:last-of-type) .fieldset {
  margin: 0 0 25px
}

.cart-summary .block .price {
  font-weight: 700
}

.cart-summary .block .field {
  margin: 0 0 16px
}

.cart-summary .block .actions-toolbar>.primary {
  text-align: left
}

.cart-summary .block .actions-toolbar>.primary .action.primary {
  width: auto
}

.cart-summary .block .title strong {
  font-size: 1.4rem;
  font-weight: 600
}

.cart-summary .block .item-options {
  margin: 0 0 16px
}

.cart-summary .block .item-options .field .radio {
  float: left;
  margin-top: 4px
}

.cart-summary .block .item-options .field .radio+.label {
  display: block;
  margin: 0;
  overflow: hidden
}

.page-main .cart-summary .block {
  margin-bottom: 0
}

.cart-summary .checkout-methods-items {
  margin: 20px 0 0;
  padding: 0 15px;
  text-align: center
}

.cart-summary .checkout-methods-items .action.primary.checkout {
  width: 100%
}

.cart-summary .checkout-methods-items .item {
  margin-bottom: 25px
}

.cart-summary .checkout-methods-items .item:last-child {
  margin-bottom: 0
}

.cart-summary .message {
  padding-left: 20px
}

.cart-summary .message>*:first-child:before {
  display: none
}

.cart-totals tbody .mark,.cart-totals tfoot .mark {
  text-align: left
}

.cart-totals .discount.coupon {
  display: none
}

.cart.table-wrapper .cart thead tr th.col {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  padding-top: 24px
}

.cart.table-wrapper .cart tbody td {
  border: 0
}

.cart.table-wrapper .cart>.item {
  border-bottom: 1px solid #ccc;
  position: relative
}

.cart.table-wrapper .col {
  padding-top: 15px
}

.cart.table-wrapper .col.price,.cart.table-wrapper .col.subtotal,.cart.table-wrapper .col.msrp {
  padding: 20px 11px 10px;
  text-align: center;

  span.price-excluding-tax {
    white-space: normal;
  }
}

.cart.table-wrapper .col.qty {
  padding: 20px 11px 10px;
  text-align: center
}

.cart.table-wrapper .col.qty .input-text {
  height: 36px;
  margin-top: -7px;
  text-align: center;
  width: 45px
}

.cart.table-wrapper .col>.price {
  color: #7d7d7d;
  font-size: 1.8rem;
  font-weight: 700
}

.cart.table-wrapper .item-actions td {
  padding-bottom: 0;
  white-space: normal
}

.cart.table-wrapper .item .col.item {
  display: block;
  min-height: 75px;
  padding: 15px 15px 10px 90px;
  position: relative
}

.cart.table-wrapper .actions-toolbar {
  min-height: 20px;
  padding-bottom: 15px;
  position: relative
}

.cart.table-wrapper .actions-toolbar>.action-edit,.cart.table-wrapper .actions-toolbar>.action-delete {


  display: inline-block;
  text-decoration: none
}

.cart.table-wrapper .actions-toolbar>.action-edit>span,.cart.table-wrapper .actions-toolbar>.action-delete>span {

}

.cart.table-wrapper .actions-toolbar>.action-edit:before,.cart.table-wrapper .actions-toolbar>.action-delete:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 20px;
  color: #757575;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center
}

.cart.table-wrapper .actions-toolbar>.action-edit:hover:before,.cart.table-wrapper .actions-toolbar>.action-delete:hover:before {
  color: #333
}

.cart.table-wrapper .actions-toolbar>.action-edit:active:before,.cart.table-wrapper .actions-toolbar>.action-delete:active:before {
  color: #757575
}

.cart.table-wrapper .actions-toolbar>.action-delete {
  right: 0
}

.cart.table-wrapper .actions-toolbar>.action-delete:before {

}

.cart.table-wrapper .action {
  margin-right: 25px
}

.cart.table-wrapper .action:last-child {
  margin-right: 0
}

.cart.table-wrapper .action.help.map {
  font-weight: 400
}

.cart.table-wrapper .product-item-photo {
  display: block;
  left: 15px;
  max-width: 65px;
  padding: 0;
  position: absolute;
  top: 15px;
  width: 100%
}

.cart.table-wrapper .product-item-name {
  @include font-size(16px);
  display: block;
  margin: -3px 0 5px
}

.cart.table-wrapper .gift-registry-name-label:after {
  content: ':'
}

.cart.table-wrapper .item-options {
  margin-bottom: 0
}

.cart.table-wrapper .product-item-name+.item-options {
  margin-top: 20px
}

.cart.table-wrapper .action.configure {
  display: inline-block;
  margin: 10px 0 0
}

.cart.table-wrapper .item .message {
  margin-top: 20px
}

.cart-products-toolbar .toolbar-amount {
  left: inherit;
  margin: 25px 0 15px;
  padding: 0;
  position: relative;
  text-align: center;
  top: inherit
}

.cart-products-toolbar-top {
  border-bottom: 1px solid #ccc
}

.cart-discount {
  border-bottom: 1px solid #ccc;
  clear: left
}

.cart-empty {
  padding-left: 15px;
  padding-right: 15px
}

.cart-tax-info+.cart-tax-total,.cart .cart-tax-info+.cart-tax-total {
  display: block
}

.cart.table-wrapper .col.price,.order-items.table-wrapper .col.price,.cart.table-wrapper .col.qty,.order-items.table-wrapper .col.qty,.cart.table-wrapper .col.subtotal,.order-items.table-wrapper .col.subtotal,.cart.table-wrapper .col.msrp,.order-items.table-wrapper .col.msrp {
  text-align: right
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
}

table th {
  text-align: left
}

table>tbody>tr>th,table>tfoot>tr>th,table>tbody>tr>td,table>tfoot>tr>td {
  vertical-align: top
}

table>thead>tr>th,table>thead>tr>td {
  vertical-align: bottom
}

table>thead>tr>th,table>tbody>tr>th,table>tfoot>tr>th,table>thead>tr>td,table>tbody>tr>td,table>tfoot>tr>td {
  padding: 11px 10px
}
