.catalog-product-view {

    .page-title-wrapper {
        padding: 0;
        @include media-query(min, $lap-start) {
            width: calc(71% - #{$half-spacing});
            float: left;
        }

        .page-title {
            @include font-size(20px);
            @include media-query(min, $lap-start) {
                @include font-size(40px);
            }
        }
    }

    .site-footer {
        clear: both;
    }
}

.product-add-form {
    form {
        margin-bottom: 0;
    }
}

.catalog-product-view-container {

    .product {

    }

    .base {
        @include font-size(24px);
    }

    .page.messages {

    }

    .columns {

    }

    .column.main {

    }
}

.product.attribute.sku {

    .value {
        @include font-size(13px);
        font-weight: normal;
    }
}

.product-page-dealer-search-wrapper {

}

.product-info-main {
    color: $white;

    @include media-query(min, $lap-start) {
        width: calc(29% - #{$half-spacing});
        float: right;
        min-width: 320px;

    }

    .container-one {
        margin-bottom: $base-spacing;
    }

    .container-two {
        background-color: $black;
        padding: $base-spacing;
        margin-bottom: $base-spacing;
    }

    .product-addto-links {
        .towishlist {
            color: $white;
            position: relative;
            text-decoration: none;
            text-align: center;
            display: block;
            @include font-size(16px);
            font-weight: bold;

            &:before {
                content: '';
                display: inline-block;
                background-color: $white;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border-color: $green;
                position: relative;
                top: 10px;
                transform: scale(0.5);
            }
        }
    }
}

.towishlist {
    @include hover {
        &:before {
            background-color: $white;
            border: $accent 10px solid;
        }
    }
}

.product.attribute.overview {
    color: $black;

    .value {
        text-transform: uppercase;
        @include font-size($h4-font-size);
        font-weight: bold;
    }
}

.product.media {
    position: relative;
    overflow: hidden;
    @include media-query(min, $lap-start) {
        width: calc(35% - #{$half-spacing});
        float: left;
        margin-right: $base-spacing;
        margin-bottom: $base-spacing;

    }
}

.description-container {
    @include media-query(min, $lap-start) {
        width: 35%;
        float: left;
    }
}

.product.info.detailed {

}

.product.data.items {
    .data.item.title {
        @include visuallyhidden;
    }
}

.product.attribute.description {
    .value p {
        @include font-size(13px);
        font-weight: bold;
        @include media-query(min, $lap-start) {
            @include font-size(16px);
        }
    }
}

.product-info-price {
    text-align: center;
    margin-bottom: $base-spacing;

    .price {
        @include font-size($h3-font-size);
        font-weight: bold;
        color: $white;
    }
}


.stock.available {

    span {

    }
}

.availability.only {

}

.product.attribute.sku {

    .type {

    }

    .value {

    }
}

.product-reviews-summary {

}

.reviews-actions {

}

.product-page-dealer-search {
    background-color: $mercury-dk;
}

.product-page-dealer-search-header__wrapper {
    background-color: $black;
    padding: $base-spacing;
    margin-bottom: $base-spacing;
}

.product-page-dealer-search-header__title {
    @include font-size($h4-font-size);
    margin-bottom: 0;
    text-transform: uppercase;
}

.product-page-searchlocation__container {
    padding: 0 $base-spacing;
    border-bottom: 1px solid $silver;


    .label {
        display: none;

    }
}

.product-page-dealer-search__results-wrapper {
    background-color: $mercury-dk;
    margin-bottom: $base-spacing;
}

.product-page-dealer-search__dealers-list {
    list-style-type: none;
    margin: 0;
}

.product-page-dealer-search__dealers-item {
    a {
        position: relative;
        padding: $base-spacing;
        display: block;

        @include hover {
            text-decoration: none;
            background-color: $silver;
        }
    }

    .dealer-search__dealer-title {
        position: relative;
        color: $base-color;

        &:after {
            content: '';
            display: inline-block;
            background-image: url("../img/arrow_forward.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            width: 20px;
            height: 20px;
        }
    }

    .dealer-search__dealer-miles {
        font-weight: 300;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.box-tocart {
    .fieldset {
        margin-bottom: 0;
    }

    .label {
        display: none;
    }

    .qty {
        display: flex;
        align-items: center;
        text-align: center;
        width: 90px;

        .label {
            margin-right: $base-spacing;
        }
    }

    .actions {
        button {
            position: relative;
            width: 100%;
            background-color: $green;
            border-color: $green;
            text-align: left;
            height: 48px;

            span {
                display: block;
                @include font-size(16px);

                &:after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    background-image: url("../img/shopping_basket.svg");
                    background-repeat: no-repeat;
                    background-size: 100%;
                    width: 20px;
                    height: 20px;
                    right: 30px;
                    top: 12px;

                }
            }
        }
    }
}

.product.alert.stock {

    .product_alert-note {
        text-align: center;
    }

    .action.alert {
        display: inline-block;
        padding: $half-spacing * 1.5 $half-spacing * 1.5;
        width: 100%;
        vertical-align: middle;
        color: $white;
        line-height: $button-line-height;
        font-weight: bold;
        text-align: left;
        text-decoration: none;
        cursor: pointer;
        transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
        -webkit-font-smoothing: antialiased;
        border-radius: 0;
        position: relative;
        background-color: $green;
        border-color: $green;
        height: 48px;

        span {
            display: block;
            @include font-size(16px);

            &:after {
                content: '';
                position: absolute;
                display: inline-block;
                background-image: url("../img/mail.svg");
                background-repeat: no-repeat;
                background-size: 100%;
                width: $base-spacing;
                height: $base-spacing;
                right: $base-spacing;
                top: $half-spacing * 1.5;
            }
        }
    }
}
.product-info-price {
    .price-container.price-final_price {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.price-container.price-final_price {
    .price-wrapper::after {
        content: attr(data-label);
        font-size: 0.7rem;
        text-transform: uppercase;
        margin-left: $half-spacing/2;
    }
}