.site-links {
    list-style: none;
    margin: 0;

}

.site-links__item {

}

a.site-links__content {
    @include font-size(14px);
    text-decoration: none;

    @include hover {
        color: $accent;
        text-decoration: underline;
    }

    @include media-query (min, $desk-start) {

    }
}

span.site-links__content {
    @include font-size(11px);
    color: $white;
    a {
        @include font-size(14px);
        text-decoration: none;
        color: $white;
        @include media-query (min, $lap-start) {
            color: $black;
        }

        @include hover {
            color: $accent;
            text-decoration: underline;
        }
    }
    @include media-query (min, $lap-start) {
        color: $black;
    }
}

.site-links__content {
    color: $black;
}



.site-links__item--help {
    display: none;

    @include media-query(min, $lap-start) {
        display: inline-block;
    }
}

.site-links__item--dealer{
    display: none;

    @include media-query(min, $lap-start) {
        display: inline-block;
    }
}
.site-links__item--login {
    display: none;

    @include media-query(min, $lap-start) {
        display: inline-block;
    }
}