/*========================================
BUTTONS
========================================*/
button {
    @include button;
    @include hover {
        outline: none;
    }
}

.button {
    @include button;
}

.button--lg {
    height: 60px;
}


/**
 * Button Styles
 */


button[disabled],
.button[disabled] {
    cursor: not-allowed;
}

.button--subtle {
    background-color: transparent;
    color: $accent;
}

.button--basket {
    background-color: $green;
    border: $green;
    color: $white;

    @include hover {
        background-color: darken($green, 10%);
    }
}

.button--circle {
    border: 0;
    border-radius: 50%;
    width: 60px;
}

.button--no-style {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    padding: 0;
    margin: 0;
    font-weight: bold;
    color: $accent;
    background: transparent;

    @include hover {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        padding: 0;
        margin: 0;
        font-weight: bold;
        color: $accent;
        background: transparent;
        text-decoration: underline;
    }
}

.button--icon {

    .svg-icon {
        width: 18px;
        height: 18px;
    }

    .svg-icon--md {
        width: 24px;
        height: 24px;
    }

    .svg-icon--lg {
        width: 30px;
        height: 30px;
    }

    .svg-icon--rotate-down {
        transform: rotate(90deg);
    }


    .svg-icon--rotate-left {
        transform: rotate(180deg);
    }
}


.button--icon-right {
    .svg-icon {
        margin-left: 0;
    }
}

.button--icon-left {
    .svg-icon {
        margin-left: 0;
        margin-right: 0;
    }
}

