.page-title-wrapper {
  @include container;
  margin-bottom: $half-spacing;

  @include media-query(min, $lap-start) {
    margin-bottom: $double-spacing;
  }

  .page-title {
    text-transform: uppercase;
    @include font-size(30px);
    padding-top: $base-spacing;
    margin-bottom: $half-spacing;

    @include media-query(min, $lap-start) {
      @include font-size($h1-font-size);
    }
  }

  .account & {
    padding: 0;
  }
}